export const StorageKey = {
    "IS_LOGGED_IN": "isLoggedIn",
    "TOKEN": "token",
    "MOBILE_NO": "mobileNo",
    "USER": "user",
    "GRADE": "GradeId",
    "USER_STATUS": "userStatus",
    "FORUM_RIGHT_BLOCK": "forumRightBlock",
    "gRE_CAPTCHA": "_grecaptcha",
    "BLOG_DATA": "blog_data",
    "TAB_ID": "tabId",
    "USER_NAME":"userName",
    "EMAIL":"email"
}

export const StorageType = {
    "LOCAL": 'local',
    "SESSION": 'session'
}