export const environment = {
  production: true,
  baseUrl: "https://prodapi.askiitians.com/api",
   //baseUrl: "https://apistaging.askiitians.com/api",
  //baseUrl: "https://localhost:44305/api",
  blogUrl: "https://www.askiitians.com/blog/",
  sms_factorkey: "",
  siteBaseDomain:"https://www.askiitians.com/",
  GATrackingID:"UA-70930152-1", ////Google Tracking Id
  GTagManagerId:"GTM-TQCDPSGK", //Google Tag manager Id
  GoogleClientId: '872727131907-hlpgb5puoq6g463dv1te0sfe3t1519iu.apps.googleusercontent.com',
  ChatPortAppId:"63f5e4e54396224c964e2745", //Chat Port AppId
  RedisServerURL:"redis://11.0.2.116:6379",
  RedisServerPassword: '8vPIoEuTuICNP0Q',
  cacheExpirytime:21600,
  hostDomain:"https://www.askiitians.com/",
};
