/*
*Author : Manikandan Maheswaran
*email : m.manikandanmct@gmail.com
*/
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, NgZone } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
declare const gapi: any;
declare const google: any;

@Injectable(
  {
  providedIn: 'root',
  }
)
export class GoogleAuthService {
  private clientId: string = '872727131907-hlpgb5puoq6g463dv1te0sfe3t1519iu.apps.googleusercontent.com';

  constructor( @Inject(DOCUMENT) private document: any,
  private ngZone: NgZone) { }
  /**
* Calling Google login API and fetching account details.
* @param callback Callback to function
*/
  public authenticateUser(clientId: any, callback: any) {
    google.accounts.id.initialize({
      client_id: this.clientId,
      callback: (response: any) => 
        { 
          this.handleCredentialResponse(response);
          const userInfo = this.decodeJwt(response.credential);
          callback.emit(userInfo);
        }
    });
    google.accounts.id.renderButton(
      document.getElementById('google-login-button'),
      {
        theme: 'outline',
        size: 'large',
      }
    );
    google.accounts.id.prompt();
    // let auth2: any;
    // gapi.load('auth2', function () {
    //   auth2 = gapi
    //     .auth2
    //     .init({ client_id: clientId, scope: 'profile email' });
    //   //Login button reference
    //   let element: any = document.getElementById('google-login-button');
    //   auth2.attachClickHandler(element, {}, function (googleUser: any) {
    //     //Getting profile object
    //     let profile = googleUser.getBasicProfile();
    //     //Setting data to localstorage.
    //     localStorage.setItem('token', googleUser.getAuthResponse());
    //     localStorage.setItem('image', profile.getImageUrl());
    //     localStorage.setItem('name', profile.getName());
    //     localStorage.setItem('email', profile.getEmail());
    //     callback.emit(googleUser);
    //   }, function (error: any) {
    //     alert(JSON.stringify(error, undefined, 2));
    //   });
    // });
    
  }
  decodeJwt(credential: any) {
    return jwtDecode(credential);
  }
  //  decodeJwt(token: string): any {
  //   return jwt_decode(token);
  // }
  handleCredentialResponse(response:any) {
    this.ngZone.run(() => {
      const credential = response.credential;
      // Here you would typically send the token to your backend for validation and user information retrieval
     

    });
  }
  public signIn() {
    google.accounts.id.prompt();
  }
  /**
* Logout user from Google
* @param callback Callback to function
*/
  userLogout(callback: any) {
    //You will be redirected to this URL after logging out from Google.
    let homeUrl = "http://localhost:4200";
    let logoutUrl = "https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah" +
      "/logout?continue=" + homeUrl;
    document.location.href = logoutUrl;
    callback();
  }
}

// function jwt_decode(token: string): any {
//   return jwt_decode(token);
// }
