import { Injectable } from '@angular/core';
import { ApiCallService } from './api-call.service';
import { Observable } from 'rxjs';
import { endPoints } from '../api-end-points';
import { ContactUs } from '../Models/ContactUsModel';

@Injectable({
  providedIn: 'root'
})
export class contactUsService {

  constructor(private apiService: ApiCallService) { }

  createContactUsDetails(post: ContactUs): Observable<any> {
     
    const headers = { 'Content-Type': 'application/json' };
    return this.apiService.Post(endPoints.CONTACT_US_DETAILS, JSON.stringify(post),headers,'text');
  }

}


