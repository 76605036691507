<div class="content-panel">
    <div class="container">
        <ul>
            <li>
                <a href="/">Home</a>
            </li>
            <li>»</li>
            <li>School Tie Up Program</li>
        </ul>
    </div>
</div>

<div class="box-dflt">
    <div class="cms-without-side">
        <div id="content-full">
            <h1>School Tie Up Program</h1>
            <strong>Welcome to India’s number 1 Online Academy for JEE Main/Advanced and NEET!</strong>
            <p>Your school can be a centre of learning in true manners if you believe in concept of total education. We are offering a program in which we have integrated JEE Main/Advanced and NEET with school curriculum. We will provide online classes only with the help of ex-iitians who are the true teacher material. The classes will be online, completely live and interactive. The students can see the teachers and ask their queries at any point of time. Each student will be provided with hard copy comprehensive study material, Daily practice papers, All India test series and regular tests. The classes are recorded and given to the schools so that students can revise the sessions also. The class timings will be as per the convenience of the school.</p>
            <p>All the school requires is Internet connection (512 KBPS Min,), a speaker system, cordless mic, white board and a projector.</p>

            <h2>Register your school</h2>
            <p>AskIITians experts have designed a tool to measure your readiness to implement online JEE (Main/Advanced) /NEET /CBSE school tutoring program in your school. Please help us getting all the answers precisely so that we can assess the best.</p>
            <p>1. Your school requires quality teachers for Science subject – yes / no</p>
            <p>2. All teachers are able to cover the syllabus efficiently on time – yes / no </p>
            <p>3. The students in a class are learning the minutes of courses – yes / no</p>
            <p>4. You are able to measure the performance of students as well as teachers – yes / no </p>
            <p>5. Does your school provide Engineering / Medical coaching to make the students ready to face the challenges – yes / no </p>
            <p>6. Does your school have teachers and study material to provide the students for Engineering &amp; Medical exams – yes / no </p>
            <p>7. Are the children of your school going for engineering / medical coaching after school hours – yes / no</p>
            <p>If you are interested in implementing askIITians [JEE (Main/Advanced) / NEET / CBSE School tutoring program] in your school, or want to get more information about askIITians.</p>

            <!-- form -->
            <div class="main-form">
                <div class="header">
                  Please fill in the following details. We will get in touch with you.
                </div>
                <div class="wite-box defaultForm">
                  <form [formGroup]="schoolTieUpProgramform" (ngSubmit)="onSubmit()">
                    <div class="form-box">
                      <span>Name</span>
                      <input type="text" formControlName="name" class="txtbox" />
                      <div *ngIf="schoolTieUpProgramform.controls['name'].invalid && schoolTieUpProgramform.controls['name'].touched" class="error-message">
                        Enter Full Name
                      </div>
                    </div>
              
                    <div class="form-box">
                      <span>Designation</span>
                      <input type="text" formControlName="designation" class="txtbox" />
                      <div *ngIf="schoolTieUpProgramform.controls['designation'].invalid && schoolTieUpProgramform.controls['designation'].touched" class="error-message">
                        Enter your designation
                      </div>
                    </div>
              
                    <div class="form-box">
                      <span>Name of School</span>
                      <input type="text" formControlName="schoolName" class="txtbox" />
                      <div *ngIf="schoolTieUpProgramform.controls['schoolName'].invalid && schoolTieUpProgramform.controls['schoolName'].touched" class="error-message">
                        Enter School Name
                      </div>
                    </div>
              
                    <div class="form-box">
                      <span>Contact Number</span>
                      <input type="text" formControlName="contactNumber" class="txtbox" />
                      <div *ngIf="schoolTieUpProgramform.controls['contactNumber'].invalid && schoolTieUpProgramform.controls['contactNumber'].touched" class="error-message">
                        Enter Contact Number
                      </div>
                    </div>
              
                    <div class="form-box">
                      <span>Email</span>
                      <input type="email" formControlName="email" class="txtbox" />
                      <div *ngIf="schoolTieUpProgramform.controls['email'].invalid && schoolTieUpProgramform.controls['email'].touched" class="error-message">
                        <span *ngIf="schoolTieUpProgramform.controls['email'].errors?.['required']">Enter Email</span>
                        <span *ngIf="schoolTieUpProgramform.controls['email'].errors?.['email']">Invalid Email Format</span>
                      </div>
                    </div>
              
                    <div class="form-box">
                      <span>Address</span>
                      <input type="text" formControlName="address" class="txtbox" />
                      <div *ngIf="schoolTieUpProgramform.controls['address'].invalid && schoolTieUpProgramform.controls['address'].touched" class="error-message">
                        Enter Your Address
                      </div>
                    </div>
              
                    <div class="form-box">
                      <span>City</span>
                      <input type="text" formControlName="city" class="txtbox" />
                      <div *ngIf="schoolTieUpProgramform.controls['city'].invalid && schoolTieUpProgramform.controls['city'].touched" class="error-message">
                        Enter City Name
                      </div>
                    </div>
              
                    <div class="form-box">
                      <span>Country</span>
                      <input type="text" formControlName="country" class="txtbox" />
                      <div *ngIf="schoolTieUpProgramform.controls['country'].invalid && schoolTieUpProgramform.controls['country'].touched" class="error-message">
                        Enter Country Name
                      </div>
                    </div>
              
                    <div class="form-box">
                      <span>Question to askIITians Team</span>
                      <textarea formControlName="question" class="txtarea" maxlength="1000"></textarea>
                    </div>
              
                    <div class="form-box">
                      <span>Request a brochure &amp; Course Details</span>
                      <textarea formControlName="courseDetails" class="txtarea" maxlength="1000"></textarea>
                    </div>
              
                    <div class="form-box">
                      <span>How did you hear about us?</span>
                      <input type="text" formControlName="referralSource" class="txtbox" />
                    </div>
              
                    <div class="form-box">
                      <button type="submit" class="submit-btn">Submit</button>
                    </div>
                  </form>
                </div>
              </div>
              

            <p><strong>Askiitians.com - Top Success in JEE Main/Advanced / NEET &amp; Boards for schools</strong></p>
            <p>Every parent dreams of a great career for his/her child. Askiitians integrated school program is a dream materialized into reality for all parents &amp; aspiring students...</p>

            <h2>Program Focus &amp; Objectives</h2>
            <p>Askiitians Program prepares a student for JEE Main/Advanced, other Engineering Entrance Exams / Medical entrance, International Olympiads...</p>

            <h2>Course Structure</h2>
            <ul>
                <li>Entire course coverage in 5 modules – through online classes with ex-iitians, the true teacher material</li>
                <li>Single comprehensive study material...</li>
            </ul>

            <h2>Programs offered</h2>
            <ul>
                <li>One Year Integrated School program for JEE Main/Advanced – for class XII studying students</li>
                <li>Two Year Integrated School Program for JEE Main/Advanced, – for class XI studying students</li>
            </ul>

            <h2>Issues Concerning You</h2>
            <p>School time should be 100% effective for JEE Main/Advanced preparation...</p>

            <h2>Current Scenario:</h2>
            <ul>
                <li>Total time available in a day - 24 Hrs.</li>
                <li>Time required for sleep 7 hrs minimum...</li>
            </ul>

            <h2>As a result, student is under immense stress because of:</h2>
            <ul>
                <li>Time: This scenario leaves absolutely minimum time for self studies...</li>
            </ul>
        </div>
    </div>
</div>
