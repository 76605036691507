<div class="modal-header">
    <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
</div>
<div class="modal-body">
    <div class=" vdo_popup_container rounded-1">
        <div class="vdo_container">
            <iframe id="video_frame" width="1098" height="560" type="text/html" [src]="videoUrl | noSanitizePipe:'resourceUrl'"
                frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
        </div>
    </div>
</div>