import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ApiCallService } from './api-call.service';
import { Observable } from 'rxjs';
import { endPoints } from '../api-end-points';
import { SubjectModel } from '../Models/SubjectModel';
import { TopicTitleModel } from '../Models/TopicTitleModel';
import { TopicDetailModel } from '../Models/TopicDetailModel';

@Injectable({
  providedIn: 'root'
})
export class ResourceService {

  constructor(private apiService: ApiCallService,
    @Inject(PLATFORM_ID) private platformId: any) { }


  getSubject(): Observable<SubjectModel> {
    return this.apiService.Get(endPoints.GET_SUBJECT);
  }

  getChapterTitleFromIdsWithUserID(GradeId: number, SubjectId: number, FeatureId: number): Observable<Array<TopicTitleModel>> {
    return this.apiService.Get(endPoints.GET_CHAPTER_TITLE_BY_RESOURCE_SUBJECT_GRADE + GradeId + '/' + SubjectId + '/' + FeatureId);
  }

  getResourcesDataFromIdsWithUserID
    (GradeId: number, SubjectId: number, FeatureId: number, TopicId: number, UserId: number): Observable<Array<TopicDetailModel>> {
    if (UserId > 0) {
      return this.apiService.Get(endPoints.GET_TOPIC_DETAILS + GradeId + '/' + SubjectId + '/' + FeatureId + '/' + TopicId + '?UserId=' + UserId);
    }
    else {
      return this.apiService.Get(endPoints.GET_TOPIC_DETAILS + GradeId + '/' + SubjectId + '/' + FeatureId + '/' + TopicId);
    }
  }

  getResourceFromID(Id: number): Observable<any> {
    return this.apiService.Get(endPoints.GET_RESOURCE_BY_ID + Id);
  }

  previous_year_disable(grade: string, getHtml: HTMLElement) {
    if (grade == "6" || grade == "7" || grade == "8" || grade == "9" || grade == "10") {
      if (isPlatformBrowser(this.platformId)) {
        getHtml.getElementsByClassName('item')[6].setAttribute("style", "display:none !important;");
      }
    }
    else {

      if (isPlatformBrowser(this.platformId)) {
        getHtml.getElementsByClassName('item')[6].setAttribute("style", "display:flex !important;");
      }
    }
  }
}
