<div class="input-container">
  <div class="input-box form-control">
    <div class="selected-country-code" (click)="isDropdownVisible = !isDropdownVisible">
      <div class="countryFlag">
        <img src="/assets/images/flag/{{ selectedCountry?.flag }}" class="flag-image" alt="Flag" height="17px"
          width="22px">
      </div>
      <div class="selected-dial-code">
        <span class="country-code"> {{ selectedCountry.code}}</span>
      </div>
      <i class="fa fa-caret-down"></i>
    </div>
    <form [formGroup]="formControl">
    <input type="tel" (keypress)="numberOnly($event)"  placeholder="Enter Phone Number" (blur)="onTouched()" formControlName="phoneNumber"  pattern="[0-9]*"  maxlength="10">
  </form>
  </div>
  <div class="country-list-container" *ngIf="isDropdownVisible">
    <div class="search-container">
      <input id="country-search-box" placeholder="Search Country" [(ngModel)]="searchText" class="search form-control">
    </div>
    <div class="list">
      <ul class="country-list">
        <li class="country-details" *ngFor="let country of getFilteredCountryCodes()" (click)="onSelect(country); isDropdownVisible = false">
          <span class="country-flag">
            <img [src]="'/assets/images/flag/' + country.flag" class="flag-image" alt="Flag" height="20px" width="30px">
          </span>
          <span class="country-name">{{ country.country }}</span>
          <span class="country-code"> ({{ country.code }})</span>
        </li>
      </ul>
    </div>
  </div>
</div>
