    <section class="main_contianer">
        <section class="top_banner">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h1><span>8th ICAT</span> (International Competitive Aptitude Test)</h1>
                        <span class="play_icon" data-aos="flip-left"><a href="#" id="playVideo1" data-toggle="modal"
                                data-target="#play_vedio">
                                <img src="https://files.askiitians.com/static_content/Resources/images/icat/play-icon.png" alt=""></a></span>
                        <h2>Upto 100% Scholarship for all courses</h2>
                        <!-- <div class="ban_btn" data-aos="fade-up" data-aos-anchor="#example-anchor" data-aos-offset="500"
                            data-aos-duration="500">
                            <button (click)="openModal()">Register Now</button>
                            <a id="dwnldIcatPaper" (click)="openModalNext($event)" data-toggle="modal"
                                data-target="#download_icat_paper">Download 7th iCAT Paper</a>
                        </div> -->

                        <div class="ban_btn aos-init aos-animate" data-aos="fade-up" data-aos-anchor="#example-anchor" data-aos-offset="500" data-aos-duration="500">
                            <a id="regStudTop" href="#" class="active" data-toggle="modal" data-target="#registernow_flow">Register Now</a>
                            <a id="dwnldIcatPaper" href="#" data-toggle="modal" data-target="#download_icat_paper">Download 7th iCAT Paper</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- The Modal -->
        <!-- Modal -->
        <div class="modal fade play_vedio" id="play_vedio" role="dialog">
            <div class="modal-dialog modal-lg">
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" id="closeVideo1" class="close closeVideo" data-dismiss="modal">×</button>
                    </div>
                    <div class="modal-body">
                        <source src="https://files.askiitians.com/static_content/Resources/images/icat/ICATkondapur.mp4" type="video/mp4">
                        <iframe width="100%" height="400" id="videoPlayer1"
                            src="https://www.youtube.com/embed/Z5saDuoXsRc" autoplay="" frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen=""></iframe>
                        <script>

                        </script>
                    </div>
                </div>
            </div>
        </div>

        <section class="icat_sec">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="bn_btom_heading">
                            <h2><span>iCAT</span> was introduced for the first time in the year 2012 with the vision of
                                helping Schools and the
                                students bench mark themselves and compare with the students around the globe. </h2>
                        </div>
                        <div class="icat_main_heading">
                            <h2>Why should I take iCAT?</h2>
                        </div>

                        <div class="row">
                            <div class="col-md-4">
                                <div class="icat_three_box icat_three3" data-aos="zoom-in">
                                    <h2>Rank Predictor</h2>
                                    <p>Predicted International and National Rank for competitive exams like JEE / NEET/
                                        SAT etc.</p>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="icat_three_box icat_three1" data-aos="zoom-in">
                                    <h2>Competitive Assessment Report</h2>
                                    <p>Personalized Score Card analysis and interpretation of strength and improvement
                                        areas. Also, detailed subject wise assessment report.</p>
                                    <a href="https://files.askiitians.com/static_content/Resources/icat/sample-report-ps-dubai-grade-10th.pdf" target="_blank"
                                        onclick="ga('send', 'event','ICAT', 'Download_report_student_click', ''+window.location.href,  0, 0);">Download
                                        Sample Report</a>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="icat_three_box icat_three2" data-aos="zoom-in">
                                    <h2>Free Counseling</h2>
                                    <p>Counseling about how to bridge the competency gap worth Rs 3000/- (Free of Cost).
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
        <section class="icat_details">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 col-sm-6">
                        <div class="details_box" data-aos="fade-right">
                            <img src="https://files.askiitians.com/static_content/Resources/images/icat/details_img.jpg" alt="">
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6">
                        <div data-aos="fade-left">
                            <div class="icat_main_heading">
                                <h2>Details</h2>
                            </div>
                            <div class="detail_box">
                                <ul>
                                    <li><span>For Classes 6th to 11th</span></li>
                                    <li><span>Exam Fee + Report + Counselling free of cost</span></li>
                                    <li style="list-style: none;"><span>India </span>: 10:30 am - 12:30 pm</li>
                                    <li style="list-style: none;"><span>Overseas </span>: 10 am - 12 pm, 12 - 2 pm, 2 -
                                        4 pm</li>
                                </ul>
                            </div>
                            <div class="detail_rn_btn detail_btn_click"><a id="regStudBottom" href="#"
                                    data-toggle="modal" data-target="#registernow_flow">Register Now</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="We_are_at" data-aos="fade-up">
            <div class="container">
                <div class="icat_main_heading">
                    <h2>We are at</h2>
                </div>
                <div class="row">
                    <div class="col-md-2"></div>
                    <div class="col-md-8">
                        <div class="We_are_at_box">
                            <div id="sync1" class="owl-carousel">
                                <div class="item">
                                    <img src="https://files.askiitians.com/static_content/Resources/images/icat/india_class_room.jpg">
                                </div>
                                <div class="item">
                                    <img src="https://files.askiitians.com/static_content/Resources/images/icat/dubai_class_room.jpg">
                                </div>
                                <div class="item">
                                    <img src="https://files.askiitians.com/static_content/Resources/images/icat/sarjha_class_room.jpg">
                                </div>
                                <div class="item">
                                    <img src="https://files.askiitians.com/static_content/Resources/images/icat/abu_dhabi_class_room.jpg">
                                </div>
                                <div class="item">
                                    <img src="https://files.askiitians.com/static_content/Resources/images/icat/saudi_arabia_class_room.jpg">
                                </div>
                                <div class="item">
                                    <img src="https://files.askiitians.com/static_content/Resources/images/icat/qatar_class_room.jpg">
                                </div>
                                <div class="item">
                                    <img src="https://files.askiitians.com/static_content/Resources/images/icat/oman_class_room.jpg">
                                </div>
                                <div class="item">
                                    <img src="https://files.askiitians.com/static_content/Resources/images/icat/bahrain_class_room.jpg">
                                </div>
                                <div class="item">
                                    <img src="https://files.askiitians.com/static_content/Resources/images/icat/kuwait_class_room.jpg">
                                </div>
                                <div class="item">
                                    <img src="https://files.askiitians.com/static_content/Resources/images/icat/uae_class_room.jpg">
                                </div>
                            </div>
                            <div id="sync2" class="owl-carousel">
                                <div class="item">
                                    <h1>India</h1>
                                </div>
                                <div class="item">
                                    <h1>Dubai</h1>
                                </div>
                                <div class="item">
                                    <h1>Sharjah</h1>
                                </div>
                                <div class="item">
                                    <h1>Abu Dhabi</h1>
                                </div>
                                <div class="item">
                                    <h1>Saudi Arabia</h1>
                                </div>
                                <div class="item">
                                    <h1>Qatar</h1>
                                </div>
                                <div class="item">
                                    <h1>Oman</h1>
                                </div>
                                <div class="item">
                                    <h1>Bahrain</h1>
                                </div>
                                <div class="item">
                                    <h1>Kuwait</h1>
                                </div>
                                <div class="item">
                                    <h1>UAE</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="topper_section_box">
            <div class="container">
                <div class="icat_main_heading">
                    <h2>Topper Section</h2>
                </div>
                <div class="row">
                    <div class="col-md-1"></div>
                    <div class="col-md-7">
                        <div class="topper_section" data-aos="fade-right">
                            <div id="topsection" class="owl-carousel owl-theme">
                                <div class="item">
                                        <source src="https://files.askiitians.com/static_content/Resources/images/icat/ICATkondapur.mp4" type="video/mp4">
                                    <iframe width="100%" height="400" id="videoPlayer2"
                                        src="https://www.youtube.com/embed/M8hLa0vht8o" frameborder="0"
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen=""></iframe>
                                </div>
                                <div class="item">
                                    <iframe width="100%" height="400" id="videoPlayer3"
                                        src="https://www.youtube.com/embed/FAtByOkWgiw" frameborder="0"
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen=""></iframe>
                                        <source src="https://files.askiitians.com/static_content/Resources/images/icat/ICATkondapur.mp4" type="video/mp4">
                                </div>
                                <div class="item">
                                    <iframe width="100%" height="400" id="videoPlayer4"
                                        src="https://www.youtube.com/embed/zxC6QHep9WM" frameborder="0"
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen=""></iframe>
                                   <video width="100%" controls="">
                                        <source src="https://files.askiitians.com/static_content/Resources/images/icat/ICATkondapur.mp4" type="video/mp4">
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <ul class="topper_section_profile" data-aos="fade-left">
                            <li><span>
                                    <img src="https://files.askiitians.com/static_content/Resources/images/icat/profile_aditya_vaidya.jpg" alt=""></span>
                                <div class="sp_text">
                                    <h3>Aditya Vaidya</h3>
                                    <h4>AIR 122 NEET</h4>
                                </div>
                            </li>
                            <li><span>
                                    <img src="https://files.askiitians.com/static_content/Resources/images/icat/profile_jacob.jpg" alt=""></span>
                                <div class="sp_text">
                                    <h3>Jacob</h3>
                                    <h4>49 AIR KVPY</h4>
                                </div>
                            </li>
                            <li><span>
                                    <img src="https://files.askiitians.com/static_content/Resources/images/icat/profile_sashank_cheekala.jpg" alt=""></span>
                                <div class="sp_text">
                                    <h3>Sashank Cheekala</h3>
                                    <h4>5835 AIR JEE Advanced,
                                        <br>
                                        99.3% in JEE Mains
                                    </h4>
                                </div>
                            </li>
                            <li><span>
                                    <img src="https://files.askiitians.com/static_content/Resources/images/icat/profile_simran_sajnani.jpg" alt=""></span>
                                <div class="sp_text">
                                    <h3>Simran Sajnani</h3>
                                    <h4>3 AIR IMO</h4>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <section class="stats_box">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="stats_profile">
                            <ul>
                                <li data-aos="flip-up">
                                    <h2>200+ test<span>centres</span></h2>
                                </li>
                                <li data-aos="flip-up">
                                    <h2>5 lacs+ students<span>appeared so far</span></h2>
                                </li>
                                <li data-aos="flip-up">
                                    <h2>50 lacs+ reward<span>money</span></h2>
                                </li>
                                <li data-aos="flip-up">
                                    <h2>Upto 100%<span>Scholarship</span></h2>
                                </li>
                                <li data-aos="flip-up">
                                    <h2>50 + cities</h2>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="for_school">
            <div class="container">
                <div class="icat_main_heading">
                    <h2>For School</h2>
                </div>
                <div class="row">
                    <div class="col-md-6 col-sm-6">
                        <div class="details_box" data-aos="fade-right">
                            <img src="https://files.askiitians.com/static_content/Resources/images/icat/school_img.jpg" alt="">
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6">
                        <div data-aos="fade-left">
                            <div class="icat_main_heading left_heading">
                                <h2>For conducting iCAT in your school</h2>
                            </div>
                            <div class="download_btn">
                                <a href="https://files.askiitians.com/static_content/Resources/icat/Sample-iCAT School Report.pdf" target="_blank"
                                    onclick="ga('send', 'event','ICAT', 'Download_report_school_click', ''+window.location.href,  0, 0);">Download
                                    Sample Report</a>
                                <a href="https://files.askiitians.com/static_content/Resources/icat/iCAT_Brochure.pdf" target="_blank"
                                    onclick="ga('send', 'event','ICAT', 'Download_brochure_school_click', ''+window.location.href,  0, 0);">Download
                                    School Brochure</a>
                            </div>
                            <div class="detail_box detail_school">
                                <ul>
                                    <li style="list-style: none;"><span>Mobile No.: </span>+91-9953655076</li>
                                    <li style="list-style: none;"><span>Email ID: </span>jasmeet.singh@askiitians.com
                                    </li>
                                </ul>
                            </div>
                            <div class="detail_rn_btn"><a id="regSchools" data-toggle="modal"
                                    data-target="#school_registernow_flow" (click)="openModalNext($event)">Register Now for Schools</a></div>
                        </div>
                    </div>
                </div>

            </div>
        </section>


        <!--FOOTER -->
        <footer></footer>
        <!--FOOTER -->


        <!-- Modal -->
        <div id="registernow_flow" class="modal fade" role="dialog" data-backdrop="static" data-keyboard="false">
            <div class="modal-dialog">

                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-header">
                        <button (click)="closeModal()" data-bs-dismiss="modal" id="regStudentClosePopup" type="button" class="close">×</button>
                        <h4 class="modal-title"><strong>Register for 8th ICAT</strong></h4>
                    </div>
                    <!-- <div class="modal-body"> -->
                    <div class="modal-body-of-icat">
                        <!-- AllForms -->
                        <div class="content-holder">

                            <div class="content formBox" id="content-1" data-id="1" style="display: block;">
                                <form [formGroup]="registrationForm" (ngSubmit)="onSubmitRegistrationForm()">
                                  <div class="formBox">
                                    <div class="buttons-grid-top icat_register_now">
                                      <div class="row">
                                        <div class="col-md-12">
                                          <div class="row">
                                            <div class="col-sm-4">
                                              <div class="headname">
                                                <p>Choose your Grade</p>
                                              </div>
                                            </div>
                                            <div class="col-sm-8">
                                              <select formControlName="grade" id="drpStudentGrade" class="grade">
                                                <option value="" disabled>Select Grade</option>
                                                <option *ngFor="let grade of grades" [value]="grade">{{ grade }}</option>
                                              </select>
                                            </div>
                                          </div>
                                          <br>
                                          <div class="row">
                                            <div class="col-sm-4">
                                              <div class="headname">
                                                <p>Choose your Country</p>
                                              </div>
                                            </div>
                                            <div class="col-sm-8">
                                              <select formControlName="country" id="country" class="form-control" (change)="onCountryChange($event.target.value)">
                                                <option value="Select">Select</option>
                                                <option value="Qatar">Qatar</option>
                                                <option value="KSA">KSA</option>
                                                <option value="Oman">Oman</option>
                                                <option value="Bahrain">Bahrain</option>
                                                <option value="Kuwait">Kuwait</option>
                                                <option value="UAE">UAE</option>
                                                <option value="India">India</option>
                                                <option value="Singapore">Singapore</option>
                                                <option value="Malaysia">Malaysia</option>
                                                <option value="Indonesia">Indonesia</option>
                                                <option value="Nigeria">Nigeria</option>
                                                <option value="Japan">Japan</option>
                                              </select>
                                            </div>
                                          </div>
                              
                                          <!-- Show Cities based on Country -->
                                          <div id="rowCityIndia" class="row cc_none" *ngIf="selectedCountry === 'India'">
                                            <div class="col-sm-4">
                                              <div class="headname">
                                                <p>Choose City</p>
                                              </div>
                                            </div>
                                            <div class="col-sm-8">
                                              <div class="choos_icat_paper choose_city">
                                                <div class="btn btn-book-click citySelected" *ngFor="let city of citiesIndia" (click)="selectCity(city)">{{ city }}</div>
                                              </div>
                                            </div>
                                          </div>
                                          
                                          <div id="rowCityQatar" class="row cc_none" *ngIf="selectedCountry === 'Qatar'">
                                            <div class="col-sm-4">
                                              <div class="headname">
                                                <p>Choose City</p>
                                              </div>
                                            </div>
                                            <div class="col-sm-8">
                                              <div class="choos_icat_paper choose_city">
                                                <div class="btn btn-book-click citySelected" *ngFor="let city of citiesQatar" (click)="selectCity(city)">{{ city }}</div>
                                              </div>
                                            </div>
                                          </div>
                              
                                          <div id="rowCityUae" class="row cc_none" *ngIf="selectedCountry === 'UAE'">
                                            <div class="col-sm-4">
                                              <div class="headname">
                                                <p>Choose City</p>
                                              </div>
                                            </div>
                                            <div class="col-sm-8">
                                              <div class="choos_icat_paper choose_city">
                                                <div class="btn btn-book-click citySelected" *ngFor="let city of citiesUae" (click)="selectCity(city)">{{ city }}</div>
                                              </div>
                                            </div>
                                          </div>
                              
                                          <div class="frm_btn-wrp">
                                            <button type="button" class="back Form_btn">Back</button>
                                            <button type="submit" class="next">Next</button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                              
                            <!-- first-box -->
                            <div class="content formBox" id="content-2" data-id="2" style="display: block;">
                                <form [formGroup]="studentForm" (ngSubmit)="onSubmitStudentForm()">
                                  <div class="formBox">
                                    <div class="buttons-grid-top icat_register_now">
                                      <div class="row">
                                        <div class="col-md-12">
                                          <div class="row">
                                            <!-- Full Name Field -->
                                            <div class="col-sm-4">
                                              <div class="headname">
                                                <p>Full Name</p>
                                              </div>
                                            </div>
                                            <div class="col-sm-8">
                                              <input formControlName="fullName" type="text" class="form-control" placeholder="">
                                            </div>
                                          </div>
                              
                                          <div class="row">
                                            <!-- Phone Number Field -->
                                            <div class="col-sm-4">
                                              <div class="headname">
                                                <p>Phone Number</p>
                                              </div>
                                            </div>
                                            <div class="col-sm-8">
                                              <input formControlName="phoneNumber" type="text" class="form-control" placeholder="+91 - 8447970000">
                                              <div class="country-code">
                                                <a tabindex="0" id="hypCountryCode" class="hypCountryCode" href="javascript:void(0);">
                                                  <span id="spnCountryName" class="spnCountryName">+91</span>
                                                  <div class="arrow-container">
                                                    <div class="arrow"></div>
                                                  </div>
                                                </a>
                                              </div>
                                              <div id="dvCountryCodeMenu" class="country-codes-container dvCountryCodeMenu">
                                                <ul>
                                                  <!-- Country codes can be dynamically added here -->
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                              
                                          <div class="row">
                                            <!-- Email ID Field -->
                                            <div class="col-sm-4">
                                              <div class="headname">
                                                <p>Email ID</p>
                                              </div>
                                            </div>
                                            <div class="col-sm-8">
                                              <input formControlName="email" type="email" class="form-control" placeholder="">
                                            </div>
                                          </div>
                              
                                          <div class="frm_btn-wrp">
                                            <button type="button" class="back Form_btn">Back</button>
                                            <button type="submit" class="next">Next</button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                              
                            <!-- second-box -->



                        </div>
                        <div class="content formBox" id="content-3" data-id="3" style="display: block;">
                            <div class="icat_done_text">
                                <img src="https://files.askiitians.com/static_content/Resources/images/icat/icat_check_icon.png" alt="">
                                <h2>Registration Done</h2>
                                <p>The details for your exams and venue will be sent to your registered email id.</p>
                                <button id="regStudentDone" type="button" class="final_btnbox"
                                    data-dismiss="modal">OK</button>
                            </div>
                        </div>
                        <!-- AllForms -->
                    </div>
                </div>
            </div>
        </div>
        <!---->
        <!-- Modal -->
        <div id="school_registernow_flow" class="modal fade" role="dialog">
            <div class="modal-dialog">

                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-header">
                        <button id="closeSchoolPopup" type="button" class="close" data-dismiss="modal">×</button>
                        <h4 class="modal-title"><strong>Register for 8th ICAT</strong></h4>
                    </div>
                    <div class="modal-body">
                        <!-- AllForms -->
                        <div class="content-holder">

                            <div class="content formBox" id="content1" data-id="1" style="display: block;">
                                <form [formGroup]="schoolForm" (ngSubmit)="onSubmitSchoolForm()">
                                  <div class="formBox">
                                    <div class="buttons-grid-top icat_register_now">
                                      <div class="row">
                                        <div class="col-md-12">
                                          <div class="row">
                                            <!-- Country Field -->
                                            <div class="col-sm-4">
                                              <div class="headname">
                                                <p>Choose your Country</p>
                                              </div>
                                            </div>
                                            <div class="col-sm-8">
                                              <select formControlName="country" class="form-control">
                                                <option value="Select">Select</option>
                                                <option value="Qatar">Qatar</option>
                                                <option value="KSA">KSA</option>
                                                <option value="Oman">Oman</option>
                                                <option value="Behrain">Behrain</option>
                                                <option value="Kuwait">Kuwait</option>
                                                <option value="UAE">UAE</option>
                                                <option value="India">India</option>
                                                <option value="Singapore">Singapore</option>
                                                <option value="Malaysia">Malaysia</option>
                                                <option value="Indonesia">Indonesia</option>
                                                <option value="Nigeria">Nigeria</option>
                                                <option value="Japan">Japan</option>
                                              </select>
                                            </div>
                                          </div>
                              
                                          <div class="row">
                                            <!-- School Name Field -->
                                            <div class="col-sm-4">
                                              <div class="headname">
                                                <p>School Name</p>
                                              </div>
                                            </div>
                                            <div class="col-sm-8">
                                              <input formControlName="schoolName" type="text" class="form-control" placeholder="">
                                            </div>
                                          </div>
                              
                                          <div class="row">
                                            <!-- Name of the Person Field -->
                                            <div class="col-sm-4">
                                              <div class="headname">
                                                <p>Name of the person</p>
                                              </div>
                                            </div>
                                            <div class="col-sm-8">
                                              <input formControlName="personName" type="text" class="form-control" placeholder="">
                                            </div>
                                          </div>
                              
                                          <div class="row">
                                            <!-- Designation Field -->
                                            <div class="col-sm-4">
                                              <div class="headname">
                                                <p>Designation</p>
                                              </div>
                                            </div>
                                            <div class="col-sm-8">
                                              <input formControlName="designation" type="text" class="form-control" placeholder="">
                                            </div>
                                          </div>
                              
                                          <div class="frm_btn-wrp">
                                            <button type="button" class="back Form_btn">Back</button>
                                            <button type="submit" class="next">Next</button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                              
                            <!-- first-box -->
                            <div class="content formBox" id="content2" data-id="2" style="display: block;">
                                <form [formGroup]="addressForm" (ngSubmit)="onSubmitAddressForm()">
                                  <div class="formBox">
                                    <div class="buttons-grid-top icat_register_now">
                                      <div class="row">
                                        <div class="col-md-12">
                                          <div class="row">
                                            <!-- School Address Field -->
                                            <div class="col-sm-4">
                                              <div class="headname">
                                                <p>School Address</p>
                                              </div>
                                            </div>
                                            <div class="col-sm-8">
                                              <input formControlName="schoolAddress" type="text" class="form-control" placeholder="">
                                            </div>
                                          </div>
                              
                                          <div class="row">
                                            <!-- Phone Number Field -->
                                            <div class="col-sm-4">
                                              <div class="headname">
                                                <p>Phone Number</p>
                                              </div>
                                            </div>
                                            <div class="col-sm-8">
                                              <input formControlName="phoneNumber" type="text" class="form-control" placeholder="+91 - 8447970000">
                                              <div runat="server" id="dvMobile">
                                                <div class="country-code">
                                                  <a tabindex="0" id="hypCountryCodeStud" class="hypCountryCode" href="javascript:void(0);">
                                                    <span id="spnCountryNameStud" class="spnCountryName">+91</span>
                                                    <div class="arrow-container">
                                                      <div class="arrow"></div>
                                                    </div>
                                                  </a>
                                                </div>
                                                <div id="dvCountryCodeMenuStud" class="country-codes-container dvCountryCodeMenu">
                                                  <ul></ul>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                              
                                          <div class="row">
                                            <!-- Email ID Field -->
                                            <div class="col-sm-4">
                                              <div class="headname">
                                                <p>Email ID</p>
                                              </div>
                                            </div>
                                            <div class="col-sm-8">
                                              <input formControlName="schoolEmail" type="text" class="form-control" placeholder="">
                                            </div>
                                          </div>
                              
                                          <div class="frm_btn-wrp">
                                            <button type="button" class="back Form_btn">Back</button>
                                            <button type="submit" class="next">Next</button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                              
                            <!-- second-box -->



                        </div>
                        <div class="content formBox" id="content3" data-id="3">
                            <div class="icat_done_text">
                                <img src="https://files.askiitians.com/static_content/Resources/images/icat/icat_check_icon.png" alt="">
                                <p>We have recieved your response.
                                    <br>
                                    Our Executive will be in touch with you soon.
                                </p>
                                <button id="schoolDone" type="button" class="final_btnbox"
                                    data-dismiss="modal">OK</button>
                            </div>
                        </div>
                        <!-- AllForms -->
                    </div>
                </div>
            </div>
        </div>
        <!---->
        <!-- Modal -->
        <div id="download_icat_paper" class="modal fade" role="dialog" [ngClass]="{ 'show': modalOpen }">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <!-- <button type="button" class="close" (click)="closeModal()">&times;</button>
                  <h4 class="modal-title"><strong>Choose your Grade</strong></h4> -->
                </div>
                <div class="modal-body">
                  <!-- AllForms -->
                  <div class="content-holder">
                    <div class="buttons-grid-top icat_register_now download_icat_paper">
                      <div class="row">
                        <div class="col-sm-12">
                          <div class="choos_icat_paper">
                            <div 
                              *ngFor="let grade of grades" 
                              class="btn btn-book-click" 
                              [ngClass]="{ 'classSelected': selectedGrade === grade }"
                              (click)="selectGrade(grade)">
                              {{ grade }}
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12">
                          <button #dwnldPaperFinal type="button" class="next" (click)="downloadPaper()">Download</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- AllForms -->
                </div>
              </div>
            </div>
          </div>
          
        <!---->
    </section>

<!-- <div *ngIf="showModal">
    <div class="modal">
      <div class="modal-content">
        <div class="modal-header">
          <h4>Register for 8th ICAT</h4>
          <button (click)="closeModal()" class="close">&times;</button>
        </div>
        <div class="modal-body">
          <p>Choose your Grade, Country, etc.</p>
        </div>
        <div class="modal-footer">
          <button (click)="closeModal()">Close</button>
        </div>
      </div>
    </div>
  </div> -->

<!-- jQuery (necessary for Bootstrap's JavaScript plugins) -->
<!-- <p>
    </p>
<script src="https://files.askiitians.com/static_content/Resources/images/jquery-1.8.3.min.js" type="text/javascript"></script>
<script src="https://files.askiitians.com/static_content/Resources/images/bootstrap.min.js"></script>
<p>

    </p>
<script src="https://files.askiitians.com/static_content/Resources/images/jquery.min.js"></script>
<p>

</p>
<script src="https://files.askiitians.com/static_content/Resources/images/owl.carousel.js"></script>
<p>
  </p>
<script src="https://files.askiitians.com/static_content/Resources/images/owl.theme.js"></script>
<p>
</p>
<script src="https://files.askiitians.com/static_content/Resources/images/aos.js"></script>
<p>


   </p>
<script src="js/script.js"></script>
<p>
</p> -->
<script>
    $(document).ready(function () {
        $("#topsection").owlCarousel({
            //animateOut: 'fadeOut',
            //transitionStyle : "fade",
            items: 1,
            itemsDesktop: [1199, 1],
            itemsDesktopSmall: [979, 1],
            itemsTablet: [768, 1],
            itemsMobile: [479, 1],
            nav: false,
            dots: true,
            loop: true,
            autoplay: true,
            autoplayTimeout: 4000,
            autoplayHoverPause: true,
            smartSpeed: 1000
        });
    });

    $(document).ready(function () {

        var sync1 = $("#sync1");
        var sync2 = $("#sync2");

        sync1.owlCarousel({
            items: 1,
            singleItem: true,
            slideSpeed: 1000,
            navigation: false,
            pagination: false,
            afterAction: syncPosition,
            responsiveRefreshRate: 200,
        });

        sync2.owlCarousel({
            items: 15,
            itemsDesktop: [1199, 15],
            itemsDesktopSmall: [979, 15],
            itemsTablet: [768, 15],
            itemsMobile: [479, 15],
            pagination: false,
            responsiveRefreshRate: 100,
            afterInit: function (el) {
                el.find(".owl-item").eq(0).addClass("synced");
            }
        });

        function syncPosition(el) {
            var current = this.currentItem;
            $("#sync2")
                .find(".owl-item")
                .removeClass("synced")
                .eq(current)
                .addClass("synced")
            if ($("#sync2").data("owlCarousel") !== undefined) {
                center(current)
            }
        }

        $("#sync2").on("click", ".owl-item", function (e) {
            e.preventDefault();
            var number = $(this).data("owlItem");
            sync1.trigger("owl.goTo", number);
        });

        function center(number) {
            var sync2visible = sync2.data("owlCarousel").owl.visibleItems;
            var num = number;
            var found = false;
            for (var i in sync2visible) {
                if (num === sync2visible[i]) {
                    var found = true;
                }
            }

            if (found === false) {
                if (num > sync2visible[sync2visible.length - 1]) {
                    sync2.trigger("owl.goTo", num - sync2visible.length + 2)
                } else {
                    if (num - 1 === -1) {
                        num = 0;
                    }
                    sync2.trigger("owl.goTo", num);
                }
            } else if (num === sync2visible[sync2visible.length - 1]) {
                sync2.trigger("owl.goTo", sync2visible[1])
            } else if (num === sync2visible[0]) {
                sync2.trigger("owl.goTo", num - 1)
            }

        }

    });


    //// PAGINATON
    //$('body').on('click', '.next', function() { 
    //    var id = $('.content:visible').data('id');
    //    var nextId = $('.content:visible').data('id')+1;
    //    $('[data-id="'+id+'"]').hide();
    //    $('[data-id="'+nextId+'"]').show();

    //    if($('.back:hidden').length == 1){
    //        $('.back').show();
    //    }

    //    if(nextId == 3){
    //    $('.content-holder').hide();
    //    $('.endBox').show();
    //    }
    //});

    //$('body').on('click', '.back', function() { 
    //    var id = $('.content:visible').data('id');
    //    var prevId = $('.content:visible').data('id')-1;
    //    $('[data-id="'+id+'"]').hide();
    //    $('[data-id="'+prevId+'"]').show();

    //    if(prevId == 1){
    //        $('.back').hide();
    //    }    
    //});

    //$('body').on('click', '.edit-previous', function() { 
    //  $('.endBox').hide();
    //    $('.content-holder').show();
    //    $('#content-3').show();
    //});

    $(document).ready(function () {
        $('[data-toggle="tooltip"]').tooltip();



    });
</script>
<script>

    var citySel = "";
    var classSel = "";
    var timingsSel = "";
    var dwnldPpr = "";

    $("#playVideo1").click(function () {
        $('#videoPlayer1').attr("src", "https://www.youtube.com/embed/5hMIKbeH9wk?enablejsapi=1&autoplay=1");

    });

    $("#closeVideo1").click(function () {
        var src = $("#videoPlayer1").attr("src");
        var abc = src.replace("autoplay=1", "autoplay=0");

        $('#videoPlayer1').attr("src", abc);

    });

    $("#topsection").click(function () {
        var src2 = $("#videoPlayer2").attr("src");
        var abc2 = src2;

        $('#videoPlayer2').attr("src", abc2);

        var src3 = $("#videoPlayer3").attr("src");
        var abc3 = src3;

        $('#videoPlayer3').attr("src", abc3);

        var src4 = $("#videoPlayer4").attr("src");
        var abc4 = src4;

        $('#videoPlayer4').attr("src", abc4);
    });



    $("#dwnldIcatPaper").click(function () {

        ga('send', 'event', 'ICAT', 'Download Paper_click1', '' + window.location.href, 0, 0);

        $('.classSelected').removeClass('active');
        dwnldPpr = "";
        $(".classSelected").click(function () {
            $('.classSelected').removeClass('active');
            $(this).toggleClass('active');
            dwnldPpr = this.innerHTML;
        });
        $("#dwnldPaperFinal").click(function () {

            downloadPaper(dwnldPpr);

        });

    });

    function downloadPaper(grade) {
        if (grade === "") {
            showModalPopup("Please Select Class");
        } else {
            ga('send', 'event', 'ICAT', 'Download Paper_click2', '' + grade, 0, 0);
            var url = "";
            if (grade == "6th") {
                url = "https://files.askiitians.com/static_content/Resources/icat/Sample Paper/ICAT (6TH).pdf";
            } else if (grade == "7th") {
                url = "https://files.askiitians.com/static_content/Resources/icat/Sample Paper/ICAT (7TH).pdf";
            } else if (grade == "8th") {
                url = "https://files.askiitians.com/static_content/Resources/icat/Sample Paper/ICAT (8th).pdf";
            } else if (grade == "9th") {
                url = "https://files.askiitians.com/static_content/Resources/icat/Sample Paper/ICAT (9th).pdf";
            } else if (grade == "10th") {
                url = "https://files.askiitians.com/static_content/Resources/icat/Sample Paper/ICAT (10th).pdf";
            } else if (grade == "11th") {
                url = "https://files.askiitians.com/static_content/Resources/icat/Sample Paper/ICAT (11th M).pdf";
            }
            //window.open(url, '_blank');
            //$("#dwnldPaperFinal").href = url;
            $("#dwnldPaperFinal").attr("href", url);
            $("#dwnldPaperFinal").attr("target", "_blank");


        }
    }

    $("#dwnldPaperClose").click(function () {
        dwnldPpr = "";


    });

    //$("#dwnldPaperFinal").click(function () {
    //    if (classSele === "") {
    //        showModalPopup("Please Select Class");
    //    } else {
    //        ga('send', 'event', 'ICAT', 'Download Paper_click2', '' + classSele, 0, 0);
    //        var url = "";
    //        if (classSele == "6th") {
    //            url = "https://files.askiitians.com/static_content/Resources/icat/Sample Paper/ICAT (6TH).pdf";
    //        }
    //        else if (classSele == "7th") {
    //            url = "https://files.askiitians.com/static_content/Resources/icat/Sample Paper/ICAT (7TH).pdf";
    //        }
    //        else if (classSele == "8th") {
    //            url = "https://files.askiitians.com/static_content/Resources/icat/Sample Paper/ICAT (8th).pdf";
    //        }
    //        else if (classSele == "9th") {
    //            url = "https://files.askiitians.com/static_content/Resources/icat/Sample Paper/ICAT (9th).pdf";
    //        }
    //        else if (classSele == "10th") {
    //            url = "https://files.askiitians.com/static_content/Resources/icat/Sample Paper/ICAT (10th).pdf";
    //        }
    //        else if (classSele == "11th") {
    //            url = "https://files.askiitians.com/static_content/Resources/icat/Sample Paper/ICAT (11th M).pdf";
    //        }


    //        window.open(url, '_blank');
    //        $(".close").click(function () {
    //            $('.classSelected').removeClass('active');
    //            classSele = "";
    //        });
    //        //$("#download_icat_paper").hide();


    //    }


    //});

    function nameValidation(element) {
        if ($(element).val().trim() !== "") {
            var regEx = /^[a-zA-Z0-9_ ]*$/;
            var matchArrayVal = $(element).val().trim().match(regEx);
            if (matchArrayVal == null) {
                $(element).focus();
                showModalPopup('Please enter valid name.');

                return false;
            } else {
                return true;
            }
        } else {
            $(element).focus();
            showModalPopup('Please enter Name.');

            return false;
        }
    }

    function emailValidation(element) {
        var e = $(element).val();
        if ($(element).val().trim() == '') {
            showModalPopup('Please enter email id.');
            $(element).focus();
            return false;
        }

        if ($(element).val() != '') {
            var tEmailInputString = /^\w[\w\.\_\-]*\w\@\w[\w\-\.]*\w\.[a-zA-z]{2,3}$/g;
            var tMatchArray = $(element).val().trim().match(tEmailInputString);
            if (tMatchArray == null) {
                $(element).focus();
                showModalPopup('Please enter your valid email id.');

                return false;
            }
        }
        return true;
    }

    function mobileValidation(element) {

        var txtMobile = $(element);
        if (txtMobile.is(":visible")) {
            var contactString = /^\+?[\(\d+\)-]+\d+(-\d+)*$/;
            var matchArray = txtMobile.val().match(contactString);
            if (matchArray == null) {
                showModalPopup("Please enter a valid phone number in Form");

                return false;
            }


            if ((txtMobile.val().length > 20 || txtMobile.val().length < 5) || txtMobile.val().replace(/\D/g, '').length < 5) {
                showModalPopup("Please enter a valid phone number");

                return false;
            }
        }
        return true;
    }

    $("#regSchools").click(function () {

        ga('send', 'event', 'RegisterNow_School', 'Register_nowSchool_Click', '' + window.location.href, 0, 0);

        $("#schoolCountry").val("Select");
        $("#schoolName").val("");
        $("#schoolPerName").val("");
        $("#schoolPerDes").val("");

        $("#schoolFrmNext1").click(function () {
            var country = $("#schoolCountry").val();
            var schoolName = $("#schoolName").val();
            var perName = $("#schoolPerName").val();
            var perDes = $("#schoolPerDes").val();
            var validated = true;
            if (country === "Select") {
                showModalPopup("Please Select Country");
            }
            else if (schoolName === "" || schoolName.length < 5) {
                $("#schoolName").focus();
                showModalPopup("Please enter Valid School Name");

            }
            //if (!nameValidation("#schoolPerName")) {
            //    validated = false;
            //    showModalPopup("Please enter Valid Name");
            //    return false;
            //}
            else if (perName === "" || perName.length < 5) {
                $("#schoolPerName").focus();
                showModalPopup("Please enter Valid Name");
            }
            else if (perDes === "" || perDes.lenght < 5) {
                $("#schoolPerDes").focus();
                showModalPopup("Please enter Valid Designation");

            } else {
                $("#content1").hide();
                $("#content2").show();
                $("#schoolAdd").val("");
                $("#schoolEmail").val("");
                $("#txtMobile").val("");
                ga('send', 'event', 'RegisterNow_School', 'School_Form_next1', '' + window.location.href, 0, 0);


            }


        });


    });

    $("#schoolFrmNext2").click(function () {

        var address = $("#schoolAdd").val();
        var email = $("#schoolEmail").val();
        var mobile = $("#spnCountryName").html() + $("#txtMobile").val();

        var isValid = true;
        if (address === "" || address.length < 5) {
            showModalPopup("Enter a Valid Address");
            $("#schoolAdd").focus();
            isValid = false;
            return isValid;
        } if (!mobileValidation("#txtMobile")) {
            isValid = false;
            return isValid;

        } if (!emailValidation("#schoolEmail")) {
            isValid = false;
            return isValid;
        }

        ga('send', 'event', 'RegisterNow_School', 'School_Form_next2', '' + window.location.href, 0, 0);

        var params = JSON.stringify({
            country: $("#schoolCountry").val(),
            schoolName: $("#schoolName").val(),
            personName: $("#schoolPerName").val(),
            designation: $("#schoolPerDes").val(),
            schoolAdd: address,
            phoneNo: mobile,
            emailId: email
        });

        $.ajax({
            type: "POST",
            url: "Default.aspx/InsertSchool",
            contentType: "application/json; charset=utf-8",
            data: params,
            dataType: "json",
            success: function (data) {
                var result = data.d;

                if (!result.IsError) {

                    $("#content1").hide();
                    $("#content2").hide();
                    $("#content3").show();

                }

                else {
                    $("#content1").hide();
                    $("#content2").hide();
                    $("#content3").hide();
                    showModalPopup(result.ResponseMessage);




                }
            }, error: function (xhr, status, error) {
                var err = JSON.parse(xhr.responseText);
                showModalPopup("There is some problem while doing the requested operation. Please try once again. If you still face same problem please contact administrator.");
            }
        });


    });

    $("#schoolDone").click(function () {
        $("#content3").hide();
        ga('send', 'event', 'RegisterNow_School', 'School_Form_Done', '' + window.location.href, 0, 0);

    });

    $("#closeSchoolPopup,#schoolDone").click(function () {
        $("#content2").hide();
        $("#content3").hide();
        $("#content1").show();

    });

    $("#regStudTop").click(function () {
        ga('send', 'event', 'ICAT', 'Register_Now_Top', '' + window.location.href, 0, 0);
        ctaClicked(this);
    });

    $("#regStudBottom").click(function () {
        ga('send', 'event', 'ICAT', 'Register_Now_Bottom', '' + window.location.href, 0, 0);
        ctaClicked(this);
    });



    $("#country").change(function () {

        if ($("#country").val() === "India") {
            $("#rowCityIndia").show();
            $("#rowCityUae").hide();
            $("#rowCityQatar").hide();

            citySel = "";

            $('.citySelected').removeClass('active');
            $(".citySelected").click(function () {
                $('.citySelected').removeClass('active');
                $(this).toggleClass('active');
                citySel = this.innerHTML;
            });

            $("#timingsIndia").show();
            $("#timingsOthers").hide();
            timingsSel = "10:30 am - 12:30 pm";
        } else if ($("#country").val() === "Qatar") {
            $("#rowCityQatar").show();
            $("#rowCityIndia").hide();
            $("#rowCityUae").hide();
            citySel = "";

            $('.citySelected').removeClass('active');
            $(".citySelected").click(function () {
                $('.citySelected').removeClass('active');
                $(this).toggleClass('active');
                citySel = this.innerHTML;
            });
            $("#timingsIndia").hide();
            $("#timingsOthers").show();

        } else if ($("#country").val() === "UAE") {
            $("#rowCityUae").show();
            $("#rowCityIndia").hide();
            $("#rowCityQatar").hide();
            citySel = "";

            $('.citySelected').removeClass('active');
            $(".citySelected").click(function () {
                $('.citySelected').removeClass('active');
                $(this).toggleClass('active');
                citySel = this.innerHTML;
            });
            $("#timingsIndia").hide();
            $("#timingsOthers").show();
        } else {
            $("#rowCityIndia").hide();
            $("#rowCityQatar").hide();
            $("#rowCityUae").hide();

            $("#timingsIndia").hide();
            $("#timingsOthers").show();

            citySel = "";

        }

    });


    function ctaClicked(e) {
        $("#content-1").show();
        $("#rowCityIndia").hide();
        $("#rowCityQatar").hide();

        $("#rowCityUae").hide();

        $("#timingsIndia").hide();
        $("#timingsOthers").show();
        $("#timingsOthers").val("10 am - 12 pm");

        $('.classSelected').removeClass('active');
        classSel = "";
        $(".classSelected").click(function () {
            $('.classSelected').removeClass('active');
            $(this).toggleClass('active');
            classSel = this.innerHTML;
        });

        $("#studentFrmNext1").click(function () {

            var isValid = true;
            var country = $("#country").val();
            if (classSel == "") {
                showModalPopup("Please Select Class");
                isValid = false;
                return isValid;
            }
            if (country == "Select") {
                showModalPopup("Please Select Country");
                isValid = false;
                return isValid;

            }
            if (country == "India") {
                timingsSel = "10:30 am - 12:30 pm";
                if (citySel == "") {
                    showModalPopup("Please select City");
                    isValid = false;
                    return isValid;
                }
            } else if (country == "Qatar") {
                if (citySel == "") {
                    showModalPopup("Please select City");
                    isValid = false;
                    return isValid;
                }
            } else if (country == "UAE") {
                if (citySel == "") {
                    showModalPopup("Please select City");
                    isValid = false;
                    return isValid;
                }
            }
            if (country != "India") {
                timingsSel = $("#timingsOthers").val();
            }

            ga('send', 'event', 'ICAT', 'Student_Form Next_1_Click', '' + window.location.href, 0, 0);
            $("#content-1").hide();
            $("#content-2").show();
            $("#emailStud").val("");
            $("#nameStud").val("");
            $("#txtMobileStud").val("");







        });

    }

    $("#studentFrmNext2").click(function () {

        var email = $("#emailStud").val();
        var mobile = $("#spnCountryNameStud").html() + $("#txtMobileStud").val();

        var isValid = true;
        if (!nameValidation("#nameStud")) {
            $("#nameStud").focus();
            isValid = false;
            return isValid;
        } if (!mobileValidation("#txtMobileStud")) {
            isValid = false;
            return isValid;

        } if (!emailValidation("#emailStud")) {
            isValid = false;
            return isValid;
        }

        ga('send', 'event', 'ICAT', 'Student_Form Next_2_Click', '' + window.location.href, 0, 0);
        var params = JSON.stringify({
            grade: classSel,
            country: $("#country").val(),
            city: citySel,
            timings: timingsSel,
            name: $("#nameStud").val(),
            phoneNo: mobile,
            emailId: email
        });

        $.ajax({
            type: "POST",
            url: "Default.aspx/InsertStud",
            contentType: "application/json; charset=utf-8",
            data: params,
            dataType: "json",
            success: function (data) {
                var result = data.d;

                if (!result.IsError) {

                    $("#content-1").hide();
                    $("#content-2").hide();
                    $("#content-3").show();
                }

                else {
                    $("#content1").hide();
                    $("#content2").hide();
                    $("#content3").hide();
                    showModalPopup(result.ResponseMessage);




                }
            }, error: function (xhr, status, error) {
                var err = JSON.parse(xhr.responseText);
                showModalPopup("There is some problem while doing the requested operation. Please try once again. If you still face same problem please contact administrator.");
            }
        });

        //$("#content-2").hide();
        //$("#content-3").show();

    });
    $("#regStudentDone").click(function () {
        $("#content-3").hide();
        ga('send', 'event', 'ICAT', 'Student_Form Done', '' + window.location.href, 0, 0);

    });



    $("#regStudentClosePopup,#regStudentDone ").click(function () {
        $("#content-2").hide();
        $("#content-3").hide();
        $("#content-1").show();
        $("#country").val("Select");


    });


</script>
<script>
    AOS.init({
        easing: 'ease-out-back',
        duration: 1000
    });
</script>