<section class="">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h3>Refund Policies:</h3>
                <p>a.   Seats in courses are limited and hence Askiitians operates a cancellation and refund policy. From the time of purchase up-to 30 days the user is eligible for a full credit of fees after deducting the fee of study material (printed/soft copy), courier charges, service Tax (18%) and (d) point</p>
                <p>b.   If the user requires a refund then a refund request for the final amount is forwarded to the course administrator. All eligible requests for refunds should be processed usually within 45 working days of the request.</p>
                <p> c. No refund on self-study course (SSP) , All India test series (AITS) , One on One course &amp; short term courses. (short term course - whose duration is less than a year)</p>
                <p>d.   For all refund(s), 10% of the total fees would be deducted against handling charges while calculating the refund (s)</p>
            </div>
        </div>
    </div>
</section>