import { MenuModel } from '../menuModel';

export class MenuListItems {
  static menuItems: Array<MenuModel> = [] as Array<MenuModel>;
  static notesmenuItems: Array<MenuModel> = [] as Array<MenuModel>;

  static get MenuItems() {
    return (this.menuItems = [
      {
        id: 'Engineering_Exams_menu',
        menuTitle: 'Engineering Exams',
        url: '',
        child: [
          {
            id: 'JEE_Advanced_menu',
            menuTitle: 'JEE Advanced',
            url: '',
            child: [
              {
                id: 'JEE_Advanced_Coaching_menu',
                menuTitle: 'JEE Advanced Coaching',
                url: 'iit-jee/advanced/',
              },
              {
                id: '1_Year_Study_Plan_menu',
                menuTitle: '1 Year Study Plan',
                url: 'iit-jee/advanced/crack-jee-advanced-in-1-year/',
              },
              {
                id: 'Solutions_menu',
                menuTitle: 'Solutions',
                url: 'iit-jee/advanced/solutions.html',
              },
              {
                id: 'Answer_Key_menu',
                menuTitle: 'Answer Key',
                url: 'iit-jee/advanced/answer-key.html',
              },
              {
                id: 'Cut_off_menu',
                menuTitle: 'Cut off',
                url: 'iit-jee/advanced/cut-off.html',
              },
              {
                id: 'Rank_Predictor_menu',
                menuTitle: 'Rank Predictor',
                url: 'iit-jee/advanced/rank-predictor.html',
              },
              {
                id: 'Paper_Pattern_menu',
                menuTitle: 'Paper Pattern',
                url: 'iit-jee/advanced/paper-pattern.html',
              },
              {
                id: 'Important_Books_menu',
                menuTitle: 'Important Books',
                url: 'iit-jee/advanced/important-books.html',
              },
              {
                id: 'Sample_Papers_menu',
                menuTitle: 'Sample Papers',
                url: 'iit-jee/advanced/sample-papers.html',
              },
              {
                id: 'Past_Papers_menu',
                menuTitle: 'Past Papers',
                url: 'iit-jee/advanced/past-year-papers/',
              },
              {
                id: 'Preparation_Tips_menu',
                menuTitle: 'Preparation Tips',
                url: 'iit-jee/advanced/preparation-tips.html',
              },
              {
                id: 'Latest_News_meun',
                menuTitle: 'Latest News',
                url: 'iit-jee/advanced/news/',
              },
            ],
          },
          {
            id: 'JEE_Main_menu',
            menuTitle: 'JEE Main',
            url: '',
            child: [
              {
                id: 'JEE_Main_Exams_menu',
                menuTitle: 'JEE Main Exams',
                url: 'iit-jee/main/',
              },
              {
                id: 'Online_Coaching_menu',
                menuTitle: 'Online Coaching',
                url: 'iit-jee/main/online-coaching/',
              },
              {
                id: 'Solutions_menu',
                menuTitle: 'Solutions',
                url: 'iit-jee/advanced/solutions.html',
              },
              {
                id: 'Answer_Key_menu',
                menuTitle: 'Answer Key',
                url: 'iit-jee/main/answer-key.html',
              },
              {
                id: 'Cut_off_menu',
                menuTitle: 'Cut off',
                url: 'iit-jee/main/cut-off.html',
              },
              {
                id: 'Rank_Predictor_menu',
                menuTitle: 'Rank Predictor',
                url: 'iit-jee/main/rank-predictor.html',
              },
              {
                id: 'Branch_Predictor_menu',
                menuTitle: 'Branch Predictor',
                url: 'iit-jee/main/college-branch-predictor.html',
              },
              {
                id: 'JEE_Main_Syllabus_menu',
                menuTitle: 'JEE Main Syllabus',
                url: 'iit-jee/main/syllabus.html',
              },
              {
                id: 'Paper_Pattern_menu',
                menuTitle: 'Paper Pattern',
                url: 'iit-jee/main/paper-pattern.html',
              },
              {
                id: 'Important_Books_menu',
                menuTitle: 'Important Books',
                url: 'iit-jee/main/important-books.html',
              },
              {
                id: 'Sample_Papers_menu',
                menuTitle: 'Sample Papers',
                url: 'iit-jee/main/sample-papers.html',
              },
              {
                id: 'Past_Year_Papers_menu',
                menuTitle: 'Past Year Papers',
                url: 'iit-jee/main/past-papers/',
              },
              {
                id: 'Preparation_Tips_menu',
                menuTitle: 'Preparation Tips',
                url: 'iit-jee/main/preparation-tips/',
              },
              {
                id: 'Math_Preparation_Tips_menu',
                menuTitle: 'Math Preparation Tips',
                url: 'iit-jee/main/preparation-tips-for-jee-mains-maths.html',
              },
              {
                id: 'Latest_News_meun',
                menuTitle: 'Latest News',
                url: 'iit-jee/main/news/',
              },
            ],
          },
          {
            id: 'IIT_JEE_menu',
            menuTitle: 'IIT JEE',
            url: '',
            child: [
              {
                id: 'IIT_JEE_Exam_Details_menu',
                menuTitle: 'IIT JEE Exam Details',
                url: 'iit-jee/',
              },
              {
                id: 'JEE_Syllabus_menu',
                menuTitle: 'JEE Syllabus',
                url: 'iit-jee-syllabus/pattern-iit-jee.aspx',
              },
              {
                id: 'IIT_JEE_Toppers_Tips_menu',
                menuTitle: 'IIT JEE Toppers Tips',
                url: 'iit-jee/toppers-tips-for-iit-jee.html',
              },
              {
                id: 'IIT_JEE_Preparation_Tips_menu',
                menuTitle: 'IIT JEE Preparation Tips',
                url: 'iit-jee/how-to-prepare-for-iit-jee.html',
              },
              {
                id: 'IIT_JEE_Preparation_Tips_for_Class_11_menu',
                menuTitle: 'IIT JEE Preparation Tips for Class 11',
                url: 'iit-jee/how-to-prepare-for-iit-jee-from-class-11.html',
              },
              {
                id: 'IIT_JEE_Preparation_Tips_for_Class_9_menu',
                menuTitle: 'IIT JEE Preparation Tips for Class 9',
                url: 'iit-jee/how-to-prepare-for-iit-jee-from-class-9.html',
              },
              {
                id: 'IIIT_JEE_Preparation_Tips_for_Class_8_menu',
                menuTitle: 'IIT JEE Preparation Tips for Class 8',
                url: 'iit-jee/how-to-prepare-for-iit-jee-from-class-8.html',
              },
              {
                id: 'IIT_JEE_Preparation_Time_Table_menu',
                menuTitle: 'IIT JEE Preparation Time Table',
                url: 'iit-jee/how-to-prepare-the-best-study-timetable-for-iit-jee.html',
              },
            ],
          },
          {
            id: 'IIT_JEE_Coaching_menu',
            menuTitle: 'IIT JEE Coaching',
            url: '',
            child: [
              {
                id: 'IIT_JEE_Online_Coaching_menu',
                menuTitle: 'IIT JEE Online Coaching',
                url: 'iit-jee/online-coaching/',
              },
              {
                id: 'Correspondence_Course_For_IIT_JEE_menu',
                menuTitle: 'Correspondence Course For IIT JEE',
                url: 'iit-jee/online-coaching/correspondence-course.html',
              },
              {
                id: 'IIT_JEE_Coaching_after_10th_menu',
                menuTitle: 'IIT JEE Coaching after 10th',
                url: 'iit-jee/online-coaching/rip-ii.html',
              },
              {
                id: 'IIT_JEE_Coaching_For_Foundation_Classes_menu',
                menuTitle: 'IIT JEE Coaching For Foundation Classes',
                url: 'iit-jee/online-coaching/foundation.html',
              },
              {
                id: 'JEE_Coaching_Institutes_menu',
                menuTitle: 'JEE Coaching Institutes1',
                url: 'iit-jee/best-coaching-institutes-for-iit-jee.html',
              },
              {
                id: 'IIT_JEE_Coaching _in _Kota_menu',
                menuTitle: 'IIT JEE Coaching in Kota',
                url: 'iit-jee-coaching-in-kota/',
              },
              {
                id: 'IIT_JEE_Coaching_Institutes_In_Kota_menu',
                menuTitle: 'IIT JEE Coaching Institutes In Kota',
                url: 'iit-jee-coaching-institutes-in-kota/',
              },
            ],
          },
          {
            id: 'BITSAT_menu',
            menuTitle: 'BITSAT',
            url: '',
            child: [
              {
                id: 'BITSAT_Examination_menu',
                menuTitle: 'BITSAT Examination',
                url: 'bitsat/',
              },
              {
                id: 'Solutions_menu',
                menuTitle: 'Solutions',
                url: 'past-year-papers/bitsat-papers.aspx',
              },
              {
                id: 'Cut_off_menu',
                menuTitle: 'Cut off',
                url: 'bitsat/cutoffs.html',
              },
              {
                id: 'Rank_Predictor_menu',
                menuTitle: 'Rank Predictor',
                url: 'bitsat/rank-predictor.htm',
              },
              {
                id: 'Paper_Pattern_menu',
                menuTitle: 'Paper Pattern',
                url: 'bitsat/paper-pattern.html',
              },
              {
                id: 'Important_Books_menu',
                menuTitle: 'Important Books',
                url: 'bitsat/important-books.html',
              },
              {
                id: 'Sample_Papers_menu',
                menuTitle: 'Sample Papers',
                url: 'bitsat/sample-paper.html',
              },
              {
                id: 'Past_Papers_menu',
                menuTitle: 'Past  Papers',
                url: 'past-year-papers/bitsat-papers.aspx',
              },
              {
                id: 'Preparation_Tips_menu',
                menuTitle: 'Preparation Tips',
                url: 'bitsat/preparation-tips.html',
              },
              {
                id: 'Branch_Predictor_menu',
                menuTitle: 'Branch Predictor',
                url: 'iit-jee/main/college-branch-predictor.html',
              },
              {
                id: 'Latest_News_meun',
                menuTitle: 'Latest News',
                url: 'bitsat/news.htm',
              },
            ],
          },
          {
            id: 'View_complete_IIT_JEE _Section_menu',
            menuTitle: 'View complete IIT JEE Section',
            url: 'iit-jee/',
          },
          {
            id: 'View_All_Engineering _Exams_menu',
            menuTitle: 'View All Engineering Exams',
            url: 'exams/',
          },
          {
            id: 'Top_Engineering_Colleges_menu',
            menuTitle: 'Top Engineering Colleges',
            url: 'top-colleges.html',
          },
          {
            id: 'Top_Engineering _Branches_menu',
            menuTitle: 'Top Engineering Branches',
            url: 'exams/engineering-branches/',
          },
          {
            id: 'Engineering_Exam_Calendar_menu',
            menuTitle: 'Engineering Exam Calendar',
            url: 'exams/engineering-exams-calendar.html',
          },
          {
            id: 'NEWS_menu',
            menuTitle: 'NEWS',
            url: 'news/',
          },
        ],
      },
      {
        id: 'Medical_Exams_menu',
        menuTitle: 'Medical Exams',
        url: '',
        child: [
          {
            id: 'NEET_(AIPMT)_menu',
            menuTitle: 'NEET (AIPMT)',
            url: '',
            child: [
              {
                id: 'NEET_Entrance_Exam_menu',
                menuTitle: 'NEET Entrance Exam',
                url: 'medical-exams/aipmt/',
              },
              {
                id: 'NEET_Online_Coaching_menu',
                menuTitle: 'NEET Online Coaching',
                url: 'medical-exams/aipmt/online-coaching/',
              },
              {
                id: 'Solutions_menu',
                menuTitle: 'Solutions',
                url: 'medical-exams/aipmt/solutions.html',
              },
              {
                id: 'Answer Key_menu',
                menuTitle: 'Answer Key',
                url: 'medical-exams/aipmt/answer-key.html',
              },
              {
                id: 'Cut_off_menu',
                menuTitle: 'Cut off',
                url: 'medical-exams/aipmt/cut-off/',
              },
              {
                id: 'Rank_Predictor_menu',
                menuTitle: 'Rank Predictor',
                url: 'medical-exams/aipmt/rank-predictor/',
              },
              {
                id: 'Paper_Pattern_menu',
                menuTitle: 'Paper Pattern',
                url: 'medical-exams/aipmt/paper-pattern.html',
              },
              {
                id: 'Syllabus_menu',
                menuTitle: 'Syllabus',
                url: 'medical-exams/aipmt/syllabus.html',
              },
              {
                id: 'Important_Books_menu',
                menuTitle: 'Important Books',
                url: 'medical-exams/aipmt/books.html',
              },
              {
                id: 'Sample_Papers_menu',
                menuTitle: 'Sample Papers',
                url: 'medical-exams/aipmt/sample-papers.html',
              },
              {
                id: 'Past_Papers_menu',
                menuTitle: 'Past Papers',
                url: 'medical-exams/aipmt/previous-year-question-papers.html',
              },
              {
                id: 'NEET_Preparation_Tips_menu',
                menuTitle: 'NEET Preparation Tips',
                url: 'medical-exams/aipmt/how-to-prepare-for-aipmt.html',
              },
              {
                id: 'Participating_States_menu',
                menuTitle: 'Participating States',
                url: 'medical-exams/aipmt/states-participating.html',
              },
            ],
          },
          {
            id: 'AIIMSs_menu',
            menuTitle: 'AIIMSs',
            url: '',
            child: [
              {
                id: 'AIIMS_Examination_menu',
                menuTitle: 'AIIMS Examination',
                url: 'medical-exams/aiims/',
              },
              {
                id: 'AIIMS_Online_Coaching_menu',
                menuTitle: 'AIIMS Online Coaching',
                url: 'medical-exams/aiims/online-coaching/',
              },
              {
                id: 'Sample_Papers_menu',
                menuTitle: 'Sample Papers',
                url: 'medical-exams/aiims/papers.html',
              },
              {
                id: 'Past_Papers_menu',
                menuTitle: 'Past Papers',
                url: 'medical-exams/aiims/past-year-papers.html',
              },
              {
                id: 'Preparation_Tips_menu',
                menuTitle: 'Preparation Tips',
                url: 'medical-exams/aiims/preparation-tips.html',
              },
              {
                id: 'View_all_Medical_Exams_menu',
                menuTitle: 'View all Medical Exams',
                url: 'medical-exams/',
              },
              {
                id: 'Top_Medical_Colleges_menu',
                menuTitle: 'Top Medical Colleges',
                url: 'top-medical-colleges-in-india.html',
              },
              {
                id: 'Latest_News_menu',
                menuTitle: 'Latest News',
                url: 'medical-exams/news/',
              },
            ],
          },
          {
            id: 'Medical_Exam_Coaching_menu',
            menuTitle: 'Medical Exam Coaching',
            url: 'medical-exams/',
          },
          {
            id: 'Best_Medical_Coaching_In_Kota_menu',
            menuTitle: 'Best Medical Coaching In Kota',
            url: 'medical-exams/best-medical-coaching-institutes-in-kota.html',
          },
          {
            id: 'Medical_Exam_Calendar_menu',
            menuTitle: 'Medical Exam Calendar',
            url: 'medical-exams/medical-entrance-exams-calender.html',
          },
        ],
      },
      {
        id: 'School_Exams_menu',
        menuTitle: 'School Exams',
        url: '',
        child: [
          {
            id: 'NTSE_menu',
            menuTitle: 'NTSE',
            url: '',
            child: [
              {
                id: 'NTSE_Examination_menu',
                menuTitle: 'NTSE Examination',
                url: 'ntse/',
              },
              {
                id: 'Notifications_menu',
                menuTitle: 'Notifications',
                url: 'ntse/notifications.html',
              },
              {
                id: 'Application_menu',
                menuTitle: 'Application',
                url: 'ntse/application-form.html',
              },
              {
                id: 'Important_Dates_menu',
                menuTitle: 'Important Dates',
                url: 'ntse/important-dates.html',
              },
              {
                id: 'Eligibility_menu',
                menuTitle: 'Eligibility',
                url: 'ntse/eligibility.html',
              },
              {
                id: 'Syllabus_menu',
                menuTitle: 'Syllabus',
                url: 'ntse/ntse-pattern-syllabus.html',
              },
              {
                id: 'Study_Material_menu',
                menuTitle: 'Study Material',
                url: 'ntse/ntse-study-materials.html',
              },
              {
                id: 'Important_Books_menu',
                menuTitle: 'Important Books',
                url: 'ntse/ntse-books.html',
              },
              {
                id: 'Sample_Papers_menu',
                menuTitle: 'Sample Papers',
                url: 'ntse/sample-papers.html',
              },
              {
                id: 'Past_Papers_menu',
                menuTitle: 'Past Papers',
                url: 'ntse/past-papers.html',
              },
              {
                id: 'Preparation_Tips_menu',
                menuTitle: 'Preparation Tips',
                url: 'ntse/preparation-tips.html',
              },
            ],
          },
          {
            id: 'KVPY_menu',
            menuTitle: 'KVPY',
            url: '',
            child: [
              {
                id: 'KVPY_Examination_menu',
                menuTitle: 'KVPY Examination',
                url: 'kvpy.html',
              },
              {
                id: 'Application_menu',
                menuTitle: 'Application',
                url: 'kvpy/how-to-apply.html',
              },
              {
                id: 'Important_Dates_menu',
                menuTitle: 'Important Dates',
                url: 'kvpy/important-dates.html',
              },
              {
                id: 'Eligibility_menu',
                menuTitle: 'Eligibility',
                url: 'kvpy/eligibility.html',
              },
              {
                id: 'Syllabus_menu',
                menuTitle: 'Syllabus',
                url: 'kvpy/syllabus.html',
              },
              {
                id: 'Study_Material_menu',
                menuTitle: 'Study Material',
                url: 'kvpy/study-material-kvpy.html',
              },
              {
                id: 'Important_Books_menu',
                menuTitle: 'Important Books',
                url: 'kvpy/important-books.html',
              },
              {
                id: 'Sample_Papers_menu',
                menuTitle: 'Sample Papers menu',
                url: 'kvpy/sample-papers.html',
              },
              {
                id: 'Past_Papers_menu',
                menuTitle: 'Past Papers',
                url: 'kvpy/kvpy-papers/',
              },
              {
                id: 'Preparation_Tip_menu',
                menuTitle: 'Preparation Tip',
                url: 'kvpy/preparation-tips.html',
              },
            ],
          },
          {
            id: 'Olympiads_menu',
            menuTitle: 'Olympiads',
            url: '',
            child: [
              {
                id: 'Olympiads_Examination_menu',
                menuTitle: 'Olympiads Examination',
                url: 'olympiads.aspx',
              },
              {
                id: 'INJSO_menu',
                menuTitle: 'INJSO',
                url: 'olympiads/indian-national-junior-science-olympiad/',
              },
              {
                id: 'RMO_menu',
                menuTitle: 'RMO',
                url: 'olympiads/regional-mathematical-olympiad/',
              },
              {
                id: 'Indian_National_Mathematics_Olympiad_menu',
                menuTitle: 'Indian National Mathematics Olympiad',
                url: 'olympiads/indian-national-mathematics-olympiad/',
              },
              {
                id: 'Physics_Olympiad_menu',
                menuTitle: 'Physics Olympiad',
                url: 'olympiads/physics/',
              },
              {
                id: 'Chemistry_Olympiad_menu',
                menuTitle: 'Chemistry Olympiad',
                url: 'olympiads/chemistry/',
              },
              {
                id: 'Biology Olympiad_menu',
                menuTitle: 'Biology Olympiad',
                url: 'olympiads/biology/',
              },
              {
                id: 'Olympiads_Sample_Papers_menu',
                menuTitle: 'Olympiads Sample Papers',
                url: 'olympiads/sample-papers.html',
              },
              {
                id: 'RMO_Papers_menu',
                menuTitle: 'RMO Papers',
                url: 'olympiads/regional-mathematical-olympiad-past-year-papers/',
              },
              {
                id: 'INMO_Papers_menu',
                menuTitle: 'INMO Papers',
                url: 'olympiads/inmo-question-papers-with-solutions/',
              },
              {
                id: 'Latest_News_menu',
                menuTitle: 'Latest News',
                url: 'school-exams/news/',
              }
            ],
          },
          {
            id: 'CBSE_menu',
            menuTitle: 'CBSE',
            url: '',
            child: [
              {
                id: 'CBSE_School_Exams_menu',
                menuTitle: 'CBSE School Exams',
                url: 'school-exams/',
              },
              {
                id: 'Class_XII_menu',
                menuTitle: 'Class XII',
                url: 'school-exams/cbse/class-12.html',
              },
              {
                id: 'Class_XI_menu',
                menuTitle: 'Class XI',
                url: 'school-exams/cbse/class-11.html',
              },
              {
                id: 'Class_X_menu',
                menuTitle: 'Class X',
                url: 'school-exams/cbse/class-10.html',
              },
              {
                id: 'Class_IX_menu',
                menuTitle: 'Class IX',
                url: 'school-exams/cbse/class-9.html',
              },
              {
                id: 'Class_VIII_menu',
                menuTitle: 'Class VIII',
                url: 'school-exams/cbse/class-8.html',
              },
              {
                id: 'Class_VII_menu',
                menuTitle: 'Class VII',
                url: 'school-exams/cbse/class-7.html',
              },
              {
                id: 'Class_VI_menu',
                menuTitle: 'Class VI',
                url: 'school-exams/cbse/class-6.html',
              }
            ],
          },
          {
            id: 'ICSE_menu',
            menuTitle: 'ICSE',
            url: '',
            child: [
              {
                id: 'CSolutions_for_Board_Exam_menu',
                menuTitle: 'CSolutions for Board Exam',
                url: 'school-exams/cisce/',
              },
              {
                id: 'Class_XII_menu',
                menuTitle: 'Class XII',
                url: 'school-exams/cisce/isc.html',
              },
              {
                id: 'Class_XI_menu',
                menuTitle: 'Class XI',
                url: 'school-exams/cisce/class-11.html',
              },
              {
                id: 'Class_X_menu',
                menuTitle: 'Class X',
                url: 'school-exams/cisce/icse.html',
              },
              {
                id: 'Class_IX_menu',
                menuTitle: 'Class IX',
                url: 'school-exams/cisce/class-9.html',
              },
              {
                id: 'Class_VIII_menu',
                menuTitle: 'Class VIII',
                url: 'school-exams/cisce/class-8.html',
              },
              {
                id: 'Class_VII_menu',
                menuTitle: 'Class VII',
                url: 'school-exams/cisce/class-7.html',
              },
              {
                id: 'Class_VI_menu',
                menuTitle: 'Class VI',
                url: 'school-exams/cisce/class-6.html',
              }
            ],
          },
        ],
      },
      {
        id: 'Past_Papers_menu',
        menuTitle: 'Past Papers',
        url: '',
        child: [
          {
            id: 'Engineering_Exams_menu',
            menuTitle: 'Engineering Exams',
            url: '',
            child: [
              {
                id: 'JEE_Main_menu',
                menuTitle: 'JEE Main',
                url: 'iit-jee/main/past-papers/',
              },
              {
                id: 'JEE_Advanced_menu',
                menuTitle: 'JEE Advanced',
                url: 'iit-jee/advanced/past-year-papers/',
              },
              {
                id: 'BITSAT_menu',
                menuTitle: 'BITSAT',
                url: 'past-year-papers/bitsat-papers.aspx',
              },
              {
                id: 'Karnataka_CET_menu',
                menuTitle: 'Karnataka CET',
                url: 'past-year-papers/kcet-papers.aspx',
              },
              {
                id: 'DCE_menu',
                menuTitle: 'DCE',
                url: 'past-year-papers/dce-papers.aspx',
              },
              {
                id: 'EAMCET_menu',
                menuTitle: 'EAMCET',
                url: 'eamcet/papers.html',
              },
            ],
          },
          {
            id: 'Medical_Exams_menu',
            menuTitle: 'Medical Exams',
            url: '',
            child: [
              {
                id: 'NEET_menu',
                menuTitle: 'NEET',
                url: 'medical-exams/aipmt/previous-year-question-papers.html',
              },
              {
                id: 'AIIMS_menu',
                menuTitle: 'AIIMS',
                url: 'medical-exams/aiims/past-year-papers.html',
              },
              {
                id: 'Manipal_UGE_menu',
                menuTitle: 'Manipal UGE',
                url: 'past-year-papers/manipal-uget.aspx',
              },
            ],
          },
          {
            id: 'School_Exams_menu',
            menuTitle: 'School Exams',
            url: '',
            child: [
              {
                id: 'KVPY_menu',
                menuTitle: 'KVPY',
                url: 'kvpy/kvpy-papers/',
              },
              {
                id: 'NTSE_menu',
                menuTitle: 'NTSE',
                url: 'ntse/past-papers.html',
              },
              {
                id: 'Olympiads_menu',
                menuTitle: 'Olympiads',
                url: 'olympiads/sample-papers.html',
              },
              {
                id: 'Class_12_menu',
                menuTitle: 'Class 12',
                url: 'school-exams/cbse/class-12/past-papers.html',
              },
              {
                id: 'Class_11_menu',
                menuTitle: 'Class 11',
                url: 'school-exams/cbse/class-11.html',
              },
              {
                id: 'Class_10_menu',
                menuTitle: 'Class 10',
                url: 'school-exams/cbse/class-10/past-papers.html',
              },
              {
                id: 'Class_9_menu',
                menuTitle: 'Class 9',
                url: 'school-exams/cbse/class-9.html',
              },
              {
                id: 'Class_8_menu',
                menuTitle: 'Class 8',
                url: 'school-exams/cbse/class-8.html',
              },
              {
                id: 'Class_7_menu',
                menuTitle: 'Class 7',
                url: 'school-exams/cbse/class-7.html',
              },
              {
                id: 'Class_6_menu',
                menuTitle: 'Class 6',
                url: 'school-exams/cbse/class-6.html',
              },
            ],
          },
        ],
      },
      {
        id: 'NCERT_Solutions_menu',
        menuTitle: 'NCERT Solutions',
        url: '',
        child: [
          {
            id: 'NCERT_Class_12_Solutions_menu',
            menuTitle: 'NCERT Class 12 Solutions',
            url: 'cbse/class-12/ncert-solutions.html',
          },
          {
            id: 'NCERT_Class_11_Solutions_menu',
            menuTitle: 'NCERT Class 11 Solutions',
            url: 'cbse/class-11/ncert-solutions.html',
          },
          {
            id: 'NCERT_Class_10_Solutions_menu',
            menuTitle: 'NCERT Class 10 Solutions',
            url: 'cbse/class-10/ncert-solutions.html',
          },
          {
            id: 'NCERT_Class_9_Solutions_menu',
            menuTitle: 'NCERT Class 9 Solutions',
            url: 'cbse/class-9/ncert-solutions.html',
          },
          {
            id: 'NCERT_Class_8_Solutions_menu',
            menuTitle: 'NCERT Class 8 Solutions',
            url: 'class-8-ncert-solutions.html',
          },
          {
            id: 'NCERT_Class_7_Solutions_menu',
            menuTitle: 'NCERT Class 7 Solutions',
            url: 'class-7-ncert-solutions.html',
          },
          {
            id: 'NCERT_Class_6_Solutions_menu',
            menuTitle: 'NCERT Class 6 Solutions',
            url: 'class-6-ncert-solutions.html',
          },
        ],
      },
      {
        id: 'Books_menu',
        menuTitle: 'Books',
        url: '',
        child: [
          {
            id: 'List_of_JEE_Main_&_JEE_Advanced_Books_menu',
            menuTitle: 'List of JEE Main & JEE Advanced Books',
            url: 'important-books-iit-jee.aspx'
          },
          {
            id: 'RD_Sharma_Solutions_menu',
            menuTitle: 'RD Sharma Solutions',
            url: '',
            child: [
              {
                id: 'R.D._Sharma_Solutions_PDF_menu',
                menuTitle: 'R.D. Sharma Solutions PDF',
                url: 'rd-sharma-solutions/',
              },
              {
                id: 'Class_XII_menu',
                menuTitle: 'Class XII',
                url: 'rd-sharma-solutions/class-12/',
              },
              {
                id: 'Class_XI_menu',
                menuTitle: 'Class XI',
                url: 'rd-sharma-solutions/class-11/',
              },
              {
                id: 'Class_X_menu',
                menuTitle: 'Class X',
                url: 'rd-sharma-class-10-solutions/',
              },
              {
                id: 'Class_IX_menu',
                menuTitle: 'Class IX',
                url: 'rd-sharma-class-9-solutions/',
              },
              {
                id: 'Class_VIII_menu',
                menuTitle: 'Class VIII',
                url: 'rd-sharma-class-8-solutions/',
              },
              {
                id: 'Class_VII_menu',
                menuTitle: 'Class VIII',
                url: 'rd-sharma-class-7-solutions/',
              },
              {
                id: 'Class_VI_menu',
                menuTitle: 'Class VI',
                url: 'rd-sharma-class-6-solutions/',
              },
            ],
          },
          {
            id: 'HC_Verma_Solutions_menu',
            menuTitle: 'HC Verma Solutions',
            url: '',
            child: [
              {
                id: 'Concepts_of_Physics_by_HC_Verma_for_JEE_menu',
                menuTitle: 'Concepts of Physics by HC Verma for JEE',
                url: 'book-review/iit-jee-physics-h-c-verma/',
              },
              {
                id: 'HC_Verma_Solutions_Part_1_menu',
                menuTitle: 'HC Verma Solutions Part 1',
                url: 'book-review/iit-jee-physics-h-c-verma/hc-verma-solutions-vol-1.aspx',
              },
              {
                id: 'HC_Verma_Solutions_Part_2_menu',
                menuTitle: 'HC Verma Solutions Part 2',
                url: 'book-review/iit-jee-physics-h-c-verma/hc-verma-solutions-vol-2.aspx',
              },
            ],
          },
        ],
      },
    ] as Array<MenuModel>);
  };

  static get NotesMenuItems() {
    return (
      //notes menu
      this.notesmenuItems = [
        {
          id: 'Study_Material_(JEE/NEET)_menu',
          menuTitle: 'Study Material (JEE/NEET)',
          url: '',
          child: [
            {
              id: 'Physics_menu',
              menuTitle: 'Physics',
              url: 'iit-study-material/iit-jee-physics/',
            },
            {
              id: 'Chemistry_menu',
              menuTitle: 'Chemistry',
              url: 'iit-study-material/iit-jee-chemistry/',
            },
            {
              id: 'Maths_menu',
              menuTitle: 'Maths',
              url: 'iit-study-material/iit-jee-mathematics/',
            },
            {
              id: 'Biology_menu',
              menuTitle: 'Biology',
              url: 'biology',
            },
            {
              id: 'View_Complete_Study_Material_menu',
              menuTitle: 'View Complete Study Material',
              url: 'iit-study-material/',
            },
          ],
        },
        {
          id: 'Study+Notes_{(JEE/NEET)_menu',
          menuTitle: 'Study Notes (JEE/NEET)',
          url: '',
          child: [
            {
              id: 'Physics_menu',
              menuTitle: 'Physics',
              url: 'revision-notes/physics/',
            },
            {
              id: 'Chemistry_menu',
              menuTitle: 'Chemistry',
              url: 'revision-notes/chemistry/',
            },
            {
              id: 'Maths_menu',
              menuTitle: 'Maths',
              url: 'revision-notes/maths/',
            },
            {
              id: 'Biology_menu',
              menuTitle: 'Biology',
              url: 'revision-notes/biology/',
            },
            {
              id: 'View_Complete_Revision_Notes_menu',
              menuTitle: 'View Complete Revision Notes',
              url: 'revision-notes/',
            },
          ],
        },
      ] as Array<MenuModel>);
  }
}
