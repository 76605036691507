import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationType } from 'src/app/enum/NotificationType';
import { ContactUs } from 'src/app/Models/ContactUsModel';
import { examList } from 'src/app/Models/ModelsList/ExamList';
import { contactUsService } from 'src/app/services/contactUs.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-contact-usform',
  templateUrl: './contact-usform.component.html',
  styleUrls: ['./contact-usform.component.css']
})
export class ContactUsformComponent implements OnInit{
  contactForm: FormGroup;
  errorMessage: string = '';
  ContactUs!: ContactUs;

  
  grades = ['6', '7', '8', '9', '10', '11', '12', '12th pass'];
  exams = examList;
  targetYears = [2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032];

  constructor(private fb: FormBuilder,
    private contactService: contactUsService,
    private notificationService:NotificationService
  ) {
    this.contactForm = this.fb.group({
      name: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
      email: ['', [Validators.required, Validators.email]],
      PhoneNo: ['', [Validators.required, Validators.pattern('^[0-9]{10,15}$')]],
      grade: ['', Validators.required],
      exam: ['', Validators.required],
      targetYear: ['', Validators.required]
    });
  }

  ngOnInit(): void {}

  onSubmit() {
    this.ContactUs = {
      name: this.contactForm.get('name')?.value,
      email: this.contactForm.get('email')?.value,
      phoneNo: this.contactForm.get('PhoneNo')?.value,
      grade: this.contactForm.get('grade')?.value,
      exam: this.contactForm.get('exam')?.value,
      targetYear: this.contactForm.get('targetYear')?.value,
    };
   
    this.contactService.createContactUsDetails(this.ContactUs).subscribe((message: any) => {
      if(message=="Mail Successfully Send")
      {
      this.notificationService.notifyMessage(NotificationType.Success, `Thank you for contacting us.`);
      this.contactForm.reset({
        name: '',
        email: '',
        PhoneNo: '',
        grade: '',       // Set dropdowns to empty string for "Select" options
        exam: '',
        targetYear: ''
      });
      }
      else
      {
        this.notificationService.notifyMessage(NotificationType.Success, `Something went wrong!.`);

      }
    });
  }

}
