import { Injectable } from '@angular/core';
import { ApiCallService } from './api-call.service';
import { endPoints } from '../api-end-points';
import { Observable } from 'rxjs';
import { Registration } from '../Models/RegistrationModel';
import { UserDetails } from '../Models/UserDetailsModel';
import { UserModel } from '../Models/UserModel';
import { UserAddress } from '../Models/UserAddress';
import { Askiitians, Key, Message } from '../Models/AuthModel';
import { GoogleResponseRequestModel } from '../Models/GoogleResponseModel';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private apiService: ApiCallService, private http: HttpClient) { }

  getEncryptObject(post: Askiitians): Observable<string> {
    return this.apiService.Post(endPoints.ENCRYPT_OBJECT, JSON.stringify(post), {}, 'text');
  }

  getAccountLogin(msg: Message): Observable<string> {
    return this.apiService.Post(endPoints.LOGIN, JSON.stringify(msg), {}, 'text');
  }

  getDecryptObject(post: Key): Observable<string> {
    return this.apiService.Post(endPoints.DECRYPT_OBJECT, JSON.stringify(post), {}, 'text');
  }

  checkPhoneNO(mobile: string): Observable<any> {
    const params = { $select: 'Name,Mobile,Id', $filter: `Mobile eq '${mobile}' ` };
    return this.apiService.Get(endPoints.CHECK_PHONE_NO, params);
  }
  checkUser(mobile: string): Observable<any> {
    let para = encodeURIComponent(`${mobile}`);
    return this.apiService.Get(`${endPoints.CHECK_USER}?Mobile=${para}`, null);
  }
  checkEmailId(email: any): Observable<any> {
    const params = { $select: 'Name,Email,Id,Mobile,Password', $filter: `Email eq '${email}' ` };
    return this.apiService.Get(endPoints.CHECK_EMAIL_ID, params);
  }
  loginWithEmailId(logindata:any): Observable<any> {
    // const url = `${email}/${password}`;
    return this.apiService.Post(endPoints.lOGIN_USING_EMAIL_ID,JSON.stringify(logindata));
  }
  forgotPassword(emailId: any): Observable<any> {
    const params = { Email: emailId };
    return this.apiService.Get(endPoints.FORGOT_PASSWORD_USING_EMAIL_ID, params)
  }
  create(post: Registration): Observable<any> {
    return this.apiService.Post(endPoints.ADD_USER, JSON.stringify(post));
  }

  edit(PUT: Registration, Id: number): Observable<any> {
    return this.apiService.Put(endPoints.ADD_USER + '/' + Id, JSON.stringify(PUT));
  }

  createUserDetails(post: UserDetails): Observable<any> {
    return this.apiService.Post(endPoints.USER_DETAILS, JSON.stringify(post));
  }

  editUserDetails(PUT: UserDetails, Id: number): Observable<any> {
    return this.apiService.Put(endPoints.USER_EDIT_DETAILS + Id, JSON.stringify(PUT));
  }

  getUserFromID(Id: number): Observable<UserModel> {
    return this.apiService.Get(endPoints.GET_USER_BY_ID + Id);
  }

  GetEdmingleClassLink(firstName: string, email: string, lastName: string): Observable<any> {
    const params = { FirstName: `${firstName}`, LastName: `${lastName}`, Email: `${email}` };
    // const url = `${firstName.replace(' ','/')}/${email}`;
    var postData = JSON.stringify(params);
    return this.apiService.Post(endPoints.GET_CLASS_LINK, postData);
  }

  //get test series url
  GetTestSeriesLink(userID: string): Observable<any> {
    const params = { UserId: `${userID}` };
    return this.apiService.Get(endPoints.GET_TEST_SERIES_LINK + '/' + userID);
  }

  getGradeFromID(Id: number): Observable<any> {
    return this.apiService.Get(endPoints.GET_GRADE_BY_ID + Id);
  }

  getAddessDetails(): Observable<any> {
    return this.apiService.Get(endPoints.GET_ADDRESS_DETAILS);
  }

  getAddessDetailsFromPhone(phone: string): Observable<any> {
    const params = { '$filter': `Phone eq  '${phone}' `, '$select': `Id,Name,Pincode,Address,Landmark,City,State,Country,Phone` };
    return this.apiService.Get(endPoints.GET_ADDRESS_DETAILS_FILTER, params);
  }

  createAddressdetails(post: UserAddress): Observable<any> {
    return this.apiService.Post(endPoints.GET_ADDRESS_DETAILS, JSON.stringify(post));
  }

  EditAddressdetails(PUT: UserAddress, Id: number): Observable<any> {
    return this.apiService.Put(endPoints.GET_ADDRESS_DETAILS + '/' + Id, JSON.stringify(PUT));
  }
  //Google Sign in
  saveGoogleResponse(post: GoogleResponseRequestModel): Observable<any> {
    return this.apiService.Post(endPoints.SAVE_GOOGLE_SIGNIN_RESPONSE, JSON.stringify(post))
  }


  updatePhoneGradeDetails(userData: any): Observable<any> {
    let para = encodeURIComponent(`${userData.mobile}`);
    const params = `UserId=${userData.userDetails.userId}&Mobile=${para}&Grade=${userData.userDetails.grade}`;
    return this.apiService.Post(endPoints.UPADTE_PHONE_AND_GRADE + params, null);
  }

  updateUserDetail(id: any, userdata: any): Observable<any> {
    return this.apiService.Put(endPoints.UPDATE_USER_DETAILS + id, JSON.stringify(userdata), { 'Content-Type': 'application/json; charset=utf-8' }, 'text');
  }

  getQuestionPointsProfile(startIndex: number, endIndex: number, userId: number): Observable<any> {
    return this.apiService.Get(endPoints.TOTAL_QUESTION_DETAILS_BY_USER + userId + `/${startIndex}/${endIndex}`);
  }
  getTotalPostByUser(userId: number): Observable<any> {
    return this.apiService.Get(endPoints.TOTAL_POST_BY_USER + userId);
  }

  getTotalAnswerDetailsByUser(startIndex: number, endIndex: number, userId: number): Observable<any> {
    return this.apiService.Get(endPoints.TOTAL_ANSWER_DETIALS_BY_USER + userId + `/${startIndex}/${endIndex}`);
  }

}
