export const cityList: string[] = [
    "Agartala",
    "Agra",
    "Ahmedabad",
    "Aizawl",
    "Ajmer",
    "Aligarh",
    "Allahabad",
    "Alwar",
    "Ambedkar Nagar",
    "Amritsar",
    "Auraiya",
    "Aurangabad",
    "Azamgarh",
    "Badaun",
    "Bahraich",
    "Balarampur",
    "Ballia",
    "Banda",
    "Bangalore",
    "Barabanki",
    "Bareilly",
    "Basti",
    "Bhagalpur",
    "Bhopal",
    "Bhubaneswar",
    "Bijnor",
    "Bikaner",
    "Bulandshahr",
    "Burdwan",
    "Canacona",
    "Chandigarh",
    "Chennai",
    "Chitrakoot",
    "Cochin",
    "Coimbatore",
    "Cuttack",
    "Darjeeling",
    "Dehradun",
    "Delhi",
    "Dhanbad",
    "Dharamsala",
    "Dispur",
    "Durgapur",
    "Etah",
    "Etawah",
    "Faizabad",
    "Faridabad",
    "Farrukhabad",
    "Fatehpur",
    "Firozabad",
    "Gandhinagar",
    "Gangtok",
    "Gaya",
    "Ghaziabad",
    "Gonda",
    "Gorakhpur",
    "Gulmarg",
    "Gurgaon",
    "Guwahati",
    "Gwalior",
    "Hampi",
    "Hardoi",
    "Haridwar",
    "Hassan",
    "Howrah",
    "Hyderabad",
    "Imphal",
    "Indore",
    "Itanagar",
    "Jabalpur",
    "Jaipur",
    "Jalaun",
    "Jammu",
    "Jamshedpur",
    "Jhansi",
    "Jodhpur",
    "Kalyan-Dombivli",
    "Kannauj",
    "Kanpur",
    "Kanpur Dehat",
    "Kanshiram Nagar",
    "Kanyakumari",
    "Kaushambi",
    "Kavaratti",
    "Khajuraho",
    "Kodaikanal",
    "Kohima",
    "Kolkata",
    "Konark",
    "Kota",
    "Kovalam",
    "Kullu",
    "Ladakh",
    "Leh",
    "Lucknow",
    "Ludhiana",
    "Madurai",
    "Mahamaya Nagar",
    "Mahoba",
    "Mainpuri",
    "Manali",
    "Mangalore",
    "Mathura",
    "Mau",
    "Meerut",
    "Moradabad",
    "Mumbai",
    "Munnar",
    "Murshidabad",
    "Muzaffarnagar",
    "Mysore",
    "Nagpur",
    "Nainital",
    "Nalanda",
    "Nashik",
    "Navi Mumbai",
    "New Delhi",
    "Noida",
    "Orchha",
    "Pahalgam",
    "Panaji",
    "Patiala",
    "Patna",
    "Pilani",
    "Pilibhit",
    "Pimpri Chinchwad",
    "Ponda",
    "Pratapgarh",
    "Puducherry",
    "Pune",
    "Puri",
    "Raebareli",
    "Raipur",
    "Rajgir",
    "Rajkot",
    "Rameshwaram",
    "Rampur",
    "Ranchi",
    "Rishikesh",
    "Saharanpur",
    "Sant Kabir Nagar",
    "Shillong",
    "Shimla",
    "Shravasti",
    "Siddharthnagar",
    "Sitapur",
    "Solapur",
    "Srinagar",
    "Sultanpur",
    "Surat",
    "Tezpur",
    "Thane",
    "Thanjavur",
    "Tirupati",
    "Trichy",
    "Trivandrum",
    "Udupi",
    "Unnao",
    "Updaipur",
    "Vadodara",
    "Vaishali",
    "Varanasi",
    "Vasco Da Gama",
    "Vijayawada",
    "Visakhapatnam",
    "Warangal"
  ];
  