
<section class="AIFranchiseeWrap">
    <div class="container">
        <div class="row">
            
            <div class="col-md-8">
                <div class="franchisesCotent">
                    <h1>Franchisee Program for <strong>IIT JEE (Main/Advanced), NEET (AIPMT) &amp; School Board (Class 6-10)</strong></h1>
                                                
                            <h2> <strong>Askiitians Franchisee : An Overview</strong></h2>
                                               
                                <p>
                                        IIT JEE/ JEE Main /Advanced/ and many other state board engineering examination
                                        is the prime choice for all the students after class 10<sup>th</sup>. AskIITians
                                        gives you a chance to open up your own center with the edge of an ex-IITians brand.
                                        AskIITians will provide all the Faculties, Study Material, DPP (Daily Practice Papers),
                                        AITS (All India Test Series) and support for the students at your center
                                </p>
                                <p>
                                        AskIITians is the most credible, pioneering and innovative company in the field
                                        of online coaching to the students in India and in Middle East.
                                </p>
                                <p>
                                        AskIITians franchisees are proud of themselves because of spirit of innovation here
                                        at askIITians. We provide you the power of online coaching for some of the most
                                        prestigious examinations in India regardless of your boundaries and location as
                                        we can reach you as long as you have an internet connection at your center and we
                                    provide you the most comprehensive and efficient marketing model for your center.</p>
                                    
                                    <p>
                                        <strong>Askiitians Franchise: Start a “Smart Online Coaching Centre”</strong></p>
                                <p>
                                    <strong>A Budget Coaching Concept – first time in India and abroad</strong></p>
                                <p>
                                    <strong>Welcome to the recession proof concept</strong></p>
                                <p>
                                        <strong>Industry: </strong>Engineering (IIT JEE / JEE Main /Advanced / All State
                                    Level Engineering Entrance Exams)</p>
                                <p>
                                        CBSE (9<sup>th</sup> / 10<sup>th</sup> / 11<sup>th</sup> / 12<sup>th</sup> / - for
                                    Physics, Chemistry, Maths )</p>
                                <p>
                                    <strong>Parent organization – TransWeb Educational&nbsp; Services Pvt. Ltd.</strong></p>
                                <p>
                                    <strong>Area required – 600 to 800 square feet</strong></p>
                                                            
                                <div class="franchisee-txt">
                                    <div class="head"><div class="ico"></div>AskIITians Franchisee :</div>
                                    <ul>
                                          <li><a href="https://www.askiitians.com/franchisee/pune.html">AskIITians Center in Pune</a></li>
                                          <li><a href="https://www.askiitians.com/franchisee/imphal.html">AskIITians Center in Imphal</a></li>
                                          <li><a href="https://www.askiitians.com/franchisee/kolkata.aspx">  AskIITians Center in Kolkata </a></li>
                                          <li><a href="https://www.askiitians.com/franchisee/ramgarh.html">AskIITians Center in Ramgarh</a></li>
                                          <li><a href="https://www.askiitians.com/franchisee/hyderabad.aspx">AskIITians Center in Hyderabad</a></li>
                                           <li><a href="https://www.askiitians.com/franchisee/hazaribagh.html">AskIITians Center in Hazaribagh</a></li>
                                    </ul>
                                </div>

                                <div class="contact-frm">
                                    <div class="head">Quick Contact Form</div>
                                    <div class="subhead">Leave us your details – our experts will get in touch with you</div>
                                    <form [formGroup]="franchiseeForm" (ngSubmit)="onSubmit()" id="contactForm">
                                    <div class="row">
                                            <div class="frmfield">
                                                    <input type="text" formControlName="name" placeholder="Name" maxlength="50" required>
                                            </div>
                                            <div class="frmfield">
                                                    <input type="email" formControlName="email" placeholder="E-Mail" maxlength="50" required>
                                            </div>
                                    </div>
                                    <div class="row">
                                            <div class="frmfield">
                                                    <input type="tel" formControlName="cell" placeholder="Cell-No." maxlength="10" pattern="\d{10}" required>
                                            </div>
                                            <div class="frmfield">
                                                    <input type="tel" formControlName="landline" placeholder="LandLine Number" maxlength="11" pattern="\d{11}">
                                            </div>
                                    </div>
                                    <div class="row">
                                            <div class="frmfield">
                                                    <textarea formControlName="address" placeholder="Address"></textarea>
                                            </div>
                                            <div class="frmfield">
                                                    <textarea formControlName="query" placeholder="Query Text"></textarea>
                                            </div>
                                    </div>
                                    <button type="submit" class="submit-btn">Submit</button>
                                    </form>
                                </div>
                                                        
                                                        
                                <div class="clear"></div>

                                <label id="lbl_msg" runat="server" text="" forecolor="Red" font-bold="true">
                                    <div class="medical-franchisee">
                                        <div class="ico"></div>
                                        To start a medical franchisee visit :
                                        <a href="http://www.emedicalprep.com/"><strong>www.emedicalprep.com</strong></a>
                                    </div>
                                </label>
                                  
                                  <br>
                                  <strong>Support services:</strong>
                                  <ul>
                                          <li>Askiitians Brand Name</li>
                                          <li>Centre design</li>
                                          <li>Course Curriculum</li>
                                          <li>Operational Support</li>
                                          <li>Branding, Marketing and promotion</li>
                                          <li>Technical assistance</li>
                                          <li>Only Ex-IITians as teachers</li>
                                          <li>Study Material, AITS, DPPs (Hard copy)</li>
                                  </ul>
                                  <br>

                                  <strong> Highlights:</strong>
                                  <ul>
                                          <li>Participation and profit sharing in school Integration program</li>
                                          <li>Managed by pioneer of online education in India and abroad ( Group of ex-iitians and foreign qualified visionary professionals)</li>
                                          <li>Vast experience in online teaching industry</li>
                                  </ul> 
                </div>
            </div>

            <div class="col-md-4">
                        <div id="ask-ques">
                        <div class="box-dflt">
                            <div class="head">
                                Related Resources
                            </div>
                            <div class="bdr-line">
                                <div class="bdr-line-haf">
                                </div>
                            </div>
                            <div class="grey-box bdr bdr-wth">
                                <div class="witecont bdr bdr-wth">
                                    <a href="nrp-program.aspx">National Resource Partner Program </a>
                                    <div class="box-dflt">
                                        <img data-original=""
                                        src="https://files.askiitians.com/static_content/Resources/images/deflt-paper.png"
                                        class="flt-left lazy" border="0">
                                        <p>
                                            National Resource Centre (NRC) Program About...
                                        </p>
                                        <div class="clear">
                                        </div>
                                    </div>
                                </div>
                                <div class="witecont bdr bdr-wth">
                                    <a href="benefits.html">Franchisee Benefits </a>
                                    <div class="box-dflt">
                                        <img data-original=""
                                        src="https://files.askiitians.com/static_content/Resources/images/deflt-paper.png"
                                        class="flt-left lazy" border="0">
                                        <p>
                                            Benefits of Askiitians Franchisee A well known...
                                        </p>
                                        <div class="clear">
                                        </div>
                                    </div>
                                </div>
                                <div class="witecont bdr bdr-wth">
                                    <a href="application-form.aspx">Application Form </a>
                                    <div class="box-dflt">
                                        <img data-original=""
                                        src="https://files.askiitians.com/static_content/Resources/images/deflt-paper.png"
                                        class="flt-left lazy" border="0">
                                        <p>
                                            Download the Application form for AskIITians...
                                        </p>
                                        <div class="clear">
                                        </div>
                                    </div>
                                </div>
                                <div class="witecont bdr bdr-wth">
                                    <a href="faqs.html">FAQs </a>
                                    <div class="box-dflt">
                                        <img data-original=""
                                        src="https://files.askiitians.com/static_content/Resources/images/deflt-paper.png"
                                        class="flt-left lazy" border="0">
                                        <p>
                                            Q. What would be the investment amount? A. The...
                                        </p>
                                        <div class="clear">
                                        </div>
                                    </div>
                                </div>
                                <div class="witecont bdr bdr-wth">
                                    <a href="financial-investment.html">Requirements – Technical and financial </a>
                                    <div class="box-dflt">
                                        <img data-original=""
                                        src="https://files.askiitians.com/static_content/Resources/images/deflt-paper.png"
                                        class="flt-left lazy" border="0">
                                        <p>
                                            Normal 0 false false false EN-US X-NONE X-NONE...
                                        </p>
                                        <div class="clear">
                                        </div>
                                    </div>
                                </div>
                                <div class="witecont bdr bdr-wth">
                                    <a href="process.aspx">Steps Forward </a>
                                    <div class="box-dflt">
                                        <img data-original="https://files.askiitians.com/cdn1/cms-content/common/www.askiitians.comimagesfranchisee-process.png.jpg"
                                        src="https://files.askiitians.com/static_content/Resources/images/deflt-paper.png"
                                        class="flt-left lazy" border="0">
                                        <p>
                                            AskIITians Franchisee-Process Process to be...
                                        </p>
                                        <div class="clear">
                                        </div>
                                    </div>
                                </div>
                                <div class="witecont bdr bdr-wth">
                                    <a href="fees-region-wise.html">Franchisee Fees - Region wise </a>
                                    <div class="box-dflt">
                                        <img data-original=""
                                        src="https://files.askiitians.com/static_content/Resources/images/deflt-paper.png"
                                        class="flt-left lazy" border="0">
                                        <p>
                                            Territories Indian Territory Franchise Fees Rs....
                                        </p>
                                        <div class="clear">
                                        </div>
                                    </div>
                                </div>
                                <div class="witecont bdr bdr-wth">
                                    <a href="master-franchisee.html">Master Franchisee </a>
                                    <div class="box-dflt">
                                        <img data-original="https://files.askiitians.com/cdn1/cms-content/common/www.askiitians.comimagesmaster-franchisee.jpg.jpg"
                                        src="https://files.askiitians.com/cdn1/cms-content/common/www.askiitians.comimagesmaster-franchisee.jpg.jpg"
                                        class="flt-left lazy" style="display: block;" border="0">
                                        <p>
                                            askIITians Master Franchisee askIITians is a...
                                        </p>
                                        <div class="clear">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>

        </div>
    </div>
</section>

<!-- <section class="header-fix">
    <div class="content-panel">
        <div class="container">
            <ul>
                <li itemscope="" itemtype="http://data-vocabulary.org/Breadcrumb"><a href="/" itemprop="url">
                        <span itemprop="title">Home</span></a> </li>
                <li>» </li>
                <li>
                    <span>Franchisee</span>
                </li>
            </ul>
            <div class="clear"></div>
        </div>
    </div>
    <div class="box-dflt">
        <div class="container-wrapper">
            <div class="container">
                <div id="content">
                    <pre>	
                            <h1>
							    <span style="color: #000000;">Franchisee Program for <strong>IIT JEE (Main/Advanced),
							        NEET (AIPMT) &amp; School Board (Class 6-10)</strong></span>
							</h1>
							                    
							<h2> <strong>Askiitians Franchisee : An Overview</strong></h2>
							    <br>
							                   
							    <p>
								        IIT JEE/ JEE Main /Advanced/ and many other state board engineering examination
								        is the prime choice for all the students after class 10<sup>th</sup>. AskIITians
								        gives you a chance to open up your own center with the edge of an ex-IITians brand.
								        AskIITians will provide all the Faculties, Study Material, DPP (Daily Practice Papers),
								        AITS (All India Test Series) and support for the students at your center
							    </p>
							    <p>
								        AskIITians is the most credible, pioneering and innovative company in the field
								        of online coaching to the students in India and in Middle East.
							    </p>
							    <p>
								        AskIITians franchisees are proud of themselves because of spirit of innovation here
								        at askIITians. We provide you the power of online coaching for some of the most
								        prestigious examinations in India regardless of your boundaries and location as
								        we can reach you as long as you have an internet connection at your center and we
							        provide you the most comprehensive and efficient marketing model for your center.</p>
							        
							        <p>
							            <strong>Askiitians Franchise: Start a “Smart Online Coaching Centre”</strong></p>
							    <p>
							        <strong>A Budget Coaching Concept – first time in India and abroad</strong></p>
							    <p>
							        <strong>Welcome to the recession proof concept</strong></p>
							    <p>
								        <strong>Industry: </strong>Engineering (IIT JEE / JEE Main /Advanced / All State
							        Level Engineering Entrance Exams)</p>
							    <p>
								        CBSE (9<sup>th</sup> / 10<sup>th</sup> / 11<sup>th</sup> / 12<sup>th</sup> / - for
							        Physics, Chemistry, Maths )</p>
							    <p>
							        <strong>Parent organization – TransWeb Educational&nbsp; Services Pvt. Ltd.</strong></p>
							    <p>
							        <strong>Area required – 600 to 800 square feet</strong></p>
							                                
							<div class="franchisee-txt">
								    <div class="head"><div class="ico"></div>AskIITians Franchisee :</div>
								    
								    <ul>
									              <li><a href="https://www.askiitians.com/franchisee/pune.html">AskIITians Center in Pune</a></li>
									              <li><a href="https://www.askiitians.com/franchisee/imphal.html">AskIITians Center in Imphal</a></li>
									              <li><a href="https://www.askiitians.com/franchisee/kolkata.aspx">  AskIITians Center in Kolkata </a></li>
									              <li><a href="https://www.askiitians.com/franchisee/ramgarh.html">AskIITians Center in Ramgarh</a></li>
									              <li><a href="https://www.askiitians.com/franchisee/hyderabad.aspx">AskIITians Center in Hyderabad</a></li>
									               <li><a href="https://www.askiitians.com/franchisee/hazaribagh.html">AskIITians Center in Hazaribagh</a></li>
								            </ul>
								            <div class="clear"></div>
							</div>
							<div class="contact-frm">
                                <div class="head">Quick Contact Form</div>
                                <div class="subhead">Leave us your details – our experts will get in touch with you</div>
                                <form [formGroup]="franchiseeForm" (ngSubmit)="onSubmit()" id="contactForm">
                                    <div class="row">
                                        <div class="frmfield">
                                            <input type="text" formControlName="name" placeholder="Name" maxlength="50" required>
                                        </div>
                                        <div class="frmfield">
                                            <input type="email" formControlName="email" placeholder="E-Mail" maxlength="50" required>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="frmfield">
                                            <input type="tel" formControlName="cell" placeholder="Cell-No." maxlength="10" pattern="\d{10}" required>
                                        </div>
                                        <div class="frmfield">
                                            <input type="tel" formControlName="landline" placeholder="LandLine Number" maxlength="11" pattern="\d{11}">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="frmfield">
                                            <textarea formControlName="address" placeholder="Address"></textarea>
                                        </div>
                                        <div class="frmfield">
                                            <textarea formControlName="query" placeholder="Query Text"></textarea>
                                        </div>
                                    </div>
                                    <button type="submit" class="submit-btn">Submit</button>
                                </form>
                            </div>
                            
                            
<div class="clear"></div>
<label id="lbl_msg" runat="server" text="" forecolor="Red" font-bold="true">
	<div class="medical-franchisee">
		<div class="ico"></div>
		To start a medical franchisee visit :
		<a href="http://www.emedicalprep.com/"><strong>www.emedicalprep.com</strong></a>
	</div>
</label>
      
      <br>
      <strong>Support services:</strong>
      <ul>
	          <li>Askiitians Brand Name</li>
	          <li>Centre design</li>
	          <li>Course Curriculum</li>
	          <li>Operational Support</li>
	          <li>Branding, Marketing and promotion</li>
	          <li>Technical assistance</li>
	          <li>Only Ex-IITians as teachers</li>
	          <li>Study Material, AITS, DPPs (Hard copy)</li>
      </ul>
      <br>
      <strong> Highlights:</strong>
      <ul>
	          <li>Participation and profit sharing in school Integration program</li>
	          <li>Managed by pioneer of online education in India and abroad ( Group of ex-iitians and foreign qualified visionary professionals)</li>
	          <li>Vast experience in online teaching industry</li>
      </ul>         
      
</pre>
                </div>
                <div id="ask-ques" style="padding: 0 2%">
                    <div class="box-dflt mrgin-top2">
                        <div class="head">
                            <span>Related</span> Resources
                        </div>
                        <div class="bdr-line">
                            <div class="bdr-line-haf">
                            </div>
                        </div>

                        <div class="grey-box bdr bdr-wth">

                            <div class="witecont bdr bdr-wth">
                                <a href="nrp-program.aspx">National Resource Partner Program </a>
                                <div class="box-dflt">

                                    <img data-original=""
                                        src="https://files.askiitians.com/static_content/Resources/images/deflt-paper.png"
                                        class="flt-left lazy" border="0">
                                    <p>
                                        National Resource Centre (NRC) Program About...
                                    </p>
                                    <div class="clear">
                                    </div>
                                </div>
                            </div>

                            <div class="witecont bdr bdr-wth">
                                <a href="benefits.html">Franchisee Benefits </a>
                                <div class="box-dflt">

                                    <img data-original=""
                                        src="https://files.askiitians.com/static_content/Resources/images/deflt-paper.png"
                                        class="flt-left lazy" border="0">
                                    <p>
                                        Benefits of Askiitians Franchisee A well known...
                                    </p>
                                    <div class="clear">
                                    </div>
                                </div>
                            </div>

                            <div class="witecont bdr bdr-wth">
                                <a href="application-form.aspx">Application Form </a>
                                <div class="box-dflt">

                                    <img data-original=""
                                        src="https://files.askiitians.com/static_content/Resources/images/deflt-paper.png"
                                        class="flt-left lazy" border="0">
                                    <p>
                                        Download the Application form for AskIITians...
                                    </p>
                                    <div class="clear">
                                    </div>
                                </div>
                            </div>

                            <div class="witecont bdr bdr-wth">
                                <a href="faqs.html">FAQs </a>
                                <div class="box-dflt">

                                    <img data-original=""
                                        src="https://files.askiitians.com/static_content/Resources/images/deflt-paper.png"
                                        class="flt-left lazy" border="0">
                                    <p>
                                        Q. What would be the investment amount? A. The...
                                    </p>
                                    <div class="clear">
                                    </div>
                                </div>
                            </div>

                            <div class="witecont bdr bdr-wth">
                                <a href="financial-investment.html">Requirements – Technical and financial </a>
                                <div class="box-dflt">

                                    <img data-original=""
                                        src="https://files.askiitians.com/static_content/Resources/images/deflt-paper.png"
                                        class="flt-left lazy" border="0">
                                    <p>
                                        Normal 0 false false false EN-US X-NONE X-NONE...
                                    </p>
                                    <div class="clear">
                                    </div>
                                </div>
                            </div>

                            <div class="witecont bdr bdr-wth">
                                <a href="process.aspx">Steps Forward </a>
                                <div class="box-dflt">

                                    <img data-original="https://files.askiitians.com/cdn1/cms-content/common/www.askiitians.comimagesfranchisee-process.png.jpg"
                                        src="https://files.askiitians.com/static_content/Resources/images/deflt-paper.png"
                                        class="flt-left lazy" border="0">
                                    <p>
                                        AskIITians Franchisee-Process Process to be...
                                    </p>
                                    <div class="clear">
                                    </div>
                                </div>
                            </div>

                            <div class="witecont bdr bdr-wth">
                                <a href="fees-region-wise.html">Franchisee Fees - Region wise </a>
                                <div class="box-dflt">

                                    <img data-original=""
                                        src="https://files.askiitians.com/static_content/Resources/images/deflt-paper.png"
                                        class="flt-left lazy" border="0">
                                    <p>
                                        Territories Indian Territory Franchise Fees Rs....
                                    </p>
                                    <div class="clear">
                                    </div>
                                </div>
                            </div>

                            <div class="witecont bdr bdr-wth">
                                <a href="master-franchisee.html">Master Franchisee </a>
                                <div class="box-dflt">

                                    <img data-original="https://files.askiitians.com/cdn1/cms-content/common/www.askiitians.comimagesmaster-franchisee.jpg.jpg"
                                        src="https://files.askiitians.com/cdn1/cms-content/common/www.askiitians.comimagesmaster-franchisee.jpg.jpg"
                                        class="flt-left lazy" style="display: block;" border="0">
                                    <p>
                                        askIITians Master Franchisee askIITians is a...
                                    </p>
                                    <div class="clear">
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="clear"></div>
</section> -->
<!-- <div style="display: none">
    <button id="Button1" runat="server" text="Quick Contact" onclick="btn_contactus_Click">
        <div id="inner-mid-left"></div>
        <div id="mid-right">
            <div id="dvMainSubCategory" runat="server" class="forum-headingmenutop"></div>
        </div>
    </button>
</div>

<script type="text/javascript">
    $(function () {
        var txtName = $('#<%=txt_Name.ClientID%>'),
            txtMail = $('#<%=txt_mail.ClientID%>'),
            txtCell = $('#<%=txt_cell.ClientID%>'),
            txtLno = $('#<%=txt_llno.ClientID%>'),
            txtQuery = $('#<%=txt_Query.ClientID%>'),
            btnSubmit = $('#<%=btn_Submit.ClientID%>');
        txtCell.on({
            keyup: function () {
                txtCell.val(txtCell.val().replace(/\D/g, ''));
            }
        });
        txtLno.on({
            keyup: function () {
                txtLno.val(txtLno.val().replace(/\D/g, ''));
            }
        });
        btnSubmit.on({
            click: function () {
                if (txtName.val().trim() === '') {
                    showModalPopup('Please enter name', function () {
                        txtName.focus();
                    });
                    return false;
                }
                if (txtName.val().trim().length <= 3) {
                    showModalPopup('Name must be greater than 3 characters', function () {
                        txtName.focus().select();
                    });
                    return false;
                }
                if (txtMail.val().trim() === '') {
                    showModalPopup('Please enter email id', function () {
                        txtMail.focus();
                    });
                    return false;
                }
                var emailPart = /^\w[\w\.\_\-]*\w\@\w[\w\-\.]*\w\.[a-zA-z]{2,3}$/g;
                var emailid = txtMail.val().trim();
                var matchArray = emailid.match(emailPart);
                if (matchArray == null) {
                    showModalPopup('Please enter a valid email id', function () {
                        txtMail.focus().select();
                    });
                    return false;
                }
                if (txtCell.val() === '') {
                    showModalPopup('Please enter cell number', function () {
                        txtCell.focus();
                    });
                    return false;
                }
                if (txtCell.val().length > 10) {
                    showModalPopup('Please enter valid cell number upto 10 digit', function () {
                        txtCell.focus().select();
                    });
                    return false;
                }
                if (txtLno.val() === '') {
                    showModalPopup('Please enter landline number', function () {
                        txtLno.focus();
                    });
                    return false;
                }
                if (txtLno.val().length > 10) {
                    showModalPopup('Please enter valid landline number upto 10 digit', function () {
                        txtLno.focus().select();
                    });
                    return false;
                }
                if (txtQuery.val() === '') {
                    showModalPopup('You must have a query', function () {
                        txtQuery.focus();
                    });
                    return false;
                }
            }
        });
    });
</script> -->