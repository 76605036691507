import { Component } from '@angular/core';

@Component({
  selector: 'app-site-health',
  templateUrl: './site-health.component.html',
  styleUrls: ['./site-health.component.css']
})
export class SiteHealthComponent {

}
