import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationType } from 'src/app/enum/NotificationType';
import { CmsService } from 'src/app/services/cms.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-school-tie-up-program',
  templateUrl: './school-tie-up-program.component.html',
  styleUrls: ['./school-tie-up-program.component.css']
})
export class SchoolTieUpProgramComponent implements OnInit {
  schoolTieUpProgramform!: FormGroup;

  constructor(private fb: FormBuilder,
    private cmsService: CmsService,
    private notificationService:NotificationService
  ) { }

  ngOnInit(): void {
    // Initialize the form with form controls and their validations
    this.schoolTieUpProgramform = this.fb.group({
      name: ['', Validators.required],
      designation: ['', Validators.required],
      schoolName: ['', Validators.required],
      contactNumber: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      address: ['', Validators.required],
      city: ['', Validators.required],
      country: ['', Validators.required],
      question: [''],
      courseDetails: [''],
      referralSource: ['']
    });
  }

  // Optional: A getter for easy access to form controls in the template
  get form() {
    return this.schoolTieUpProgramform.controls;
  }

  // Method to handle form submission
  onSubmit(): void {
    const schoolTieUpProgramData = {
      InType: 2,  // Default value from C# model
      FirstName: this.schoolTieUpProgramform.value.name,  // Mapping 'name' to 'FirstName'
      Designation: this.schoolTieUpProgramform.value.designation,
      SchoolName: this.schoolTieUpProgramform.value.schoolName,
      Mobile: this.schoolTieUpProgramform.value.contactNumber,  // Mapping 'contactNumber' to 'Mobile'
      EmailId: this.schoolTieUpProgramform.value.email,  // Mapping 'email' to 'EmailId'
      Address: this.schoolTieUpProgramform.value.address,
      City: this.schoolTieUpProgramform.value.city,
      Country: this.schoolTieUpProgramform.value.country,
      Question: this.schoolTieUpProgramform.value.question,
      CourseDetails: this.schoolTieUpProgramform.value.courseDetails,
      Referred: this.schoolTieUpProgramform.value.referralSource,  // Mapping 'referralSource' to 'Referred'
      SchoolTieUpId: this.schoolTieUpProgramform.value.schoolTieUpId
    };
    this.cmsService.schoolTieUp(schoolTieUpProgramData).subscribe((message: any) => {
      if(message)
      {
        this.notificationService.notifyMessage(NotificationType.Success, `Thank you!.`);
      }
     else
     {
      this.notificationService.notifyMessage(NotificationType.Warning, `Something went wrong!.`);
     }
    });

  }
}