import { Component, ElementRef, Renderer2, forwardRef, EventEmitter, Output, Input } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CountryCodeModel } from '../Models/CountryCode.Model';

@Component({
  selector: 'app-country-picker',
  templateUrl: './country-picker.component.html',
  styleUrls: ['./country-picker.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CountryPickerComponent),
    multi: true,
  },
    // {
    //   provide: NG_VALIDATORS,
    //   useValue: phoneNumberValidator,
    //   multi: true,
    // },
  ]
})
export class CountryPickerComponent implements ControlValueAccessor {
  @Output() sendDataEvent = new EventEmitter<boolean>();
  @Input() PhoneNumberwithCountryCode!: '';

  isDropdownVisible = false;
  countryCodes: any[] = CountryCodeModel;
  formControl!: FormGroup;

  selectedCountry: any = this.countryCodes.find((country: any) => country.code === '+91');
  selectedCountryCode: string = '';
  searchText: string = '';

  onTouched = () => { };

  constructor(private elementRef: ElementRef,
    public formBuilder: FormBuilder,
    private renderer: Renderer2) { }

  ngOnInit() {
    this.renderer.listen('body', 'click', (event) => {
      this.closeDropdownOnClickOutside(event);
    });
    
    this.formControl = this.formBuilder.group(
      {
        phoneNumber: [''],
      }
    );
  }
  ngOnDestroy() {
    this.renderer.destroy();
  }

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    } return true;
  }

  onSelect(country: any): void {
    this.selectedCountry = country;
    this.selectedCountryCode = country.code;
    this.formControl.reset({
      phoneNumber: '',
    });
  }

  getFilteredCountryCodes(): any[] {
    const searchTextLower = this.searchText.toLowerCase();
    return this.countryCodes.filter(country =>
      country.country.toLowerCase().includes(searchTextLower) || country.code.includes(searchTextLower)
    );
  }
  writeValue(value: any) {
    if(this.PhoneNumberwithCountryCode !=undefined)
    {
      this.selectedCountry.code =this.PhoneNumberwithCountryCode.split('-')[0];
      this.selectedCountry = this.countryCodes.find((country: any) => country.code === this.selectedCountry.code)
      this.formControl.controls['phoneNumber'].setValue(this.PhoneNumberwithCountryCode.split('-')[1]);
    }
    else
    {
      this.formControl.controls['phoneNumber'].setValue(value);
    }
  }

  registerOnChange(fn: Function) {
    this.formControl.controls['phoneNumber'].valueChanges.subscribe((val) => fn(this.selectedCountry.code + '-' + val));
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }
  closeDropdownOnClickOutside(event: Event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isDropdownVisible = false;
    }
  }
}
