import { Injectable } from '@angular/core';
import { ApiCallService } from './api-call.service';
import { Observable } from 'rxjs';
import { endPoints } from '../api-end-points';
import { CMSModel } from '../Models/CMSModel';
import { Registration } from '../Models/RegistrationModel';

@Injectable({
  providedIn: 'root'
})
export class CmsService {

  constructor(private apiService: ApiCallService) { }

  getPost(sourceURL: string): Observable<CMSModel> {

    let source = encodeURIComponent(`${sourceURL}`)
    var apiEndPoint = endPoints.GET_CMS_POST + source;

    return this.apiService.Get(apiEndPoint);
  }
  getCity(): Observable<any> {
    var apiEndPoint = endPoints.GET_CITY;
    return this.apiService.Get(apiEndPoint);
  }

  postCareers(careersData: any): Observable<any> {
    var apiEndPoint = endPoints.CAREERS;
    // return this.apiService.Get(apiEndPoint);
    return this.apiService.Post(apiEndPoint, JSON.stringify(careersData),null,'text');
  }
  schoolTieUp(schoolTieUpData: any): Observable<any> {
    var apiEndPoint = endPoints.SCHOOL_TIE_UP_PROGRAM;
    // return this.apiService.Get(apiEndPoint);
    return this.apiService.Post(apiEndPoint, JSON.stringify(schoolTieUpData),null,'text');
  }
  postFranchisee(franchiseeData: any): Observable<any> {
    var apiEndPoint = endPoints.FRANCHISEE;
    // return this.apiService.Get(apiEndPoint);
    return this.apiService.Post(apiEndPoint, JSON.stringify(franchiseeData),null,'text');
  }
  postTabLearningProgram(post: Registration): Observable<any> {
    return this.apiService.Post(endPoints.ADD_USER, JSON.stringify(post));
  }
}
