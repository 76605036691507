<section class="careerWrap">
    <div class="container">
        <div class="row">

            <div class="col-md-7 innerCareerBlock">
                <div class="careerContentWrapper">
                    <h1 class="pageSecHeading">Join the Askiitians Team - Be a Part of Our Journey</h1>

                    <strong class="leftPanlStrong"><i class="fa fa-check-circle" aria-hidden="true"></i> About Us</strong>
                    <p>At Askiitians, we are passionate about empowering students with the knowledge and skills they need to excel academically. As pioneers in online education, we provide top-notch tutoring services and learning resources to students around the globe. We prepare students for competitive exams like IIT JEE, NEET, IB, and CBSE board exams, consistently producing top results and Middle East JEE toppers over the past few years. Our mission is to make high-quality education accessible to everyone, everywhere.</p>

                    <strong class="leftPanlStrong"><i class="fa fa-check-circle" aria-hidden="true"></i> Why Work with Us</strong>
                    <ul class="careerLisitngStyle">
                        <li>Innovative Environment: Be part of a forward-thinking team that is at the forefront of educational technology.</li>
                        <li>Growth Opportunities: We believe in nurturing talent and providing ample opportunities for professional development and career progression.</li>
                        <li>Impact: Your work will make a real difference in the lives of students, helping them achieve their academic goals.</li>
                        <li>Collaborative Culture: Join a diverse and inclusive team that values collaboration, creativity, and mutual respect.</li>
                        <li>Flexibility: Enjoy the benefits of a flexible work environment with options for remote working.</li>
                    </ul>

                    <strong class="leftPanlStrong"><i class="fa fa-check-circle" aria-hidden="true"></i> Our Values</strong>

                    <ul class="careerLisitngStyle">
                        <li>Excellence: We strive for the highest standards in everything we do.</li>
                        <li>Integrity: We are committed to honesty, transparency, and ethical practices.</li>
                        <li>Innovation: We embrace change and encourage innovative thinking.</li>
                        <li>Customer Focus: Our students are at the heart of everything we do.</li>
                        <li>Teamwork: We believe in the power of collaboration and teamwork.</li>
                    </ul>

                    <strong class="leftPanlStrong"><i class="fa fa-check-circle" aria-hidden="true"></i> How to Apply</strong>

                    <p>Interested candidates are invited to fill out the application form. Once you submit your details, our team will call you.</p>
                    
                    <strong class="leftPanlStrong"><i class="fa fa-check-circle" aria-hidden="true"></i> Life at Askiitians</strong>

                    <ul class="careerLisitngStyle">
                        <li>Professional Development: Access to workshops, training sessions, and conferences.</li>
                        <li>Work-Life Balance: Flexible working hours and a supportive work environment.</li>
                        <li>Health &amp; Wellness: Comprehensive health benefits and wellness programs.</li>
                        <li>Community: Participate in team-building activities, social events, and community outreach programs.</li>
                    </ul>

                    <strong class="leftPanlStrong"><i class="fa fa-check-circle" aria-hidden="true"></i> Get in Touch</strong>
                    <p>If you have any questions about our open positions or the application process, please feel free to contact us at <a href="mailto:info@askiitians.com">info@askiitians.com</a></p>                            
                    </div>
            </div>

            <div class="col-md-5">
                <div class="Careerform singleForm">
                    <div class="justify-content-center">
                        <div class="user-show-head">Join Ed-Tech Revolution</div>
                    </div>

                    <form [formGroup]="personalDetailsForm" (ngSubmit)="onSubmit()">
                        <div id="txtSearchProdAssign">
                            <div class="form-row">
                                <div class="form-group col-md-12">
                                    <label for="name-user" class="label-space">Name <span class="required">*</span></label>
                                    <input type="text" id="name-user" placeholder="Type Name Here..." required
                                        class="form-control input-text" name="name" formControlName="name" />
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="form-group col-md-12">
                                    <label for="current-address" class="label-space">Current Address <span
                                            class="required">*</span></label>
                                    <input type="text" id="current-address" placeholder="Type Address Here..." required
                                        class="form-control input-text" name="currentAddress" formControlName="currentAddress" />
                                </div>
                            </div>
                
                            <div class="form-row">
                                <div class="form-group col">
                                    <label for="current-city" class="label-space">Current City <span class="required">*</span></label>
                                    <select id="current-city" class="form-control input-text" name="currentCity"
                                        formControlName="currentCity" required>
                                        <option value="">--City--</option>
                                        <option *ngFor="let city of cities" [value]="city | json">
                                          {{ city }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="form-row countryCodde">
                                <div class="form-group col">
                                    <label for="contact-number" class="label-space">Contact Number <span class="required">*</span></label>
                                    <app-country-picker name="PhoneNo" formControlName="PhoneNo"></app-country-picker>
                                </div>
                            </div>
                
                            <div class="form-row EmalFld">
                                <div class="form-group col">
                                    <label for="email" class="label-space">E-mail <span class="required">*</span></label>
                                    <input type="email" id="email" placeholder="Type E-mail Here..." required
                                        class="form-control input-text" name="email" formControlName="email" />
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="form-group col">
                                    <label for="qualification" class="label-space">Qualification <span class="required">*</span></label>
                                    <input type="text" id="qualification" placeholder="Type Qualification Here..." required
                                        class="form-control input-text" name="qualification" formControlName="qualification" />
                                </div>
                            </div>
                
                            <div class="form-row">
                                <div class="form-group col">
                                    <label for="college" class="label-space">College <span class="required">*</span></label>
                                    <input type="text" id="college" placeholder="Type College Here..." required
                                        class="form-control input-text" name="college" formControlName="college" />
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="form-group col">
                                    <label class="label-space">Category <span class="required">*</span></label>
                                    <div class="d-flex">
                                        <div class="form-check form-check-inline">
                                            <input type="radio" id="category-student" class="form-check-input" name="category"
                                                formControlName="category" value="Student" />
                                            <label for="category-student" class="form-check-label">Student</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input type="radio" id="category-teacher" class="form-check-input" name="category"
                                                formControlName="category" value="Teacher" />
                                            <label for="category-teacher" class="form-check-label">Teacher</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input type="radio" id="category-other" class="form-check-input" name="category"
                                                formControlName="category" value="Other" />
                                            <label for="category-other" class="form-check-label">Other</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br>
                            <div class="form-row">
                                <div class="form-group col">
                                    <label for="current-ctc" class="label-space">Current CTC <span class="required">*</span></label>
                                    <input type="number" id="current-ctc" placeholder="Type Current CTC Here..." required
                                        class="form-control input-text" name="currentCTC" formControlName="currentCTC" />
                                </div>
                                <div class="form-group col">
                                    <label for="expected-ctc" class="label-space">Expected CTC <span class="required">*</span></label>
                                    <input type="number" id="expected-ctc" placeholder="Type Expected CTC Here..." required
                                        class="form-control input-text" name="expectedCTC" formControlName="expectedCTC" />
                                </div>
                            </div>
                
                            <div class="form-row">
                                <div class="form-group col">
                                    <label for="past-experience" class="label-space">Past Experience (if Any)</label>
                                    <input type="text" id="past-experience" placeholder="Type Past Experience Here..."
                                        class="form-control input-text" name="pastExperience" formControlName="pastExperience" />
                                </div>
                            </div>
                
                            <button type="submit" class="btn btn-applyCareer">Submit</button>
                        </div>
                    </form>
                </div>
            </div>

        </div>

    </div>
</section>

