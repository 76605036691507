import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  tabId: string | undefined;

  constructor(@Inject(PLATFORM_ID) private platformId: any) { }

  setItem(key: string, value: any, type: string): void {
    if (isPlatformBrowser(this.platformId)) {
      if (type == 'local') localStorage.setItem(key, value);
      else sessionStorage.setItem(key, value);
    }
  }

  getItem(key: string, type: string): any {
    if (isPlatformBrowser(this.platformId)) {
      if (type == 'local') return localStorage.getItem(key);
      return sessionStorage.getItem(key);
    }
    return null;
  }

  remove(key: string, type: string): void {
    if (isPlatformBrowser(this.platformId)) {
      if (type == 'local')
        localStorage.removeItem(key);
      else sessionStorage.removeItem(key);
    }
  }

  clear(type: string): void {
    if (isPlatformBrowser(this.platformId)) {
      if (type == 'local') localStorage.clear();
      else sessionStorage.clear();
    }
  }
}
