import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CmsService } from 'src/app/services/cms.service';
import { cityList} from 'src/app/Models/ModelsList/CityList';
import { NotificationType } from 'src/app/enum/NotificationType';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-careersform',
  templateUrl: './careersform.component.html',
  styleUrls: ['./careersform.component.css']
})
export class CareersformComponent implements OnInit{
 
  personalDetailsForm!: FormGroup;
  cities: string[] = [];


  constructor(private fb: FormBuilder,
    private cmsService: CmsService,
    private notificationService:NotificationService
  ) { }

  ngOnInit() {
    this.personalDetailsForm = this.fb.group({
      name: ['', [Validators.required]],
      currentAddress: ['', [Validators.required]],
      currentCity: ['', [Validators.required]],
      PhoneNo: ['', [Validators.required, Validators.pattern('^[0-9]{10}$')]],
      email: ['', [Validators.required, Validators.email]],
      qualification: ['', [Validators.required]],
      college: ['', [Validators.required]],
      category: ['', [Validators.required]],
      currentCTC: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      expectedCTC: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      pastExperience: ['']
    });
    this.cities = cityList;
    //this.getCity();
  }

  onSubmit() {
     
    const formData = {
      Name: this.personalDetailsForm.get('name')?.value,
      Address1: this.personalDetailsForm.get('currentAddress')?.value,
      Address2: this.personalDetailsForm.get('currentAddress')?.value,
      City: this.personalDetailsForm.get('currentCity')?.value,
      PhoneNumber: this.personalDetailsForm.get('PhoneNo')?.value,
      Email: this.personalDetailsForm.get('email')?.value,
      qualification: this.personalDetailsForm.get('qualification')?.value,
      College: this.personalDetailsForm.get('college')?.value,
      Category: this.personalDetailsForm.get('category')?.value,
      currentCTC: this.personalDetailsForm.get('currentCTC')?.value,
      ExpectedCTC: this.personalDetailsForm.get('expectedCTC')?.value,
      PastExperience: this.personalDetailsForm.get('pastExperience')?.value
    };

    // if (this.personalDetailsForm.valid) {
      this.cmsService.postCareers(formData).subscribe((message: any) => {
         
        if(message=="Mail Successfully Send")
        {
        this.notificationService.notifyMessage(NotificationType.Success, `Thank you!.`);
        this.personalDetailsForm.reset({
          name: '',
          currentAddress: '',
          currentCity: '',
          PhoneNo: '',
          email: '',
          qualification: '',
          college: '',
          category: '',
          currentCTC: '',
          expectedCTC: '',
          pastExperience: ''  // Set any non-required fields to an empty string or default value
        });
        }
        else
        {
        this.notificationService.notifyMessage(NotificationType.Warning, `Something went wrong!.`);

        }
      });
      console.log('Form is not valid');
    // }
  }
  getCity()
  {this.cmsService.getCity().subscribe({
    next: (res) => {
       
     this.cities = res;
    },
    error: (error) => {
    },
  });
      //this.selectedCity = JSON.parse(this.city.nativeElement.value);
  }
}
