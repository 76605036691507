import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationType } from 'src/app/enum/NotificationType';

@Component({
  selector: 'app-noification-message',
  templateUrl: './noification-message.component.html',
  styleUrls: ['./noification-message.component.css'],
})
export class NoificationMessageComponent implements OnInit {
  @Input() notificationType!: string;
  @Input() Message!: string;
  @Input() isShow!: boolean;
  isSuccess = false;
  notificationClass = '';

  constructor(public activeModal: NgbActiveModal, @Inject(PLATFORM_ID) private platformId: any) { }

  ngOnInit() {
    if (this.notificationType == NotificationType.Success) {
      this.notificationClass = 'success';
    }
    if (this.notificationType == NotificationType.Fail) {
      this.notificationClass = 'fail';
    }
    if (this.notificationType == NotificationType.Warning) {
      this.notificationClass = 'warning';
    }
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        this.activeModal.close();
      }, 3000);
    }
  }

  closeModal() {
    this.activeModal.close();
  }
}
