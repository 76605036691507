<script src="https://js.instamojo.com/v1/button.js"></script>
<script src="https://files.askiitians.com/static_content/Resources/scripts/jquery.min1.js"></script>
<script src="https://files.askiitians.com/static_content/Resources/scripts/owl.carousel.min.js"
    type="text/javascript"></script>
<script src="https://files.askiitians.com/static_content/Resources/scripts/owl.carousel.js"
    type="text/javascript"></script>
<link href="https://files.askiitians.com/static_content/Resources/style/font-awesome.min.css" rel="stylesheet"
    type="text/css">
<link href="https://files.askiitians.com/static_content/Resources/style/owl.carousel.css" rel="stylesheet"
    type="text/css">
<link href="https://files.askiitians.com/static_content/Resources/style/owl.theme.default.min.css" rel="stylesheet"
    type="text/css">
<section class="tablet-learning">


    <section class="top_banner">
        <div class="container">
            <div class="row">
                <div class="col-md-5 col-sm-5">
                    <div class="banner_phone"><img
                            src="https://files.askiitians.com/static_content/Resources/images/group_24.png"
                            class="img-responsive">
                        <!-- Modal -->
                        <div class="modal-dialog modal-lg">
                            <div class="modal-content">
                                <div class="ask_popup_close">
                                    <button type="button" data-dismiss="modal">×</button>
                                </div>
                                <div class="modal-body">
                                    <!-- <iframe width="100%" height="450"
                                            src="https://www.youtube.com/embed/nDRPmRZu_M0" frameborder="0"
                                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                            allowfullscreen=""></iframe> -->
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-7 col-sm-7">
                <div class="top_banner_container">
                    <div class="top_banner_containt">
                        <h1 class="banner_title">Make efficient learning a habit</h1>
                        <h2 class="banner_subtitle">An Interactive way of learning with India's Best Teachers</h2>
                        <div class="top_banner_form">
                            <form [formGroup]="phonRegistrationForm" (ngSubmit)="onPhoneNoSubmit()">
                            <div class="enter_mobile_no">
                                <span class="country_code">+91</span>
                                <app-country-picker name="PhoneNo" formControlName="PhoneNo"></app-country-picker>
                                <button type="submit" class="register_button">REGISTER</button>
                            </div>
                            </form>
                        </div>
                    </div>
                    <div class="app_icons">
                        <a href="https://play.google.com/store/apps/details?id=com.askiitians.android.app&amp;hl=en">
                            <img src="https://files.askiitians.com/static_content/Resources/images/google_play.png"
                                class="app_icon" alt="Google Play">
                        </a>
                        <a href="#">
                            <img src="https://files.askiitians.com/static_content/Resources/images/comming_soon_app.png"
                                class="app_icon" alt="Coming Soon on App Store">
                        </a>
                    </div>
                </div>
            </div>

        </div>
    </section>
    <section class="better_than_others">
        <div class="container">
            <div class="learning_program_top_heading">
                <h2>Why we are better than others</h2>
            </div>
            <div id="better_than_others" class="owl-carousel owl-theme">
                <div>
                    <div class="item">
                        <div class="better_iitians_boxs better_box1">
                            <h2>Learn from <span>1137 Lecture’s</span> videos</h2>
                            <p>Get 310+ detailed subject wise Interactive Video Lessons</p>
                        </div>
                    </div>
                    <div class="item">
                        <div class="better_iitians_boxs better_box4">
                            <h2>It’s like giving the <span>Actual Exam</span></h2>
                            <p>A replica of real question paper to experience the real exam environment.</p>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="item">
                        <div class="better_iitians_boxs better_box2">
                            <h2>A bag full of <span>Topper’s secrets</span></h2>
                            <p>Containing Revision Notes, Mind Maps, Study Planner, NCERT Solutions and Past Papers</p>
                        </div>
                    </div>
                    <div class="item">
                        <div class="better_iitians_boxs better_box5">
                            <h2>Detailed <span>Test Analysis</span></h2>
                            <p>Get AIR and analyze your speed, accuracy with our detailed Report of your performance.
                            </p>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="item">
                        <div class="better_iitians_boxs better_box3">
                            <h2>Participate in <span>50+ Different Test Series</span></h2>
                            <p>Challenge yourself with Chapter test, Unit test and All India Level test series</p>
                        </div>
                    </div>
                    <div class="item">
                        <div class="better_iitians_boxs better_box6">
                            <h2><span>Question &amp; Answer<span></span></span></h2>
                            <p>A platform where students can help each other to solve doubts.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="how_we_solve">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="learning_program_top_heading">
                        <h2>How we solve your problems</h2>
                        <h3>Study Kit</h3>
                    </div>
                    <div id="How_we_solve" class="owl-carousel owl-theme how_we_solve_text">
                        <div class="item">
                            <div class="row">
                                <div class="col-md-1 col-sm-1"></div>
                                <div class="col-md-3 col-sm-3">
                                    <div class="responsive_video">
                                        <!-- <iframe width="100%" height="300"
                                            src="https://www.youtube.com/embed/nDRPmRZu_M0" frameborder="0"
                                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                            allowfullscreen=""></iframe> -->
                                    </div>
                                    <div class="solve_problems">
                                        <span><img
                                                src="https://files.askiitians.com/static_content/Resources/images/video.gif"
                                                class="img-responsive"></span>
                                        <img src="https://files.askiitians.com/static_content/Resources/images/tablet.png"
                                            class="img-responsive">
                                    </div>

                                </div>
                                <div class="col-md-7 col-sm-7">
                                    <h2>Videos</h2>
                                    <ul>
                                        <li>Lectures by IITIANS/AIIMS passed out.</li>
                                        <li>Understand every concept with Visual Interaction </li>
                                        <li>Freedom to learn at my own pace</li>
                                        <li>Study anytime and anywhere.</li>
                                    </ul>
                                </div>
                                <div class="col-md-1 col-sm-1"></div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="row">
                                <div class="col-md-1 col-sm-1"></div>
                                <div class="col-md-3 col-sm-3">
                                    <div class="responsive_video">
                                        <!-- <iframe width="100%" height="300"
                                            src="https://www.youtube.com/embed/nDRPmRZu_M0" frameborder="0"
                                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                            allowfullscreen=""></iframe> -->
                                    </div>
                                    <div class="solve_problems">
                                        <span><img
                                                src="https://files.askiitians.com/static_content/Resources/images/Study-kit.gif"
                                                class="img-responsive"></span>
                                        <img src="https://files.askiitians.com/static_content/Resources/images/tablet.png"
                                            class="img-responsive">
                                    </div>

                                </div>
                                <div class="col-md-7 col-sm-7">
                                    <h2>Study kit</h2>
                                    <ul>
                                        <li><span>Mind Maps</span> - A graphical way to revise the whole chapter than
                                            video quickly.</li>
                                        <li><span>Revision Notes</span> - Now, focus easily on attending lectures.
                                            SInce, we are providing you writtten notes.</li>
                                        <li><span>NCERT Solutions</span> - Step by step explanation of questions in
                                            ncert book to clear the doubts and understand the concepts.</li>
                                        <li><span>Study Planner</span> - A compelete strategy to cover every chapter in
                                            a smart way which minizmises your effort and investing crucial time in right
                                            direction.</li>
                                        <li><span>Past Year Papers</span> - A compelete strategy to cover every chapter
                                            in a smart way which minizmises your effort and investing crucial time in
                                            right direction.</li>
                                    </ul>
                                </div>
                                <div class="col-md-1 col-sm-1"></div>
                            </div>
                        </div>

                        <div class="item">
                            <div class="row">
                                <div class="col-md-1 col-sm-1"></div>
                                <div class="col-md-3 col-sm-3">
                                    <div class="responsive_video">
                                        <!-- <iframe width="100%" height="300"
                                            src="https://www.youtube.com/embed/nDRPmRZu_M0" frameborder="0"
                                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                            allowfullscreen=""></iframe> -->
                                    </div>
                                    <div class="solve_problems">
                                        <span><img
                                                src="https://files.askiitians.com/static_content/Resources/images/Test-Analysis.gif"
                                                class="img-responsive"></span>
                                        <img src="https://files.askiitians.com/static_content/Resources/images/tablet.png"
                                            class="img-responsive">
                                    </div>
                                </div>
                                <div class="col-md-7 col-sm-7">
                                    <h2>Detailed Test Analysis</h2>
                                    <ul>
                                        <li>Generate your AIR and PERCENTILE with respect to a topper.</li>
                                        <li>Efficient feedback of your weak and strong topics. </li>
                                        <li>A chance to analyze yourself through different parameters like Accuracy,
                                            speed and more</li>
                                        <li>Get step by step explanation of every question so that you can refer them to
                                            clear your concept in future</li>
                                    </ul>
                                </div>
                                <div class="col-md-1 col-sm-1"></div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="row">
                                <div class="col-md-1 col-sm-1"></div>
                                <div class="col-md-3 col-sm-3">
                                    <div class="responsive_video">
                                        <!-- <iframe width="100%" height="300"
                                            src="https://www.youtube.com/embed/nDRPmRZu_M0" frameborder="0"
                                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                            allowfullscreen=""></iframe> -->
                                    </div>
                                    <div class="solve_problems">
                                        <span><img
                                                src="https://files.askiitians.com/static_content/Resources/images/question_and_answer.gif"
                                                class="img-responsive"></span>
                                        <img src="https://files.askiitians.com/static_content/Resources/images/tablet.png"
                                            class="img-responsive">
                                    </div>
                                </div>
                                <div class="col-md-7 col-sm-7">
                                    <h2>Question and Answer</h2>
                                    <ul>
                                        <li>Freedom to raise the doubt anytime you want.</li>
                                        <li>Opportunity to view different approach to solve a question.</li>
                                        <li>Gain knowledge by reading other answers.</li>
                                        <li>High Quality and detailed explanation.</li>
                                    </ul>
                                </div>
                                <div class="col-md-1 col-sm-1"></div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="row">
                                <div class="col-md-1 col-sm-1"></div>
                                <div class="col-md-3 col-sm-3">
                                    <div class="responsive_video">
                                        <!-- <iframe width="100%" height="300"
                                            src="https://www.youtube.com/embed/nDRPmRZu_M0" frameborder="0"
                                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                            allowfullscreen=""></iframe> -->
                                    </div>
                                    <div class="solve_problems">
                                        <span><img
                                                src="https://files.askiitians.com/static_content/Resources/images/Test-Series.gif"
                                                class="img-responsive"></span>
                                        <img src="https://files.askiitians.com/static_content/Resources/images/tablet.png"
                                            class="img-responsive">
                                    </div>
                                </div>
                                <div class="col-md-7 col-sm-7">
                                    <h2>Test Series</h2>
                                    <p>Experience the actual test interface to get rid of exam's fear and nervous,
                                        Opportunity to check your potential at All India level even before the actual
                                        exam. </p>
                                    <ul>
                                        <li><span>Chapter test</span> - Focus on preparing only one chapter test at a
                                            time so that you have enough time to build your concepts.</li>
                                        <li><span>Unit test</span> - Practice! Practice! Practice! Till you identify the
                                            best approach with our monthly exam. </li>
                                        <li><span>Half Yearly Test</span> - Refresh the concept and revise chapters
                                            covered in every 6 months.</li>
                                        <li><span>Full Syllabus Test</span> - Now! you're ready to check your
                                            preparedness by giving JEE equivalent.</li>
                                    </ul>
                                </div>
                                <div class="col-md-1 col-sm-1"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="ab_teacher">
        <div class="container">
            <div class="learning_program_top_heading">
                <h2>About Our Teachers</h2>
            </div>
            <div id="ab_teacher" class="owl-carousel owl-theme">
                <div>
                    <div class="item">
                        <div class="ab_teacher_box">
                            <a href="https://www.askiitians.com/academic-experts-profile/faculty-ravleen-kaur/">
                                <span><img
                                        src="https://files.askiitians.com/static_content/Resources/images/ravleen_kaur.jpg"></span>
                                <p class="tch_name">Ravleen kaur</p>
                                <p>She has done her MSc, IIT Patna. Ravleen has a great art of connecting life bonds
                                    with the chemical bond and this art of her makes her connections with the student
                                    strong and has worked out pretty well in terms of students success.</p>
                            </a>
                        </div>
                    </div>
                    <div class="item">
                        <div class="ab_teacher_box">
                            <a href="https://www.askiitians.com/academic-experts-profile/radeef/"> <span><img
                                        src="https://files.askiitians.com/static_content/Resources/images/radeef_ulde.jpg"></span>
                                <p class="tch_name">Dr. Radeef</p>
                                <p>He has done his medical graduation from the Maharashtra University of Health Science,
                                    Nashik and also a post-graduate Master in Business Administration from ITM,
                                    California.</p>
                            </a>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="item">
                        <div class="ab_teacher_box">
                            <a href="https://www.askiitians.com/academic-experts-profile/faculty-rahul-rohilla/">
                                <span><img
                                        src="https://files.askiitians.com/static_content/Resources/images/rahul_rohilla.jpg"></span>
                                <p class="tch_name">Rahul Rohilla</p>
                                <p>She has Graduated with engineering degree from IIT Delhi. He is having a rich 5 years
                                    of experience in coaching IIT JEE aspirants and has been a part of Askiitians
                                    community for a long time.</p>
                            </a>
                        </div>
                    </div>
                    <div class="item">
                        <div class="ab_teacher_box">
                            <a href="https://www.askiitians.com/academic-experts-profile/faculty-abhilash-sharma/">
                                <span><img
                                        src="https://files.askiitians.com/static_content/Resources/images/abhislash_sharma.jpg"></span>
                                <p class="tch_name">Abhilash Sharma</p>
                                <p>He has Graduated from IIT Madras in Electrical Engineering, he was very clear from
                                    day one that he has to change the way kids learn physics. He is popularly known as
                                    “Physics Geek” among the students.</p>
                            </a>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="item">
                        <div class="ab_teacher_box">
                            <a href="https://www.askiitians.com/academic-experts-profile/faculty-anjali-ahuja.html">
                                <span><img
                                        src="https://files.askiitians.com/static_content/Resources/images/anjali_ahuja.jpg"></span>
                                <p class="tch_name">Anjali Ahuja</p>
                                <p>She has done her M.Sc. in Biochemistry and B.Ed. from Kurukshetra University,
                                    Haryana. She is interested in doing research to figure out how microorganisms and
                                    functional foods are helping towards reinventing the food-biotech industry.</p>
                            </a>
                        </div>
                    </div>
                    <div class="item">
                        <div class="ab_teacher_box">
                            <a href="https://www.askiitians.com/academic-experts-profile/faculty-harsh-patodia/">
                                <span><img
                                        src="https://files.askiitians.com/static_content/Resources/images/harsh_patodia.jpg"></span>
                                <p class="tch_name">Harsh Patodia</p>
                                <p>Classes, Resonance etc. of KOTA for preparing JEE aspirates for fulfilling their IIT
                                    dreams. He is having a rich 5 years of experience in coaching IIT JEE aspirants.</p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="view_more_btn"><a href="../academic-experts-profile.aspx">View More</a></div>
            </div>

        </div>
    </section>
    <section class="play_store">
        <div class="container">
            <div class="learning_program_top_heading">
                <h2>What users have to say about us</h2>
                <h3>From Google Play Store</h3>
            </div>
            <div id="play_store" class="owl-carousel owl-theme">
                <div class="item">
                    <div class="play_store_box">
                        <p>Its an amazing app, Very good for iit jee and neet.. the study kit is good and the lectures
                            are also fantastic.. must have app for every student aspiring for jee or neet.....</p>
                        <span><img
                                src="https://files.askiitians.com/static_content/Resources/images/sarthak_narang.jpg"></span>
                        <a href="#">Sarthak Narang</a>
                    </div>
                </div>
                <div class="item">
                    <div class="play_store_box">
                        <p>Best app for JEE preparation 2019 online format.thankyou askiitian team and well wishes for
                            your further upcoming projects. 1 suggestions pls fix some bugs in ans key to all India test
                            series and their solutions.</p>
                        <span><img
                                src="https://files.askiitians.com/static_content/Resources/images/aditya_kanoi.jpg"></span>
                        <a href="#">Aditya kanoi</a>
                    </div>
                </div>
                <div class="item">
                    <div class="play_store_box">
                        <p>Very useful and make me to learn more through video lectures and to study the smart way.</p>
                        <span><img
                                src="https://files.askiitians.com/static_content/Resources/images/a_google_user.jpg"></span>
                        <a href="#">A google User</a>
                    </div>
                </div>

            </div>
            <div class="col-md-12">
                <div class="view_more_btn"><a
                        href="https://play.google.com/store/apps/details?id=com.askiitians.android.app&amp;hl=en&amp;showAllReviews=true">View
                        More</a></div>
            </div>

        </div>
    </section>
    <section class="price_sec">
        <div class="container">
            <div class="learning_program_top_heading">
                <h2>Pricing</h2>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="">
                        <div class="row">
                            <div class="col-md-2"></div>
                            <div class="col-md-8">
                                <ul class="price_tab_btn">
                                    <li class="active"><a href="#profile" role="tab" data-toggle="tab">IIT JEE</a></li>
                                    <li><a href="#buzz" role="tab" data-toggle="tab">NEET</a></li>
                                </ul>
                                <div class="tab-content">
                                    <div role="tabpanel" class="tab-pane active" id="profile">
                                        <div class="row">
                                            <div class="col-md-6 col-sm-6">
                                                <div class="price_box">
                                                    <h2>askIITians Mobile</h2>
                                                    <h3>Rs 35,000<span>/2 years</span></h3>
                                                    <ul>
                                                        <li>Only Online Content
                                                            <ul>
                                                                <li>Videos</li>
                                                                <li>Study Kit</li>
                                                                <li>Chapter Test</li>
                                                                <li>AIR Test Series</li>
                                                                <li>Test Report</li>
                                                            </ul>
                                                        </li>
                                                        <li class="hide_price">Lenovo Tablet</li>
                                                        <li class="hide_price">64 Gigs of SD Card </li>
                                                    </ul>
                                                    <a href="https://www.instamojo.com/askiitians/askiitians-tablet/"
                                                        class="by_now_btn">Buy Now</a>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-sm-6">
                                                <div class="price_box price_box2">
                                                    <h2>askIITians Tablet</h2>
                                                    <h3>Rs 50,000<span>/2 years</span></h3>
                                                    <ul>
                                                        <li>Offline Content
                                                            <ul>
                                                                <li>Videos</li>
                                                                <li>Study Kit</li>
                                                                <li>Chapter Test</li>
                                                                <li>AIR Test Series (Internet Required)</li>
                                                                <li>Test Report</li>
                                                            </ul>
                                                        </li>
                                                        <li>Lenovo Tablet</li>
                                                        <li>64 Gigs of SD Card </li>
                                                    </ul>
                                                    <a href="https://www.instamojo.com/askiitians/askiitians-tablet/"
                                                        class="by_now_btn">Buy Now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div role="tabpanel" class="tab-pane" id="buzz">
                                        <div class="row">
                                            <div class="col-md-6 col-sm-6">
                                                <div class="price_box">
                                                    <h2>askIITians Mobile</h2>
                                                    <h3>Rs 30,000<span>/2 years</span></h3>
                                                    <ul>
                                                        <li>Only Online Content
                                                            <ul>
                                                                <li>Videos</li>
                                                                <li>Study Kit</li>
                                                                <li>Chapter Test</li>
                                                                <li>AIR Test Series</li>
                                                                <li>Test Report</li>
                                                            </ul>
                                                        </li>
                                                        <li class="hide_price">Lenovo Tablet</li>
                                                        <li class="hide_price">64 Gigs of SD Card </li>
                                                    </ul>
                                                    <a href="https://www.instamojo.com/askiitians/askiitians-tablet/"
                                                        class="by_now_btn">Buy Now</a>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-sm-6">
                                                <div class="price_box price_box2">
                                                    <h2>askIITians Tablet</h2>
                                                    <h3>Rs 35,000<span>/2 years</span></h3>
                                                    <ul>
                                                        <li>Offline Content
                                                            <ul>
                                                                <li>Videos</li>
                                                                <li>Study Kit</li>
                                                                <li>Chapter Test</li>
                                                                <li>AIR Test Series (Internet Required)</li>
                                                                <li>Test Report</li>
                                                            </ul>
                                                        </li>
                                                        <li>Lenovo Tablet</li>
                                                        <li>64 Gigs of SD Card </li>
                                                    </ul>
                                                    <a href="https://www.instamojo.com/askiitians/askiitians-tablet/"
                                                        class="by_now_btn">Buy Now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <a name="leadfrm" class="bkmrk"></a>
    <div class="lead-frm">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-xs-12">
                <div class="head">
                    Get <strong>FREE</strong> counselling<br>
                    session from
                    <p>Our Mentors.</p>
                </div>
            </div>
            <div class="form-container">
                <div class="form-content">
                    <h2 class="form-title">Get FREE counselling session from <strong>OUR MENTORS.</strong></h2>

                    <form [formGroup]="tabletForm" (ngSubmit)="onSubmit()" class="frm">
                        <div class="form-group">
                            <input type="text" class="form-control input-field" formControlName="name"
                                placeholder="Name" maxlength="50"
                                [ngClass]="{'is-invalid': name?.invalid && (name?.dirty || name?.touched)}" />
                            <div *ngIf="name?.invalid && (name?.dirty || name?.touched)">
                                <small *ngIf="name?.errors?.required" class="text-danger">Name is required</small>
                            </div>
                        </div>

                        <div class="form-group">
                            <input type="email" class="form-control input-field" formControlName="email"
                                placeholder="Email" required maxlength="50"
                                [ngClass]="{'is-invalid': email?.invalid && (email?.dirty || email?.touched)}" />
                            <div *ngIf="email?.invalid && (email?.dirty || email?.touched)">
                                <small *ngIf="email?.errors?.required" class="text-danger">Email is required</small>
                                <small *ngIf="email?.errors?.email" class="text-danger">Invalid Email</small>
                            </div>
                        </div>

                        <div class="form-group phone-group">
                            <app-country-picker name="PhoneNo" formControlName="PhoneNo"></app-country-picker>
                            <!-- <div class="country-code">
                                <a tabindex="0" id="hypCountryCode" class="hypCountryCode" href="javascript:void(0);">
                                    <div class="arrow-container">
                                        <div class="arrow"></div>
                                    </div>
                                    <span id="spnCountryName" class="spnCountryName">+91</span>
                                </a>
                            </div>
                            <input type="text" class="form-control input-field phone-input" formControlName="phone" placeholder="Phone"
                                maxlength="50"
                                [ngClass]="{'is-invalid': phone?.invalid && (phone?.dirty || phone?.touched)}" />
                            <div *ngIf="phone?.invalid && (phone?.dirty || phone?.touched)">
                                <small *ngIf="phone?.errors?.required" class="text-danger">Phone number is required</small>
                            </div> -->
                        </div>

                        <div class="form-group">
                            <button type="submit" class="btn submit-btn">SIGNUP FOR DEMO</button>
                        </div>
                    </form>
                </div>
            </div>

        </div>
    </div>
    <div class="clear"></div>
</section>

<script type="text/javascript">
    $(document).ready(function () {
        $("#hdnCountry").val("India");
    });
    var options = {
        txtNameTablet: $('#<%=txtNameTablet.ClientID %>'),
        txtEmailTablet: $('#<%=txtEmailTablet.ClientID %>'),
        txtMobileTablet: $('#<%=txtMobileTablet.ClientID %>'),
        BtnSaveTablet: $('#<%=BtnSaveTablet.ClientID %>')
    };
    $.Ask.TabletLearningProgram(options);
</script>
<script>
    $(document).ready(function () {
        $("#How_we_solve").owlCarousel({
            items: 1,
            nav: true,
            dots: true,
            loop: true,
            autoplay: true,
            autoplayTimeout: 400000,
            autoplayHoverPause: true,
            smartSpeed: 1000,
            autoHeight: true,
            navText: ["<i class='fa fa-angle-left' aria-hidden='true'></i>", "<i class='fa fa-angle-right' aria-hidden='true'></i>"]
        });
    });
</script>
<script>
    $(document).ready(function () {
        $("#better_than_others").owlCarousel({
            items: 3,
            nav: false,
            dots: false,
            loop: false,
            autoplay: false,
            autoplayTimeout: 400000,
            autoplayHoverPause: true,
            smartSpeed: 1000,
            autoHeight: true,
            responsive: {
                // breakpoint from 0 up
                0: {
                    items: 1,
                    dots: true,
                },
                // breakpoint from 480 up
                480: {
                    items: 2,
                    dots: true,
                },
                // breakpoint from 768 up
                768: {
                    items: 3,
                }
            }
        });
    });
</script>
<script>
    $(document).ready(function () {
        $("#ab_teacher").owlCarousel({
            items: 3,
            nav: false,
            dots: false,
            loop: false,
            autoplay: false,
            autoplayTimeout: 400000,
            autoplayHoverPause: true,
            smartSpeed: 1000,
            autoHeight: true,
            responsive: {
                // breakpoint from 0 up
                0: {
                    items: 1,
                    dots: true,
                },
                // breakpoint from 480 up
                480: {
                    items: 2,
                    dots: true,
                },
                // breakpoint from 768 up
                768: {
                    items: 3,
                }
            }
        });
    });
</script>
<script>
    $(document).ready(function () {
        $("#play_store").owlCarousel({
            items: 3,
            nav: false,
            dots: false,
            loop: false,
            autoplay: true,
            autoplayTimeout: 400000,
            autoplayHoverPause: true,
            smartSpeed: 1000,
            autoHeight: true,
            responsive: {
                // breakpoint from 0 up
                0: {
                    items: 1,
                    dots: true,
                },
                // breakpoint from 480 up
                480: {
                    items: 2,
                    dots: true,
                },
                // breakpoint from 768 up
                768: {
                    items: 3,
                }
            }
        });
    });
</script>