<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.4.1/dist/css/bootstrap.min.css">
		<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css" />
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/owl-carousel/1.3.3/owl.carousel.min.css" />
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/owl-carousel/1.3.3/owl.theme.css" />

<!-- Header Slider Section -->
<div class="head-slider-sec">
    <h2 class="hsc-txt">Proud to Present Our Toppers</h2>
    <div id="topbanner" class="owl-carousel owl-theme">
        <div class="item">
            <img class="hsc-owl-itm" src="https://files.askiitians.com/static_content/Resources/images/result-images/iit-slider-img.png" alt="banner image" />
            <img class="hsc-owl-itm2" src="https://files.askiitians.com/static_content/Resources/images/result-images/res-iit-slider-img.png" alt="banner image" />
        </div>
        <div class="item">
            <img class="hsc-owl-itm" src="https://files.askiitians.com/static_content/Resources/images/result-images/additionaljee.png" alt="banner image" />
            <img class="hsc-owl-itm2" src="https://files.askiitians.com/static_content/Resources/images/result-images/additionaljee_mb.png" alt="banner image" />
        </div>
        <div class="item">
            <img class="hsc-owl-itm" src="https://files.askiitians.com/static_content/Resources/images/result-images/NEET.png" alt="banner image" />
            <img class="hsc-owl-itm2" src="https://files.askiitians.com/static_content/Resources/images/result-images/res-neet.png" alt="banner image" />
        </div>
        <div class="item">
            <img class="hsc-owl-itm" src="https://files.askiitians.com/static_content/Resources/images/result-images/12th.png" alt="banner image" />
            <img class="hsc-owl-itm2" src="https://files.askiitians.com/static_content/Resources/images/result-images/res-12th.png" alt="banner image" />
        </div>
        <div class="item">
            <img class="hsc-owl-itm" src="https://files.askiitians.com/static_content/Resources/images/result-images/10th.png" alt="banner image" />
            <img class="hsc-owl-itm2" src="https://files.askiitians.com/static_content/Resources/images/result-images/res-10th.png" alt="banner image" />
        </div>
        <div class="item">
            <img class="hsc-owl-itm" src="https://files.askiitians.com/static_content/Resources/images/result-images/SAT.png" alt="banner image" />
            <img class="hsc-owl-itm2" src="https://files.askiitians.com/static_content/Resources/images/result-images/res-sat.png" alt="banner image" />
        </div>
        <div class="item">
            <img class="hsc-owl-itm" src="https://files.askiitians.com/static_content/Resources/images/result-images/slider-olympiad-img.png" alt="banner image" />
            <img class="hsc-owl-itm2" src="https://files.askiitians.com/static_content/Resources/images/result-images/res-slider-olympiad-img.png" alt="banner image" />
        </div>
        <div class="item">
            <img class="hsc-owl-itm" src="https://files.askiitians.com/static_content/Resources/images/result-images/top-ranker.png" alt="banner image" />
            <img class="hsc-owl-itm2" src="https://files.askiitians.com/static_content/Resources/images/result-images/res-top-ranker.png" alt="banner image" />
        </div>
    </div>
    <div class="container">
        <div class="hsc-carousel">
            <div id="topSlider" class="owl-carousel owl-theme">
                
            </div>
        </div>
    </div>
</div>
<!-- End Header Slider Section -->
<!-- Empowering Students  Section -->
<div class="empowering-sec">
    <div class="emp-head-txt">
        <h2>Empowering Students To Learn Better</h2>
    </div>
    <div class="container">
        <div class="emp-sec-container">
            <div class="emp-sec-wrapper">
                <div class="row">
                    <div class="col-xs-6 col-md-3">
                        <div class="cac-box2">
                            <div class="cb-num-sec">
                                <h3><span class="counter">40000</span>+</h3>
                            </div>
                            <div class="cp-icon-sec">
                                <img src="https://files.askiitians.com/static_content/Resources/images/result-images/hours-icon.svg" alt="icon" />
                            </div>
                            <div class="cp-txt-sec">
                                <p>Hours of Classes Conducted in 2020</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-6 col-md-3">
                        <div class="cac-box1">
                            <div class="cb-num-sec ">
                                <h3><span class="counter">10000</span>+</h3>
                            </div>
                            <div class="cp-icon-sec">
                                <img src="https://files.askiitians.com/static_content/Resources/images/result-images/ci-icon1.svg" alt="icon" />
                            </div>
                            <div class="cp-txt-sec">
                                <p>Students Selected in JEE from askIITians</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-6 col-md-3">
                        <div class="cac-box3">
                            <div class="cb-num-sec">
                                <h3><span class="counter">300</span>+</h3>
                            </div>
                            <div class="cp-icon-sec">
                                <img src="https://files.askiitians.com/static_content/Resources/images/result-images/ci-icon3.svg" alt="icon" />
                            </div>
                            <div class="cp-txt-sec">
                                <p>Over 300 Toppers in 2020</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-6 col-md-3">
                        <div class="cac-box4">
                            <div class="cb-num-sec">
                                <h3><span class="counter">10</span>+ Countries</h3>
                            </div>
                            <div class="cp-icon-sec">
                                <img src="https://files.askiitians.com/static_content/Resources/images/result-images/ci-icon4.svg" alt="icon" />
                            </div>
                            <div class="cp-txt-sec">
                                <p>10+ Countries Across 3 Continents </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>
<!-- End Empowering Students  Section -->
<!-- Our Result Section -->
<div class="our-result">
    <div class="container">
        <div class="our-result-wrapper mtm-50">
            <div class="orw-head-sec">
                <h2>Our IIT JEE Results</h2>
            </div>
            <div class="orw-stud-sec">
                <div class="row">
                    <div class="col-xs-6 col-md-2 pl-0 pr-0">
                        <div class="orw-stud-img">
                            <img src="https://files.askiitians.com/static_content/Resources/images/result-images/iit-stud1.png" alt="Students Image" />
                        </div>
                    </div>
                    <div class="col-xs-6 col-md-2 pl-0 pr-0">
                        <div class="orw-stud-img">
                            <img src="https://files.askiitians.com/static_content/Resources/images/result-images/iit-stud2.png" alt="Students Image" />
                        </div>
                    </div>
                    <div class="col-xs-6 col-md-2 pl-0 pr-0">
                        <div class="orw-stud-img">
                            <img src="https://files.askiitians.com/static_content/Resources/images/result-images/iit-stud3.png" alt="Students Image" />
                        </div>
                    </div>
                    
                    <div class="col-xs-6 col-md-2 pl-0 pr-0">
                        <div class="orw-stud-img">
                            <img src="https://files.askiitians.com/static_content/Resources/images/result-images/iit-stud7.png" alt="Students Image" />
                        </div>
                    </div>
                    <div class="col-xs-6 col-md-2 pl-0 pr-0">
                        <div class="orw-stud-img">
                            <img src="https://files.askiitians.com/static_content/Resources/images/result-images/iit-stud8.png" alt="Students Image" />
                        </div>
                    </div>
                    <div class="col-xs-6 col-md-2 pl-0 pr-0">
                        <div class="orw-stud-img">
                            <img src="https://files.askiitians.com/static_content/Resources/images/result-images/iit-stud9.png" alt="Students Image" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="our-res-txt-sec">
                <h2>1100+</h2>
                <p>askIITIans Students Selected For JEE Main Each Year </p>
            </div>
        </div>
        <div class="our-result-wrapper">
            <div class="orw-head-sec">
                <h2>Our NEET Results</h2>
            </div>
            <div class="orw-stud-sec">
                <div class="row">
                    <div class="col-xs-6 col-md-3 pl-0 pr-0">
                        <div class="orw-stud-img">
                            <img src="https://files.askiitians.com/static_content/Resources/images/result-images/neet-stud-1.png" alt="Students Image">
                        </div>
                    </div>
                    <div class="col-xs-6 col-md-3 pl-0 pr-0">
                        <div class="orw-stud-img">
                            <img src="https://files.askiitians.com/static_content/Resources/images/result-images/neet-stud-2.png" alt="Students Image">
                        </div>
                    </div>
                    <div class="col-xs-6 col-md-3 pl-0 pr-0">
                        <div class="orw-stud-img">
                            <img src="https://files.askiitians.com/static_content/Resources/images/result-images/neet-stud-3.png" alt="Students Image">
                        </div>
                    </div>
                    <div class="col-xs-6 col-md-3 pl-0 pr-0">
                        <div class="orw-stud-img">
                            <img src="https://files.askiitians.com/static_content/Resources/images/result-images/neet-stud-4.png" alt="Students Image">
                        </div>
                    </div>
                </div>
            </div>
            <div class="our-res-txt-sec">
                <h2>340+</h2>
                <p>askIITians Students Cracking NEET Since 2019</p>
            </div>
        </div>
        <div class="our-result-wrapper">
            <div class="orw-head-sec">
                <h2>Our  12th Grade Results</h2>
            </div>
            <div class="orw-stud-sec">
                <div class="row">
                    <div class="col-xs-6 col-md-2 pl-0 pr-0">
                        <div class="orw-stud-img">
                            <img src="https://files.askiitians.com/static_content/Resources/images/result-images/12th-stud-1.png" alt="Students Image">
                        </div>
                    </div>
                    <div class="col-xs-6 col-md-2 pl-0 pr-0">
                        <div class="orw-stud-img">
                            <img src="https://files.askiitians.com/static_content/Resources/images/result-images/12th-stud-2.png" alt="Students Image">
                        </div>
                    </div>
                    <div class="col-xs-6 col-md-2 pl-0 pr-0">
                        <div class="orw-stud-img">
                            <img src="https://files.askiitians.com/static_content/Resources/images/result-images/12th-stud-3.png" alt="Students Image">
                        </div>
                    </div>
                    <div class="col-xs-6 col-md-2 pl-0 pr-0">
                        <div class="orw-stud-img">
                            <img src="https://files.askiitians.com/static_content/Resources/images/result-images/12th-stud-4.png" alt="Students Image">
                        </div>
                    </div>
                    <div class="col-xs-6 col-md-2 pl-0 pr-0">
                        <div class="orw-stud-img">
                            <img src="https://files.askiitians.com/static_content/Resources/images/result-images/12th-stud-5.png" alt="Students Image">
                        </div>
                    </div>
                    <div class="col-xs-6 col-md-2 pl-0 pr-0">
                        <div class="orw-stud-img">
                            <img src="https://files.askiitians.com/static_content/Resources/images/result-images/12th-stud-6.png" alt="Students Image">
                        </div>
                    </div>
                </div>
            </div>
            <div class="our-res-txt-sec">
                <h2>100% Success</h2>
                <p>In 12th Exams with 50% Students Securing More Than 90%</p>
            </div>
        </div>
        <div class="our-result-wrapper">
            <div class="orw-head-sec">
                <h2>Our  10th Grade Results</h2>
            </div>
            <div class="orw-stud-sec">
                <div class="row">
                    <div class="col-xs-6 col-md-2 pl-0 pr-0">
                        <div class="orw-stud-img">
                            <img src="https://files.askiitians.com/static_content/Resources/images/result-images/10th-stud-7.png" alt="Students Image">
                        </div>
                    </div>
                    <div class="col-xs-6 col-md-2 pl-0 pr-0">
                        <div class="orw-stud-img">
                            <img src="https://files.askiitians.com/static_content/Resources/images/result-images/10th-stud-2.png" alt="Students Image">
                        </div>
                    </div>
                    <div class="col-xs-6 col-md-2 pl-0 pr-0">
                        <div class="orw-stud-img">
                            <img src="https://files.askiitians.com/static_content/Resources/images/result-images/10th-stud-4.png" alt="Students Image">
                        </div>
                    </div>
                    <div class="col-xs-6 col-md-2 pl-0 pr-0">
                        <div class="orw-stud-img">
                            <img src="https://files.askiitians.com/static_content/Resources/images/result-images/10th-stud-3.png" alt="Students Image">
                        </div>
                    </div>
                    <div class="col-xs-6 col-md-2 pl-0 pr-0">
                        <div class="orw-stud-img">
                            <img src="https://files.askiitians.com/static_content/Resources/images/result-images/10th-stud-5.png" alt="Students Image">
                        </div>
                    </div>
                    <div class="col-xs-6 col-md-2 pl-0 pr-0">
                        <div class="orw-stud-img">
                            <img src="https://files.askiitians.com/static_content/Resources/images/result-images/10th-stud-6.png" alt="Students Image">
                        </div>
                    </div>
                </div>
            </div>
            <div class="our-res-txt-sec">
                <h2>100% Success</h2>
                <p>In 10th Exams with 50% Students Securing More Than 90%</p>
            </div>
        </div>
        <div class="our-result-wrapper">
            <div class="orw-head-sec">
                <h2>Our  SAT Results</h2>
            </div>
            <div class="orw-stud-sec">
                <div class="row">
                    <div class="col-xs-6 col-md-2 pl-0 pr-0">
                        <div class="orw-stud-img">
                            <img src="https://files.askiitians.com/static_content/Resources/images/result-images/sat-stud-1.png" alt="Students Image">
                        </div>
                    </div>
                    <div class="col-xs-6 col-md-2 pl-0 pr-0">
                        <div class="orw-stud-img">
                            <img src="https://files.askiitians.com/static_content/Resources/images/result-images/sat-stud-2.png" alt="Students Image">
                        </div>
                    </div>
                    <div class="col-xs-6 col-md-2 pl-0 pr-0">
                        <div class="orw-stud-img">
                            <img src="https://files.askiitians.com/static_content/Resources/images/result-images/sat-stud-3.png" alt="Students Image">
                        </div>
                    </div>
                    <div class="col-xs-6 col-md-2 pl-0 pr-0">
                        <div class="orw-stud-img">
                            <img src="https://files.askiitians.com/static_content/Resources/images/result-images/sat-stud-4.png" alt="Students Image">
                        </div>
                    </div>
                    <div class="col-xs-6 col-md-2 pl-0 pr-0">
                        <div class="orw-stud-img">
                            <img src="https://files.askiitians.com/static_content/Resources/images/result-images/sat-stud-5.png" alt="Students Image">
                        </div>
                    </div>
                    <div class="col-xs-6 col-md-2 pl-0 pr-0">
                        <div class="orw-stud-img">
                            <img src="https://files.askiitians.com/static_content/Resources/images/result-images/sat-stud-6.png" alt="Students Image">
                        </div>
                    </div>
                </div>
            </div>
            <div class="our-res-txt-sec">
                <h2>500+</h2>
                <p>Over 500 Students with Perfect Score of 2400</p>
            </div>
        </div>
        <div class="our-result-wrapper">
            <div class="orw-head-sec">
                <h2>Our Olympiad Results(NSO & IMO)</h2>
            </div>
            <div class="orw-stud-sec">
                <div class="row">
                    <div class="col-xs-6 col-md-2 pl-0 pr-0">
                        <div class="orw-stud-img">
                            <img src="https://files.askiitians.com/static_content/Resources/images/result-images/olpd-stud-1.png" alt="Students Image">
                        </div>
                    </div>
                    <div class="col-xs-6 col-md-2 pl-0 pr-0">
                        <div class="orw-stud-img">
                            <img src="https://files.askiitians.com/static_content/Resources/images/result-images/olpd-stud-2.png" alt="Students Image">
                        </div>
                    </div>
                    
                    <div class="col-xs-6 col-md-2 pl-0 pr-0">
                        <div class="orw-stud-img">
                            <img src="https://files.askiitians.com/static_content/Resources/images/result-images/olpd-stud-4.png" alt="Students Image">
                        </div>
                    </div>
                    <div class="col-xs-6 col-md-2 pl-0 pr-0">
                        <div class="orw-stud-img">
                            <img src="https://files.askiitians.com/static_content/Resources/images/result-images/olpd-stud-5.png" alt="Students Image">
                        </div>
                    </div>
                    <div class="col-xs-6 col-md-2 pl-0 pr-0">
                        <div class="orw-stud-img">
                            <img src="https://files.askiitians.com/static_content/Resources/images/result-images/olpd-stud-6.png" alt="Students Image">
                        </div>
                    </div>
                    <div class="col-xs-6 col-md-2 pl-0 pr-0">
                        <div class="orw-stud-img">
                            <img src="https://files.askiitians.com/static_content/Resources/images/result-images/olpd-stud-3.png" alt="Students Image">
                        </div>
                    </div>
                </div>
            </div>
            <div class="our-res-txt-sec">
                <h2>300+</h2>
                <p>Olympiad rankers from askIITians</p>
            </div>
        </div>
    </div>
</div>
<!-- End Our Result Section -->
<!-- Testimonial Section -->
<section class="testimonial">
    <div class="testimonial-head-txt">
        <h2>What our Students have to Say</h2>
    </div>
    <div class="container">
        <div class="testimonial-content-sec">
            <div class="row">
                <div class="col-md-5">
                    <div class="test-video-sec">
                        <div class="row">
                            <div class="col-md-9">
                                <div class="test-vide-box">
                                    <a href="javascript:void()" data-toggle="modal" data-target="#studVideo"><img
                                    src="https://files.askiitians.com/static_content/Resources/images/result-images/video-testimonial.svg" alt="testimonial image" /></a>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="test-vert-line">
                                    <img src="https://files.askiitians.com/static_content/Resources/images/result-images/vertical-line.svg" alt="vertical line" />
                                </div>
                            </div>
                        </div>
                        <div class="student-testimonial">

                            <!-- The Modal -->
                            <div class="modal fade" id="studVideo">
                                <div class="modal-dialog modal-lg modal-dialog-centered">
                                    <div class="modal-content">
                                        <div class="modal-body">
                                            <button type="button" class="close"
                                                data-dismiss="modal">&times;</button>
                                            
                                            <iframe width="100%" height="380" src="https://www.youtube.com/embed/_C0nytPDBy4" frameborder="0" 
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>

                                            </iframe>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <div class="col-md-7">
                    <div class="test-text-sec">
                        <div id="big" class="owl-carousel owl-theme">
                            <div class="item">
                                <div class="test-content-sec">
                                    <p class="head-txt">Shashank Cheekala – Grade 12, Kuwait</p>
                                    <p class="testimonial-txt">The support from askIITians faculty has helped me improving my concepts and fundamentals in all 3 subjects through their
                                        sincere and passionate teaching. Online Facilities like tips & tricks have helped me not only plan better for different examinations but given me an
                                        opportunity to check my standards and preparations compared with people across the globe. Also, Online facilities like Batch Discussion forum and Test
                                    generator has helped me bridge my gap.</p>
                                </div>
                            </div>
                            <div class="item">
                                <div class="test-content-sec">
                                    <p class="head-txt">Kunal Kalani – Grade 10, India</p>
                                    <p class="testimonial-txt">It was not easy for me to clear Medical Exam without such an experienced coaching where I learnt the
                                        things in new style – Study Planner, Mind Map, Revision Notes, Live Classes and especially doubt removal classes that helped me to
                                    understand the concepts thoroughly.... Many thanks to all the experts of askIITians</p>
                                </div>
                            </div>
                            <div class="item">
                                <div class="test-content-sec">
                                    <p class="head-txt">Rishi Kabra - Grade 12, Malaysia</p>
                                    <p class="testimonial-txt">I’ve been studying in askIITians since Grade 10 and it has helped me a lot in academics. The teachers and their
                                        teaching style have greatly improved my academic performance. It is one of the reasons I’ve been able to achieve Maximum Score in SATs
                                    and other Examinations.</p>
                                </div>
                            </div>
                            
                        </div>
                        <div id="thumbs" class="owl-carousel owl-theme">
                            <div class="item">
                                <div class="test-content-sec">
                                    <a href="javascript:void()">
                                        <img src="https://files.askiitians.com/static_content/Resources/images/result-images/Sashank Cheekala.png" alt="student image" />
                                    </a>
                                </div>
                            </div>
                            <div class="item">
                                <div class="test-content-sec">
                                    <a href="javascript:void()">
                                        <img src="https://files.askiitians.com/static_content/Resources/images/result-images/Kunal Kalani.png" alt="student image" />
                                    </a>
                                </div>
                            </div>
                            <div class="item">
                                <div class="test-content-sec">
                                    <a href="javascript:void()">
                                        <img src="https://files.askiitians.com/static_content/Resources/images/result-images/Rishi Kabra.png" alt="student image" />
                                    </a>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="testimonial-needhelp-sec">
            <div class="tns-txt-sec">
                <h2>NEED HELP ?</h2>
                <p>Let Our Experts help you, Get Free Online Counselling</p>
                <div class="courses">
                    <div class="row">
                        <div class="col-xs-3 col-md-3 pl-0 pr-0">
                            <div class="crs-txt"><i class="fa fa-caret-right" aria-hidden="true"></i> JEE MAIN</div>
                        </div>
                        <div class="col-xs-3 col-md-3 pl-0 pr-0">
                            <div class="crs-txt"><i class="fa fa-caret-right" aria-hidden="true"></i> JEE ADVANCED</div>
                        </div>
                        <div class="col-xs-3 col-md-3 pl-0 pr-0">
                            <div class="crs-txt"><i class="fa fa-caret-right" aria-hidden="true"></i> NEET</div>
                        </div>
                        <div class="col-xs-3 col-md-3 pl-0 pr-0">
                            <div class="crs-txt"><i class="fa fa-caret-right" aria-hidden="true"></i> BOARDS</div>
                        </div>
                    </div>
                </div>
                <div class="tns-cta-sec">
                    <button class="tns-cta" data-toggle="modal" data-target="#userForm" >CONTACT US</button>
                </div>
                
            </div>
        </div>
    </div>
</section>
<!-- End Testimonial Section -->
<!-- Advertise Section -->
<div class="advertise-sec">
    <div class="container">
        <div class="adv-sec-wrapper1">
            <div class="row">
                <div class="col-xs-12 col-md-4">
                    <a href="https://www.askiitians.com/juniorhacker/">
                        <div class="adv-sec1">
                            <div class="advc-logo">
                                <img src="https://files.askiitians.com/static_content/Resources/images/result-images/jr-hacr-logo.svg" alt="logo" />
                            </div>
                            <div class="advc-img">
                                <img src="https://files.askiitians.com/static_content/Resources/images/result-images/jr-hacr-add.svg" alt="ad image" />
                            </div>
                            <div class="advc-txt-sec">
                                <h2>Live 1-1 <span>coding classes</span> <br/>
                                to unleash Creator in your Child</h2>
                            </div>
                            <div class="advc-cta">
                                <a href="https://www.askiitians.com/juniorhacker/">Click Here Know More <i class="fas fa-long-arrow-alt-right"></i></a>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-xs-12 col-md-4">
                    <a href="https://www.askiitians.com/liveclasses.aspx">
                        <div class="adv-sec1 adv-brd">
                            <div class="advc-logo  mt-10">
                                <img src="https://files.askiitians.com/static_content/Resources/images/result-images/live-class-img.svg" class="advc-logo-img" alt="logo" />
                            </div>
                            <div class="advc-img">
                                <img src="https://files.askiitians.com/static_content/Resources/images/result-images/live-class-add.svg" alt="ad image" />
                            </div>
                            <div class="advc-txt-sec">
                                <h2>Now Learn <span>All the Courses Live</span> <br/>
                                From Home</h2>
                            </div>
                            
                            <div class="advc-cta">
                                <a href="https://www.askiitians.com/liveclasses.aspx">Click Here Know More <i class="fas fa-long-arrow-alt-right"></i></a>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-xs-12 col-md-4">
                    <a href="https://www.askiitians.com/one-to-one-live-classes/">
                        <div class="adv-sec1 adv-brd" style="border:2px solid #d4b200">
                            <div class="advc-logo  mt-10">
                                <img src="https://files.askiitians.com/static_content/Resources/images/result-images/one-on-on-logo.svg" alt="one on one image" />
                            </div>
                            <div class="advc-img">
                                <img src="https://files.askiitians.com/static_content/Resources/images/result-images/one-on-one.svg" alt="Ad Image" />
                            </div>
                            <div class="advc-txt-sec">
                                <h2 class="advc-hd-txt">One <span>on</span> One Classes</h2>
                                <p class="advc-sub-txt">A course tailored as per your learning requirements</p>
                            </div>
                            
                            <div class="advc-cta">
                                <a href="https://www.askiitians.com/one-to-one-live-classes/" class="advc-cta-link">Click Here Know More <i class="fas fa-long-arrow-alt-right"></i></a>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Add Section -->

<script src="https://code.jquery.com/jquery-1.12.4.min.js"></script>
<script src="http://qa.askiitians.com/Resources/scripts/carousal/owl-v1-3-3/js/owl.carousel.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/waypoints/2.0.3/waypoints.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/Counter-Up/1.0.0/jquery.counterup.min.js"></script>

<script>
var sync1 = $("#big");
var sync2 = $("#thumbs");

sync1.owlCarousel({
    items: 1,
    dots: false,
    singleItem: true,
    slideSpeed: 1000,
    navigation: false,
    pagination: false,
    autoHeight: true,
    afterAction: syncPosition,
    responsiveRefreshRate: 200,
    onInitialized: function() {
        console.log("sync1 initialized");
    },
    onChange: function() {
        console.log("sync1 changing item");
    }
});

sync2.owlCarousel({
    items: 3,
    dots: false,
    navigation: false,
    itemsDesktop: [1199, 6],
    itemsDesktopSmall: [979, 6],
    itemsTablet: [768, 6],
    itemsMobile: [479, 6],
    pagination: false,
    responsiveRefreshRate: 100,
    afterInit: function (el) {
        el.find(".owl-item").eq(0).addClass("current");
        console.log("sync2 initialized with first item selected");
    },
    onChange: function() {
        console.log("sync2 changing item");
    }
});

function syncPosition(el) {
    var current = this.currentItem;
    console.log("syncPosition triggered, current item: " + current);

    $("#thumbs")
        .find(".owl-item")
        .removeClass("current")
        .eq(current)
        .addClass("current");

    if ($("#thumbs").data("owlCarousel") !== undefined) {
        center(current);
    }
}

$("#thumbs").on("click", ".owl-item", function (e) {
    e.preventDefault();
    var number = $(this).data("owlItem");
    console.log("Thumbnail clicked, moving to item: " + number);

    sync1.trigger("owl.goTo", number);
});

function center(number) {
    var sync2visible = sync2.data("owlCarousel").owl.visibleItems;
    var num = number;
    var found = false;

    console.log("Center function triggered, checking visibility for number: " + num);

    for (var i in sync2visible) {
        if (num === sync2visible[i]) {
            found = true;
            break;
        }
    }

    if (!found) {
        if (num > sync2visible[sync2visible.length - 1]) {
            console.log("Item is outside visible range, scrolling right");
            sync2.trigger("owl.goTo", num - sync2visible.length + 2);
        } else {
            if (num - 1 === -1) {
                num = 0;
            }
            console.log("Item is inside range, scrolling to: " + num);
            sync2.trigger("owl.goTo", num);
        }
    } else if (num === sync2visible[sync2visible.length - 1]) {
        console.log("Item is at the right edge, moving to next visible item");
        sync2.trigger("owl.goTo", sync2visible[1]);
    } else if (num === sync2visible[0]) {
        console.log("Item is at the left edge, moving to previous visible item");
        sync2.trigger("owl.goTo", num - 1);
    }
}

</script>

<script>
    jQuery(document).ready(function ($) {
        $('.counter').counterUp({
            delay: 10,
            time: 2500
        });
        BindCountry();
    });
    function register() {
        if (validate()) {
            newRegistration();
        }
        else {
            return false;
        }

    }
    function validate() {
        if ($('#modalName').val().trim() == '') {
            $('#modalNameSpan').show();
            $('#modalName').focus();
            return false;
        }
        if (isNaN($('#modalName').val()) == false) {
            $('#modalNameSpan').text('Please enter the valid name.');
            $('#modalNameSpan').show();
            $('#modalName').select().focus();
            return false;
        }
        if ($('#modalName').val() != '') {
            var regEx = /^[a-zA-Z0-9_ ]*$/;
            var matchArrayVal = $('#modalName').val().match(regEx);
            if (matchArrayVal == null) {
                $('#modalNameSpan').text('Please enter only alphanumeric value in name');
                $('#modalNameSpan').show();
                $('#modalName').select().focus();
                return false;
            }
        }
        $('#modalNameSpan').hide();

        if (!validateEmail($('#modalEmail').val().trim())) {
            $('#modalEmailSpan').show();
            $('#modalEmail').focus();
            return false;
        }

        $('#modalEmailSpan').hide();
        if ($('#modalMobile').val().trim() == '') {
            $('#modalMobileSpan').text('Please enter contact number');
            $('#modalMobileSpan').show();
            $('#modalMobile').val().focus();
            return false;
        }

        if ($('#modalMobile').val() != '') {
            var contactString = /^\+?[\(\d+\)-]+\d+(-\d+)*$/;
            var matchArray = $('#modalMobile').val().match(contactString);
            if (matchArray == null) {
                $('#modalMobileSpan').text('Invalid contact number! Please enter a valid phone number.');
                $('#modalMobileSpan').show();
                $('#modalMobile').val().select().focus();
                return false;
            }
        }

        if (($('#modalMobile').val().length > 20 || $('#modalMobile').val().length < 9) || $('#modalMobile').val().replace(/\D/g, '').length < 5) {
            $('#modalMobileSpan').text('Invalid contact number!');
            $('#modalMobileSpan').show();
            $('#modalMobile').val().select().focus();
            return false;
        }

        $('#modalMobileSpan').hide();
        if ($('#modaldropGrade').prop('selectedIndex') == 0) {
            $('#modaldropGradeSpan').show();
            $('#modaldropGrade').focus();
            return false;
        }
        $('#modaldropGradeSpan').hide();
        if ($('#modaldropSubject').prop('selectedIndex') == 0) {
            $('#modaldropSubjectSpan').show();
            $('#modaldropSubject').focus();
            return false;
        }
        $('#modaldropSubjectSpan').hide();
        return true;
    }
    function validateEmail(elementValue) {
        var value = elementValue.trim();
        var tempMail = elementValue.match(/^\w[\w\.\_\-]*\w\@\w[\w\-\.]*\w\.[a-zA-z]{2,3}$/g)
        if (value != tempMail)
            return false;
        return true;
    }
    function newRegistration() {
        var registrationUrl = '/webservice/commonwebservice.asmx/LeadUserDetailInsert';
        var campaignSource = getParameterByName('utm_source'),
            campaignMedium = getParameterByName('utm_medium'),
            campaign = getParameterByName('utm_campaign');

        var subject = $('#modaldropSubject').val();
        var grade = $('#modaldropGrade').val();
        var countryCode = $('#ddlCountryCode').val();

        var userId = 0;
        var hdnSourceId = $('#modalbtnRegister').attr('data-source-id');
        var userDetailSubmission = "{userDetail:{'Id':'" + userId + "', 'Name':'" + $('#modalName').val() + "'," +
            "'Email':'" + $('#modalEmail').val() + "'," +
            "'Mobile':'" + countryCode + $('#modalMobile').val()
            + "', 'Subject':'" + subject + "', 'Grade':'" + grade + "','SourceId':'" + hdnSourceId + "','RegistrationUrl':'" + window.location.pathname + "'},'source':'"
            + campaignSource + "','medium':'" + campaignMedium + "','campaign':'" + campaign + "',isNewEntry:true}";

        $.ajax({
            type: "POST",
            url: registrationUrl,
            contentType: "application/json",
            data: userDetailSubmission,
            dataType: "json",
            success: function (data) {
                var result = data.d;
                if (!result.IsError) {
                    $("#userForm").modal("hide");
                    $("#submitMsgPopup").modal("show");
                    return false;
                } else {
                    alert(result.ResponseMessage);
                }
            }
        });

    }
    function BindCountry() {
        var ddlCountryCode = $('#ddlCountryCode');
        ddlCountryCode.empty();
        $.ajax({
            type: 'POST',
            url: 'ResultsPage.aspx/GetCountry',
            method: 'post',
            contentType: "application/json; charset=utf-8",
            dataType: 'json',
            async: false,
            success: function (response) {
                $("#countrycode").text(response);
                var data = response.d;
                ddlCountryCode.empty();
                $.each(data, function (i, vals) {
                    $("#ddlCountryCode").append($("<option/>").val("+" + vals.Value).text(vals.Key + " (+" + vals.Value + ")"));
                });
                var selectedCountry = "+91";
                $("#ddlCountryCode").val(selectedCountry);
            },
        });
    }

    
    $(document).ready(function () {
        $("#topbanner").owlCarousel({
            items: 1,
            navigation: false, // Show next and prev buttons
            dots: false,
            slideSpeed: 300,
            paginationSpeed: 400,
            singleItem: true
            // "singleItem:true" is a shortcut for:
            // itemsDesktop : false,
            // itemsDesktopSmall : false,
            // itemsTablet: false,
            // itemsMobile : false
        });
    });
</script>

<script>
    $('#topSlider').owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 1
            }
        }
    });
    $(document).ready(function () {
        var bigimage = $("#big");
        var thumbs = $("#thumbs");
        //var totalslides = 10;
        var syncedSecondary = true;

        bigimage
            .owlCarousel({
                items: 1,
                slideSpeed: 2000,
                nav: false,
                autoplay: false,
                dots: false,
                loop: true,
                responsiveRefreshRate: 200,

            })
            .on("changed.owl.carousel", syncPosition);

        thumbs
            .on("initialized.owl.carousel", function () {
                thumbs
                    .find(".owl-item")
                    .eq(0)
                    .addClass("current");
            })
            .owlCarousel({
                items: 4,
                dots: true,
                nav: false,

                smartSpeed: 200,
                slideSpeed: 500,
                slideBy: 4,
                responsiveRefreshRate: 100
            })
            .on("changed.owl.carousel", syncPosition2);

        function syncPosition(el) {
            var count = el.item.count - 1;
            var current = Math.round(el.item.index - el.item.count / 2 - 0.5);

            if (current < 0) {
                current = count;
            }
            if (current > count) {
                current = 0;
            }
            //to this
            thumbs
                .find(".owl-item")
                .removeClass("current")
                .eq(current)
                .addClass("current");
            var onscreen = thumbs.find(".owl-item.active").length - 1;
            var start = thumbs
                .find(".owl-item.active")
                .first()
                .index();
            var end = thumbs
                .find(".owl-item.active")
                .last()
                .index();

            if (current > end) {
                thumbs.data("owl.carousel").to(current, 100, true);
            }
            if (current < start) {
                thumbs.data("owl.carousel").to(current - onscreen, 100, true);
            }
        }

        function syncPosition2(el) {
            if (syncedSecondary) {
                var number = el.item.index;
                bigimage.data("owl.carousel").to(number, 100, true);
            }
        }

        thumbs.on("click", ".owl-item", function (e) {
            e.preventDefault();
            var number = $(this).index();
            bigimage.data("owl.carousel").to(number, 300, true);
        });
        //$("#modalbtnRegister").click(function () {
        //    validate();
        //})
        
    });
</script>