import { Injectable } from '@angular/core';
import { ApiCallService } from './api-call.service';
import { Observable } from 'rxjs';
import { endPoints } from '../api-end-points';

@Injectable({
  providedIn: 'root'
})
export class BookAFreeDemoService {
  constructor(private apiService: ApiCallService) { }

  bookFreeDemo(demoData: any): Observable<any> {
    let para = encodeURI(`Name=${demoData.Name}&Mobile=${demoData.Mobile}&Email=${demoData.Email}&Grade=${demoData.Grade}&Topic=${demoData.Topic}&Date=${demoData.Date}&Time=${demoData.Time}`);
    return this.apiService.Post(`${endPoints.BOOK_FREE_DEMO}?${para}`, null, { 'Content-Type': 'text/plain; charset=utf-8' }, 'text');
  }

  submitConatctUsLead(demoData: any): Observable<any> {
     
    // Construct URL parameters with all required fields
    let para = encodeURI(
      `Name=${demoData.Name}&Mobile=${demoData.PhoneNumber}&Email=${demoData.Email}&Message=${demoData.Message}`
    );

    return this.apiService.Post(
      `${endPoints.SUBMIT_CONTACT_US_PARENT_LEAD}?${para}`,
      null,
      { 'Content-Type': 'text/plain; charset=utf-8' },
      'text'
    );
  }

  submitJuniorHackerParentsLead(demoData: any): Observable<any> { 
    // Construct URL parameters with all required fields
    let para = encodeURI(`Name=${demoData.Name}&Mobile=${demoData.PhoneNumber}&Email=${demoData.Email}&Grade=${demoData.Grade}`);

    return this.apiService.Post(
        `${endPoints.SUBMIT_JUNIOR_HACKER_PARENT_LEAD}?${para}`,
        null,
        { 'Content-Type': 'text/plain; charset=utf-8' },
        'text'
    );
  }
}
