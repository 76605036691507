import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {
  currentPage: string = '';
  _routeSubscriber: any;
  constructor(private router: Router
  ) { }
  ngOnInit(): void {
    this._routeSubscriber = this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        this.currentPage = ev.url;
      }
    });
  }

  ngOnDestroy() {
    this._routeSubscriber.unsubscribe();
  }
}
