import { Component, Injectable, } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MetaContentService } from 'src/app/services/meta-content.service';

@Injectable({
  providedIn: 'root',
})

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent {

  response: any;
  statusCode: any = 404;
  constructor(
    private metaService: MetaContentService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      if (params.get('status') !== null) {
        this.statusCode = parseInt(params.get('status') || '404');
      }
      this.handleStatusCode(this.statusCode);
    });
  }


  private handleStatusCode(statusCode: number) {
    if (statusCode === 404) {
      this.response = { statusCode: 404, statusMessage: '404 - Page Not Found' };
      this.metaService.updateMetaContent(null, null, 'Page Not Found', null, null, 'Error', null);
    } else if (statusCode === 410) {
      this.response = { statusCode: 410, statusMessage: 'Page has been deleted' };
      this.metaService.updateMetaContent(null, null, 'Page has been deleted', null, null, 'Error', null);
    }
  }
}
