import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';
import { NotificationType } from 'src/app/enum/NotificationType';

@Component({
  selector: 'app-free-trial-notify',
  templateUrl: './free-trial-notify.component.html',
  styleUrls: ['./free-trial-notify.component.css']
})
export class FreeTrialNotifyComponent {

  @Input() FreeTrialStatus!: string;
  @Input() isTrialPending!: string;
  @Input() isTrialExpired!: boolean;
  @Input() isPackageExpired!: boolean;
  @Output() isUserInTrial: EventEmitter<boolean> = new EventEmitter();

  constructor(private activeModal: NgbActiveModal,
    private router: Router,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
  }

  buyaPackage() {
    this.router.navigate(['/package']);
    this.isUserInTrial.emit(true);
    this.activeModal.close();
  }

  exploreStudyPod() {
    this.notificationService.notifyMessage(NotificationType.Success, `You have started your free trial!`);
    this.isUserInTrial.emit(true);
    this.activeModal.close();
  }

  closeModal() {
    this.isUserInTrial.emit(true);
    this.activeModal.close();
  }
}
