<!-- footer -->
<ng-container *ngIf="currentPage != '/sat'">
    <footer class="footer_section" *ngIf="currentPage != '/juniorHacker'">
        <div class="container">
            <div class="row w-100">
                <div class="col-lg-6 col-md-6 first">
                    <img loading="lazy" src="/assets/images/logo-2.png" alt="logo2" width="143px" height="40px" class="img-fluid">
                    <p>We help you live your dreams. Get access to our extensive online
                        coaching courses for IIT JEE, NEET and other entrance exams with
                        personalised online classes, lectures, study talks and test series
                        and map your academic goals.</p>
                </div>

                <div class="col-lg-3 col-md-6 second ps-md-3">
                    <h5>Company</h5>
                    <ul class="list-unstyled">
                        <li><a routerLink="/about-us.aspx" routerLinkActive="active" ariaCurrentWhenActive="page"><i class="fa fa-caret-right"></i> About
                                US</a></li>
                        <li><a routerLink="/privacy-policy.html" routerLinkActive="active"
                                ariaCurrentWhenActive="page"><i class="fa fa-caret-right"></i> Privacy Policy</a></li>
                        <li><a routerLink="/terms-and-conditions" routerLinkActive="active"
                                ariaCurrentWhenActive="page"><i class="fa fa-caret-right"></i> Terms and condition</a></li>
                        <li><a routerLink="package/" routerLinkActive="active" ariaCurrentWhenActive="page"><i class="fa fa-caret-right"></i> Course
                                Packages</a></li>
                    </ul>

                </div>
                <div class="col-lg-3 col-md-6 third">
                    <h5>Contact US</h5>
                    <ul class="list-unstyled">
                        <li>
                            <a href="tel:7353221155">
                                <!-- <img loading="lazy" src="/assets/images/footer_icon/phone.png" alt="phone"
                                    width="30px" height="30px" class="img-fluid"> -->
                                    <i class="footer fa fa-phone"></i> +91-735-322-1155
                            </a>
                        </li>
                        <li>
                            <a href="mailto:info@askiitians.com">
                                <!-- <img loading="lazy" src="/assets/images/footer_icon/email.png" alt="email"
                                    width="30px" height="30px" class="img-fluid"> -->
                                    <i class="footer fa fa-envelope-o"></i> info&#64;askiitians.com
                            </a>
                        </li>
                        <li>
                            <a href="https://www.google.com/maps/search/E-32,+Sector-6+++Noida+-+201301/@28.5928788,77.3135186,16z?hl=en&entry=ttu"
                                style="display: flex">
                                <div> <!--style="margin-right: 22px"-->
                                    <!-- <img loading="lazy" src="/assets/images/footer_icon/location.png"
                                        width="30px" height="30px" alt="location" class="img-fluid"> -->
                                        <i class="footer fa fa-map-marker"></i>
                                    </div> AskiiTians.com
                                    C/O Transweb
                                    B-30, Sector-6
                                    Noida - 201301
                                    Tel No. +91 7353221155
                            </a>
                        </li>
                    </ul>
                </div>

                <div class="col-lg-12 d-flex justify-content-between">
                    <div class="one">
                        <div>
                            <a href="https://www.facebook.com/askiitians" target="blank" aria-label="Visit Askiitians Online Education facebook"> 
                                <!-- <img loading="lazy"
                                    src="/assets/images/footer_icon/facebook.png" alt="facebook" width="11px"
                                    height="20px" class="img-fluid"> -->
                                    <i class="footer social fa fa-facebook"></i>
                                </a>
                        </div>
                        <div>
                            <a href="https://twitter.com/askiitians" target="blank" aria-label="Visit Askiitians Online Education twitter"> 
                                <!-- <img loading="lazy"
                                    src="/assets/images/footer_icon/twitter.png" alt="twitter" width="20px" height="16px"
                                    class="img-fluid"> -->
                                    <i class="footer social fa fa-twitter"></i>
                                </a>
                        </div>
                        <div>
                            <a href=" https://www.instagram.com/askiitians" target="blank" aria-label="Visit Askiitians Online Education Instagram"> 
                                <!-- <img loading="lazy"
                                    src="/assets/images/footer_icon/instagram.png" alt="instagram" width="24px"
                                    height="24px" class="img-fluid" style="height:24px;"> -->
                                    <i class="footer social fa fa-instagram"></i>
                                </a>
                        </div>
                        <div>
                            <a href="https://www.youtube.com/@Askiitians-online-education" target="blank" aria-label="Visit Askiitians Online Education YouTube Channel"> 
                                <!-- <img loading="lazy"
                                    src="/assets/images/footer_icon/youtube.png" alt="youtube" width="20px" height="15px"
                                    class="img-fluid"> -->
                                    <i class="footer social fa fa-youtube-play"></i>
                                </a>
                        </div>
                    </div>

                    <div class="two aiCaption">
                        <p> <a href="mailto:info@askiitians.com" target="blank">info&#64;askiitians.com , </a> 2006-2024, All Rights reserved</p>
                    </div>
                </div>
            </div>
        </div>
    </footer>


    <!-- End Footer Form Section -->
    <!--================  Footer Section  ================-->
    <ng-container *ngIf="currentPage == '/juniorHacker'">
        <div class="ask-footer-section">
            <div class="container">
                <div class="row">
                    <div class="col-md-3">
                        <div class="ask-fs-txt-container">
                            <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/logo.png" loading="lazy" width="178px" height="51px"
                                alt="Footer Logo" />
                            <p class="logo-sub-txt">Unleash your child’s potential with live coding classes from top
                                professionals in the country at affordable prices</p>
                            <p class="ask-footer-icons">
                                <a><i class="fa fa-twitter"></i></a>
                                &nbsp;&nbsp; <a><i class="fa fa-pinterest-p"></i></a>&nbsp;&nbsp;
                                <a><i class="fa fa-youtube"></i></a>
                            </p>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="ask-fs-txt-container">
                            <ul>
                                <li><a routerLink="/about-us.aspx" routerLinkActive="active"
                                        ariaCurrentWhenActive="page">About Us</a></li>
                                <li><a routerLink="/blog" routerLinkActive="active">Blog</a></li>
                                <li><a routerLink="/one-to-one-live-classes" routerLinkActive="active"
                                        ariaCurrentWhenActive="page">Live Classes</a></li>
                                <li><a>StudyPod</a></li>
                                <li><a routerLink="/package" routerLinkActive="active"
                                        ariaCurrentWhenActive="page">Course Package</a></li>
                                <li><a>Site Map</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="ask-fs-txt-container">
                            <ul>
                                <li><a routerLink="/media-coverage.aspx" routerLinkActive="active"
                                        ariaCurrentWhenActive="page">Media Coverage</a></li>
                                <li><a routerLink="/school-tie-up-program" routerLinkActive="active"
                                        ariaCurrentWhenActive="page">School Tie-up</a></li>
                                <li><a routerLink="/franchisee" routerLinkActive="active"
                                        ariaCurrentWhenActive="page">Franchisee</a></li>
                                <li><a routerLink="/rd-sharma-solutions" routerLinkActive="active"
                                        ariaCurrentWhenActive="page">RD Sharma Solution</a></li>
                                <li><a>Tutor Login</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="ask-fs-txt-container">
                            <ul>
                                <li><a routerLink="/careers.aspx" routerLinkActive="active"
                                        ariaCurrentWhenActive="page">Career </a></li>
                                <li><a routerLink="/faqs.aspx" routerLinkActive="active"
                                        ariaCurrentWhenActive="page">FAQ'S</a></li>
                                <li><a routerLink="/privacy-policy.html" routerLinkActive="active"
                                        ariaCurrentWhenActive="page">Privacy Policy</a></li>
                                <li><a routerLink="/terms-and-conditions" routerLinkActive="active"
                                        ariaCurrentWhenActive="page">Terms & Conditions</a></li>
                                <li><a routerLink="/contact-us.aspx" routerLinkActive="active"
                                        ariaCurrentWhenActive="page">Contact Us</a></li>
                                <li><a>Pay Now</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--================  End Footer Section  ================-->
        <div class="scroll-top" style="display: block;">
            <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/icons/launch.png" loading="lazy" alt="launch icon" width="40px" height="40px" />
        </div>
    </ng-container>
</ng-container>