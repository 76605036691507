import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { StorageService } from './storage.service';
import { StorageKey, StorageType } from '../storage-key';
import { HmacUtils } from './hmac.service';

@Injectable({
  providedIn: 'root',
})
export class ApiCallService {
  headers: HttpHeaders;
  baseUrl: string = environment.baseUrl;
  constructor(private http: HttpClient, private storage: StorageService) {
    this.headers = new HttpHeaders();
  }
  setCustomHeader(headers: any, httpMethod: string, url: string, body: any) {
    var headersArray = headers ? headers : {};
    if (Object.keys(headersArray).filter((x) => x == 'Content-Type').length <= 0) {
      headersArray['Content-Type'] = 'application/json';
    }
    if (
      this.storage.getItem(StorageKey.TOKEN, StorageType.LOCAL) != null &&
      this.storage.getItem(StorageKey.TOKEN, StorageType.LOCAL) != undefined
    ) {
      const token = this.storage.getItem(StorageKey.TOKEN, StorageType.LOCAL);
      if (Object.keys(headersArray).filter((x) => x == 'Authorization').length <= 0) {
        headersArray['Authorization'] = 'Bearer ' + token;
      }
    }

    // Generate HMAC token
    // const hmacToken = HmacUtils.generateHmacToken(httpMethod, url, body);

    // Set HMAC token in headers
    // headersArray['x-hAsk-signature'] = hmacToken;

    this.headers = new HttpHeaders(headersArray);
  }

  setHeader(headers?: any) {
    var headersArray = headers ? headers : {};
    if (Object.keys(headersArray).filter((x) => x == 'Content-Type').length <= 0) {
      headersArray['Content-Type'] = 'application/json';
    }
    if (
      this.storage.getItem(StorageKey.TOKEN, StorageType.LOCAL) != null &&
      this.storage.getItem(StorageKey.TOKEN, StorageType.LOCAL) != undefined
    ) {
      const token = this.storage.getItem(StorageKey.TOKEN, StorageType.LOCAL);
      if (Object.keys(headersArray).filter((x) => x == 'Authorization').length <= 0) {
        headersArray['Authorization'] = 'Bearer ' + token;
      }
    }
    this.headers = new HttpHeaders(headersArray);
  }
  
  public GetJson(filePath: string): Observable<any> {
    return this.http.get(filePath);
  }
  public Get(url: string, params?: any, headers?: any, responseType?: any): Observable<any> {
    try {
      //this.setHeader(headers);
      this.setCustomHeader(headers, 'GET', url, params);
      var respone = this.http.get(this.baseUrl + url, {
        headers: this.headers,
        params,
        responseType: responseType ?? 'json',
      });

      return respone;
    } catch (error) {
      throw error;
    }
  }
  public Post(url: string, params: any, headers?: any, responseType?: any): Observable<any> {
    try {
       
      //this.setHeader(headers);
      this.setCustomHeader(headers, 'POST', url, params);
      return this.http.post(this.baseUrl + url, params, {
        headers: this.headers,
        responseType: responseType ?? 'json',
      });
    } catch (error) {
      throw error;
    }
  }

  public createLead(url: string, params: any, headers?: any, responseType?: any): Observable<any> {
    try {
      var leadheadersArray = headers ? headers : {};
      leadheadersArray['secret-key'] = 'd0c5c6bc7d49de013b09e69c031f437c';
      leadheadersArray['access-key'] = 'ee71837c527a4091bfc34aa85bb41853';
      leadheadersArray['Content-Type'] = 'application/json';
      leadheadersArray['Access-Control-Allow-Origin'] = '*';
      console.log(leadheadersArray);
      return this.http.post(url, params, {
        headers: leadheadersArray,
        responseType: responseType ?? 'json',
      });
    } catch (error) {
      throw error;
    }
  }


  /**
   * Put Method
   */
  public Put(url: string, params: any, headers?: any, responseType?: any): Observable<any> {
    try {
      //this.setHeader(headers);
      this.setCustomHeader(headers, 'PUT', url, params);
      return this.http.put(this.baseUrl + url, params, { headers: this.headers, responseType: responseType ?? 'json' });
    } catch (error) {
      throw error;
    }
  }

  public Patch(url: string, params: any, headers?: any, responseType?: any): Observable<any> {
    try {
      //this.setHeader(headers);
      this.setCustomHeader(headers, 'PATCH', url, params);
      return this.http.patch(this.baseUrl + url, params, {
        headers: this.headers,
        responseType: responseType ?? 'json',
      });
    } catch (error) {
      throw error;
    }
  }

  
}
