export interface CountryCode {
    code: string;
    country: string;
    country_code: string;
    flag: string;
}

export const CountryCodeModel: CountryCode[] = [
    { code: '+1', country: 'United States', country_code: 'US', flag: 'Flag_of_United_States.webp' },
    { code: '+971', country: 'UAE', country_code: 'AE', flag: 'Flag_of_United_Arab_Emirates.webp' },
    { code: '+62', country: 'Indonesia', country_code: 'ID', flag: 'Flag_of_Indonesia.webp' },
    { code: '+968', country: 'Oman', country_code: 'OM', flag: 'Flag_of_Oman.webp' },
    { code: '+65', country: 'Singapore', country_code: 'SG', flag: 'Flag_of_Singapore.webp' },
    { code: '+966', country: 'Saudi Arabia', country_code: 'SA', flag: 'Flag_of_Saudi_Arabia.webp' },
    { code: '+974', country: 'Qatar', country_code: 'QA', flag: 'Flag_of_Qatar.webp' },
    { code: '+973', country: 'Bahrain', country_code: 'BH', flag: 'Flag_of_Bahrain.webp' },
    { code: '+60', country: 'Malaysia', country_code: 'MY', flag: 'Flag_of_Malaysia.webp' },
    { code: '+256', country: 'Uganda', country_code: 'UG', flag: 'Flag_of_Uganda.webp' },
    { code: '+965', country: 'Kuwait', country_code: 'KW', flag: 'Flag_of_Kuwait.webp' },
    { code: '+91', country: 'India', country_code: 'IN', flag: 'Flag_of_India.webp' },
];