import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';

@Component({
  selector: 'app-juniorhacker',
  templateUrl: './juniorhacker.component.html',
  styleUrls: ['./juniorhacker.component.css']
})
export class JuniorhackerComponent {
  tabletlearningprogram: string[] = [
    '/Resources/scripts/jquery.min1.js',
    '/Resources/scripts/owl.carousel.min.js',
    '/Resources/scripts/owl.carousel.js',
    '/Resources/style/font-awesome.min.css',
    '/Resources/style/owl.carousel.css',
    '/Resources/style/owl.theme.default.min.css'
  ];

  constructor(@Inject(DOCUMENT) private dom: Document, @Inject(PLATFORM_ID) private platformId: Object) {}

  ngOnInit() {
    // Load scripts and styles on component initialization
    this.loadCSS(this.tabletlearningprogram);
  }

  loadCSS(csslist: any) {
    if (isPlatformBrowser(this.platformId)) {
      if (Array.isArray(csslist)) {
        for (let n = 0; n < csslist.length; n++) {
          const file = csslist[n];
          const isCSS = file.endsWith('.css');
          const isJS = file.endsWith('.js');
          if (isCSS) {
            const link = this.dom.createElement('link');
            link.setAttribute('rel', 'stylesheet');
            link.setAttribute('href', file);
            this.dom.head.appendChild(link);
          } else if (isJS) {
            const script = this.dom.createElement('script');
            script.setAttribute('src', file);
            this.dom.head.appendChild(script);
          }
        }
      }
    }
  }
}
