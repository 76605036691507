import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationType } from 'src/app/enum/NotificationType';
import { GradeModel } from 'src/app/Models/GradeModel';
import { CacheService } from 'src/app/services/cache.service';
import { NotificationService } from 'src/app/services/notification.service';
import { StorageService } from 'src/app/services/storage.service';
import { UserService } from 'src/app/services/user.service';
import { StorageKey, StorageType } from 'src/app/storage-key';
import { LoginComponent } from '../login/login.component';
import { RegistrationComponent } from '../registration/registration.component';
import { SourceIdEnum } from 'src/app/Models/sourceIdEnumModel';
import { GoogleResponseModel, GoogleResponseRequestModel } from 'src/app/Models/GoogleResponseModel';
import { OtpVerificationComponent } from '../otp-verification/otp-verification.component';
import { DatePipe } from '@angular/common';
import { Registration } from 'src/app/Models/RegistrationModel';
import { TrialService } from 'src/app/services/trial.service';
import { TrialUserComponent } from '../trial-user/trial-user.component';
import { environment } from 'src/environments/environment';
import { ClientIPAddressService } from 'src/app/services/client-ipaddress.service';
@Component({
  selector: 'app-login-with-email',
  templateUrl: './login-with-email.component.html',
  styleUrls: ['./login-with-email.component.css'],
})
export class LoginWithEmailComponent implements OnInit {
  @Input() sourceId!: number;
  @Output() isUserLogged: EventEmitter<any> = new EventEmitter();
  @Output() userid: EventEmitter<any> = new EventEmitter();
  @Input() isResendOTP: boolean = false;
  loginWithEmailForm!: FormGroup;
  mobileVerificationForm!: FormGroup;
  separateDialCode = true;
  getGra: Array<GradeModel> = [];
  userID!: number;
  isLoading: boolean = false;
  isForgotPassword: boolean = false;
  googleClientId: string = environment.GoogleClientId;
  googleResponseRequestModel!: GoogleResponseRequestModel;
  googleResponseModel!: GoogleResponseModel;
  isVerified: boolean = false;
  isSignInwithEmail: boolean = false;
  isUserExist: boolean = false;
  createPassword: boolean = false;
  showPasswordField: boolean = false;
  validatePhoneNumber: Boolean = false;
  userData: any;
  registrationModal!: Registration;
  isValidPassword: boolean = true;
  registration!: Registration;
  componentSourceId!: number;
  grade!: string;
  clientIPAdress!: string;
  check = false;
  isVerifyOtp = false;
  otp: string | undefined;
  verificationId: number | undefined;
  modalRefOTPVerification:any;
  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private userService: UserService,
    private notificationService: NotificationService,
    private storageService: StorageService,
    private cachaService: CacheService,
    private datePipe: DatePipe,
    private trialService: TrialService,
    private clientIPAdressService: ClientIPAddressService
  ) {}
  ngOnInit() {
    this.loginWithEmailForm = this.formBuilder.group({
      Email: ['', [Validators.required]],
      Password: ['', Validators.required],
    });
    this.mobileVerificationForm = this.formBuilder.group({
      PhoneNo: ['', [Validators.required]],
    });
    this.componentSourceId = this.sourceId;
    this.clientIPAdressService.getClientIp().subscribe((ip) => {
      console.log('Client IP:', ip);
      this.clientIPAdress = ip;
    });
  }

  get e() {
    return this.loginWithEmailForm.controls;
  }
  get f() {
    return this.mobileVerificationForm.controls;
  }

  loginWithEmail() {
    if (this.loginWithEmailForm.valid) {
      this.userService.checkEmailId(this.loginWithEmailForm.value.Email).subscribe((data) => {
        if (data.length > 0) {
          this.userID = data[0].Id;
          const loginData = {
            Email: this.loginWithEmailForm.controls['Email'].value,
            Password: this.loginWithEmailForm.controls['Password'].value,
          };
          this.userService.loginWithEmailId(loginData).subscribe({
            next: (res) => {
              if (res.message == 'Login Successfull') {
                this.storageService.setItem(StorageKey.IS_LOGGED_IN, 'true', StorageType.LOCAL);
                this.cachaService.setIsUserLoggedIn(true);
                this.getUserDetailsById(res.userId);
                this.isUserLogged.emit(true);
                this.userid.emit(res.userId);
                this.activeModal.close();
                this.notificationService.notifyMessage(NotificationType.Success, `You're Login Successfully!`);
              }
            },
            error: (error) => {
              if (error.status === 400) {
                this.notificationService.notifyMessage(NotificationType.Success, `Invalid Email or Password`);
              }
            },
          });
        } else {
          this.notificationService.notifyMessage(
            NotificationType.Success,
            `You are not a registered user. Please register as a new user.`
          );
          this.activeModal.close();
          const sourceId = SourceIdEnum.Register;
          const modalRef = this.modalService.open(RegistrationComponent, { centered: true, backdrop: 'static' });
          modalRef.componentInstance.sourceId = sourceId;
          modalRef.componentInstance.isShowCrossbtn = true;
        }
      });
    }
  }

  forgotPassword() {
    this.isForgotPassword = true;
    this.isSignInwithEmail = true;
  }

  onLoginWithPhone() {
    this.activeModal.close();
    // const sourceId = SourceIdEnum.Login;
    const modalRef = this.modalService.open(LoginComponent, { centered: true, backdrop: 'static' });
    // modalRef.componentInstance.sourceId = sourceId;
  }

  closeModal() {
    this.activeModal.close();
  }

  getUserDetailsById(userid: number) {
    this.userService.getUserFromID(userid).subscribe((res) => {
      this.grade = res.userdetails.grade;
      this.storageService.setItem(StorageKey.IS_LOGGED_IN, 'true', StorageType.LOCAL);
      this.storageService.setItem(StorageKey.USER, JSON.stringify(res), StorageType.LOCAL);
      this.cachaService.onUserDetailsChange(res);
    });
  }

  getPassword() {
    this.isLoading = true;
    this.userService.checkEmailId(this.loginWithEmailForm.value.Email).subscribe((isExistUser) => {
      if (isExistUser.length > 0) {
        this.userService.forgotPassword(this.loginWithEmailForm.value.Email).subscribe({
          next: (response) => {
            this.activeModal.close();
            if (response == 'Password has been sent to your registered email ID.') {
              this.notificationService.notifyMessage(
                NotificationType.Success,
                `Password has been sent to your registered email ID Please Chaeck your Email`
              );
              this.isLoading = false;
            } else {
              if (response.status === 400) {
                this.notificationService.notifyMessage(
                  NotificationType.Success,
                  `You are not a registered user. Please register as a new user.`
                );
              }
              this.isLoading = false;
            }
          },
          error: (error) => {
            if (error.status === 400) {
              this.notificationService.notifyMessage(NotificationType.Success, `Please Provide a valid email address.`);
            }
          },
        });
        this.isLoading = false;
      } else {
        this.notificationService.notifyMessage(
          NotificationType.Success,
          `You are not a registered user. Please register as a new user.`
        );
        this.activeModal.close();
        const sourceId = SourceIdEnum.Register;
        const modalRef = this.modalService.open(RegistrationComponent, { centered: true, backdrop: 'static' });
        modalRef.componentInstance.isShowCrossbtn = true;
        modalRef.componentInstance.sourceId = sourceId;
      }
      this.isLoading = false;
    });
  }
  onGoogleSigninSuccess(data: any) {
    const sourceId = SourceIdEnum.GoogleSignIn;
    this.googleResponseModel = data;

    this.googleResponseRequestModel = {
      email: data.email,
      name: data.name,
      grade: '',
      mobile: '',
      password: '',
      profilePictureUrl: data.picture,
      registrationUrl: String(window.location.pathname),
      sourceNameId: sourceId,
      ipAddress: this.clientIPAdress,
      isMobile: false,
      ipCountry: '',
      city: '',
      googleUniqueId: '',
      accessToken: '',
      // expiresAt: '',
      // expiresIn: '',
      // firstIssuedAt: '',
      // providerId: data.Sc != undefined ? data.Sc.idpId : data.Tc.idpId,
      // scope: data.Sc != undefined ? data.Sc.scope : data.Tc.scope
    };

    // this.userService.checkEmailId(data.email).subscribe((isExistUser) => {
    //   if (isExistUser.length > 0) {
    //   }

    // });
    this.userService.checkEmailId(data.email).subscribe((isExistUser) => {
      // if (isExistUser.length > 0) {
      //   this.notificationService.notifyMessage(NotificationType.Success, `Logged In SuccessFully`);

      // }
      // else
      // {
      this.userService.saveGoogleResponse(this.googleResponseRequestModel).subscribe((res) => {
        if (res.id != null || res.id > 0) {
          if (res.isFirstTimeSignIn == false) {
            if (res.mobile == '' || res.mobile == undefined) {
            } else {
              this.userID = res.id;
              this.setUserLoggedIn();
            }
          } else {
            if (res.mobile == '' || res.mobile == undefined) {
            } else {
              this.userID = res.id;
              this.setUserLoggedIn();
            }
          }
        } else {
          this.notificationService.notifyMessage(NotificationType.Error, `Login Failed`);
        }
      });
      // }
    });
  }
  isResolved(resolvedData: any) {
    if (resolvedData != null || resolvedData != '') {
      this.isVerified = true;
    } else {
      this.isVerified = false;
    }
  }

  loginwithEmail() {
    this.isSignInwithEmail = true;
    this.isForgotPassword = false;
  }

  keyUpEvent() {
    if (this.loginWithEmailForm.controls['Email'].invalid) {
      return;
    } else {
      this.storageService.setItem(StorageKey.EMAIL, this.loginWithEmailForm.controls['Email'].value, StorageType.LOCAL);
      this.userService.checkEmailId(this.loginWithEmailForm.controls['Email'].value).subscribe((userData) => {
        this.userData = userData;
        if (userData.length > 0) {
          this.isUserExist = true;
          this.createPassword = false;
          this.showPasswordField = true;
          this.validatePhoneNumber = false;
        } else {
          this.isUserExist = false;
          this.createPassword = true;
          this.showPasswordField = true;
        }
      });
    }
  }

  VerifyMobileNumber() {
    this.check = true;
    if (this.isUserExist == true) {
      this.userID = this.userData[0].Id;
      const loginData = {
        Email: this.loginWithEmailForm.controls['Email'].value,
        Password: this.loginWithEmailForm.controls['Password'].value,
      };
      this.userService.loginWithEmailId(loginData).subscribe({
        next: (res) => {
          if (res.message == 'Login Successfull') {
            this.storageService.setItem(StorageKey.IS_LOGGED_IN, 'true', StorageType.LOCAL);
            this.cachaService.setIsUserLoggedIn(true);
            this.getUserDetailsById(res.userId);
            this.isUserLogged.emit(true);
            this.userid.emit(res.userId);
            this.activeModal.close();
            this.notificationService.notifyMessage(NotificationType.Success, `You're Login Successfully!`);
          }
        },
        error: (error) => {
          if (error.status === 400) {
            this.notificationService.notifyMessage(NotificationType.Success, `Invalid Email or Password`);
          }
        },
      });
    } else {
      if (this.loginWithEmailForm.invalid) {
        return;
      } else {
        this.validatePhoneNumber = true;
      }
    }
  }
  // comment it

  VerifyOtp() {
    this.isVerifyOtp = true;
    if (this.isUserExist == true && this.userData[0].Mobile != null) {
      this.userID = this.userData[0].Id;
      this.setUserLoggedIn();
    } else {
      this.userService.checkUser(this.mobileVerificationForm.value.PhoneNo).subscribe({
        next: (data) => {
          this.notificationService.notifyMessage(NotificationType.Warning, `You're already Register with this number!`);
        },
        error: () => {
          this.storageService.setItem(
            StorageKey.MOBILE_NO,
            this.mobileVerificationForm.value.PhoneNo,
            StorageType.LOCAL
          );
            this.modalRefOTPVerification = this.modalService.open(OtpVerificationComponent, {
            centered: true,
            backdrop: 'static',
          });
          this.modalRefOTPVerification.componentInstance.isOtpVerfied.subscribe((isOtpVerfied: any) => {
            this.modalRefOTPVerification.componentInstance.otp.subscribe((otp: string) => {
              this.otp = otp;
              this.modalRefOTPVerification.componentInstance.VerificationId.subscribe((VerificationId: number) => {
                this.verificationId = VerificationId;
                if (isOtpVerfied == true && VerificationId) {
                  if (this.isUserExist == true && this.userData[0].Email) {
                    this.updateUserDetails();
                  } else {
                   this.registerUser();
                  }
                }
              });
            });
          });
        },
      });
    }
  }
  VerifyOtpfortesting() {
   this.modalRefOTPVerification = this.modalService.open(OtpVerificationComponent, {
      centered: true,
      backdrop: 'static',
    });
    this.modalRefOTPVerification.componentInstance.isOtpVerfied.subscribe((isOtpVerfied: any) => {
      if (isOtpVerfied == true) {
      }
    });
  }

  updateUserDetails() {
    var gradeId: any = this.mobileVerificationForm.value.gradeId;
    this.registrationModal = {
      mobile: this.mobileVerificationForm.value.PhoneNo,
      name: this.mobileVerificationForm.value.name,
      email: this.mobileVerificationForm.value.email,
      userDetails: {
        id: 0,
        userId: this.userData[0].Id,
        schoolName: '',
        grade: gradeId,
        modifiedDate: this.transformDate(new Date())?.toString(),
      },
      mobileVerification: {
        verificationId: 0,
      },
    };

    this.userService.updateUserDetail(this.userData[0].Id, this.registrationModal).subscribe((data) => {
      this.userID = this.userData[0].Id;
      this.setUserLoggedIn();
    });
  }

  transformDate(date: string | number | Date) {
    return this.datePipe.transform(date, "yyyy-MM-ddThh:mm:ss.SSS'Z'");
  }
  setUserLoggedIn() {
    this.cachaService.setIsUserLoggedIn(true);
    this.getUserDetailsById(this.userID);
    this.activeModal.close();
    this.isUserLogged.emit(this.storageService.getItem(StorageKey.IS_LOGGED_IN, StorageType.LOCAL));
    this.notificationService.notifyMessage(NotificationType.Success, `Logged In SuccessFully`);
    if (this.sourceId == 30) {
      this.getUserStatus();
    }
  }
  registerUser(): any {
    let returnValue = '';
    const mobileVerification = {
      otp: this.otp,
      PhoneNo: this.mobileVerificationForm.value.PhoneNo,
    };

    this.registration = {
      id: 0,
      name: '',
      email: this.loginWithEmailForm.value.Email,
      isActive: true,
      password: this.loginWithEmailForm.value.Password,
      userTypeId: 1,
      mobile: this.mobileVerificationForm.value.PhoneNo,
      createdDateTime: this.transformDate(new Date())?.toString(),
      modifiedBy: '',
      lastModified: this.transformDate(new Date())?.toString(),
      registrationUrl: String(window.location.pathname),
      sourceNameId: this.sourceId,
      ipCountry: '',
      ipAddress: this.clientIPAdress,
      city: '',
      VerificationId: this.verificationId,
      otp: this.otp,
      userDetails: {
        id: 0,
        grade: '',
        userId: 0,
        schoolName: '',
        modifiedDate: this.transformDate(new Date())?.toString(),
      },
      mobileVerification: {
        otp: this.otp,
        mobile: this.mobileVerificationForm.value.PhoneNo,
      },
    };
    if (this.registration.mobile != null) {
      this.userService.create(this.registration).subscribe({
        next: (data) => {
          if (data.result.value.id != null) {
            this.userID = data.result.value.id;
            this.storageService.setItem(StorageKey.IS_LOGGED_IN, 'true', StorageType.LOCAL);
            this.cachaService.setIsUserLoggedIn(true);
            this.getUserDetailsById(this.userID);
            this.activeModal.close();
            // this.notificationService.notifyMessage(NotificationType.Success, `You're Register Successfully!`);
            const modalRefRegistration = this.modalService.open(RegistrationComponent, {
              centered: true,
              backdrop: 'static',
            });
            modalRefRegistration.componentInstance.isShowCrossbtn = true;
            modalRefRegistration.componentInstance.password = this.loginWithEmailForm.controls['Password'].value;
            //  modalRefRegistration.componentInstance.sourceId = sourceId;
            modalRefRegistration.componentInstance.isUserLogged.subscribe((isUserLogged: any) => {
              if (isUserLogged == true) {
                this.isUserLogged.emit(isUserLogged);
              }
            });
          } else {
            this.notificationService.notifyMessage(NotificationType.Success, `Sorry ! Unable to Register you!`);
          }
        },
        error: (error) => {
          if (error.error === 'Invalid OTP') {
            this.modalRefOTPVerification.componentInstance.isOTPVerfiedFromDB = false;
            //returnValue = 'Invalid OTP';
          } else {
            this.notificationService.notifyMessage(NotificationType.Success, `Sorry! Unable to register you.`);
           // returnValue= 'Error occurred';
          }
        },
      });
    }
  }
  getUserStatus(): any {
    if (this.userID) {
      this.trialService.GetOrder(this.userID).subscribe((data) => {
        if (data.length > 0) {
        } else {
          setTimeout(() => {
            const modalRef = this.modalService.open(TrialUserComponent, {
              centered: true,
              size: 'xl',
              backdrop: 'static',
            });
            modalRef.componentInstance.UserId = this.userID;
            modalRef.componentInstance.GradeId = this.grade;
            // modalRef.componentInstance.GradeId = this.NewGradeId;
          }, 3000);
        }
      });
    }
  }
}
