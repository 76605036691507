import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { NotificationType } from 'src/app/enum/NotificationType';
import { CmsService } from 'src/app/services/cms.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-franchisee',
  templateUrl: './franchisee.component.html',
  styleUrls: ['./franchisee.component.css']
})
export class FranchiseeComponent {
  franchiseeForm!: FormGroup;

  constructor(private http: HttpClient,
    private cmsService: CmsService,
    private notificationService:NotificationService
  ) { }

  ngOnInit(): void {
    this.franchiseeForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      email: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(50)]),
      cell: new FormControl('', [Validators.required, Validators.pattern('^\\d{10}$')]),
      landline: new FormControl('', [Validators.pattern('^\\d{11}$')]),
      address: new FormControl(''),
      query: new FormControl('')
    });
  }

  onSubmit(): void {
    // const franchiseeForm = {
    //   name: this.franchiseeForm.value.name,
    //   email: this.franchiseeForm.value.email,
    //   cell: this.franchiseeForm.value.cell,
    //   landline: this.franchiseeForm.value.landline,
    //   address: this.franchiseeForm.value.address,
    //   query: this.franchiseeForm.value.query
    // };
    const franchiseeForm = {
      QueryID: this.franchiseeForm.value.queryID || '',
      UserName: this.franchiseeForm.value.name,         // Mapped from the form 'name'
      Email: this.franchiseeForm.value.email,           // Mapped from the form 'email'
      CellNo: this.franchiseeForm.value.cell,               // Mapped from the form 'cell'
      LandLineNo: this.franchiseeForm.value.landline || '', // Optional field with default empty string
      UsrAddress: this.franchiseeForm.value.address || '',  // Optional field with default empty string
      QueryText: this.franchiseeForm.value.query || '',     // Optional field with default empty string
     
    };
    this.cmsService.postFranchisee(franchiseeForm).subscribe((message: any) => {
      if(message)
      {
        this.notificationService.notifyMessage(NotificationType.Success, `Thank you!.`);
      }
     else
     {
      this.notificationService.notifyMessage(NotificationType.Warning, `Something went wrong!.`);
     }
    });
  }

  sendFormData(formData: any): Observable<any> {
    // Replace with your API URL
    const apiUrl = '/submit';
    return this.http.post(apiUrl, formData);
  }

}
