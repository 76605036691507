import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecaptchaFormsModule, RecaptchaModule, RecaptchaV3Module } from 'ng-recaptcha';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleRECAPTCHAComponent } from './google-recaptcha.component';
import { SharedModule } from 'src/app/modules/shared.module';


@NgModule({
  declarations: [GoogleRECAPTCHAComponent],
  imports: [
    CommonModule,
    RecaptchaV3Module,
    FormsModule,
    RecaptchaModule,
    ReactiveFormsModule,
    RecaptchaFormsModule,
    SharedModule,

  ],
  exports: [GoogleRECAPTCHAComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})
export class GoogleRECAPTCHAModule { }
