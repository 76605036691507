import { Component, Injectable, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Bookafreedemo } from 'src/app/Models/Bookafreedemo';
import { NotificationType } from 'src/app/enum/NotificationType';
import { BookAFreeDemoService } from 'src/app/services/book-afree-demo.service';
import { NotificationService } from 'src/app/services/notification.service';
import { CourceService } from 'src/app/services/cource.service';
import { GradeModel } from 'src/app/Models/GradeModel';
@Injectable({ providedIn: 'root' })
@Component({
  selector: 'app-free-demo-registration',
  templateUrl: './free-demo-registration.component.html',
  styleUrls: ['./free-demo-registration.component.css', '../../../assets/css/tool.css']
})
export class FreeDemoRegistrationComponent implements OnInit {
  @Input() min: any;
  @Input() UserName!: string;
  @Input() Phone!: string;
  @Input() sourceId!: number;
  selectedGrade!: any;
  topic: any;
  date: any;
  time: any;
  isSubmitted = false;
  mobile: any;
  today!: string;
  responseMessage!: string;
  forms!: FormGroup;
  separateDialCode = true;
  bookFreeDemoData: Bookafreedemo = {};
  submitted = false;
  grades: Array<GradeModel> = [];

  constructor(public activeModal: NgbActiveModal,
    public formBuilder: FormBuilder,
    public bookFreeDemo: BookAFreeDemoService,
    private notificationService: NotificationService,
    private courceService: CourceService,
  ) {

    this.today = new Date().toISOString().slice(0, 10);
  }


  ngOnInit() {
    this.courceService.getAllGrades().subscribe((res) => {
      this.grades = res.sort((x) => x.id).reverse();
    });
    this.forms = this.formBuilder.group(
      {
        selectedGrade: ['12', Validators.required],
        topic: ['', Validators.required],
        date: ['', Validators.required],
        time: ['', Validators.required]
      }
    );
  }
  get f() {
    return this.forms.controls;
  }

  closeModal() {
    this.activeModal.close();
  }

  changeGrade(e: any) {
    this.selectedGrade?.setValue(e.target.value, {
      onlySelf: true,
    });
  }

  onSubmit() {
    this.submitted = true;

    if (this.forms.invalid) {
      return;
    }
    else {
      this.selectedGrade = this.forms.value.selectedGrade;
      this.topic = this.forms.value.topic;
      this.date = this.forms.value.date;
      this.time = this.forms.value.time;

      this.bookFreeDemoData = {
        Name: this.UserName,
        Mobile: this.Phone,
        Email: 'info@askiitians.com',
        Grade: this.selectedGrade,
        Topic: this.topic,
        Date: this.date,
        Time: this.time
      };

      this.bookFreeDemo.bookFreeDemo(this.bookFreeDemoData).subscribe({
        next: (catData) => {
          this.responseMessage = catData;
          if (this.responseMessage == 'Mail Successfully Send') {
            this.notificationService.notifyMessage(NotificationType.Success, `Mail Successfully Send.`);
          }
        },
        error: () => {
          this.notificationService.notifyMessage(NotificationType.Fail, `Fail to Sent Mail.`);
        },
      });
      this.activeModal.close();
    }
  }
}
