import { Injectable } from '@angular/core';
import { ApiCallService } from './api-call.service';
import { endPoints } from '../api-end-points';
import { Observable } from 'rxjs';
import { GradeModel } from '../Models/GradeModel';
@Injectable({
  providedIn: 'root'
})
export class CourceService {

  constructor(private apiService: ApiCallService) { }

  getAllGrades(): Observable<Array<GradeModel>> {

    const params = { '$filter': 'IsActive' };
    return this.apiService.Get(endPoints.GET_ALL_GRADES, params);
  }

  getCourcesByGrades(GradeId: string, TagId?: string): Observable<any> {
    return this.apiService.Get(endPoints.GET_COURCES_BY_GRADE + GradeId);
  }
}
