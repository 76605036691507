
<div class="error-box error-box-{{statusCode}}">

    <div class="number">
        <div class="four"></div>
        <div class="zero swing">
            <div class="nail"></div>
        </div>
        <div class="four"></div>
    </div>

    <p id="msg">
        We are sorry the page requested can not be found
    </p>
    <p id="additionalMsg" style="font-size:medium">
    </p>    
    <a href="/" class="btn">Go to Home</a>
</div>