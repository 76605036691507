import { Pipe, PipeTransform } from '@angular/core';

export type SortOrder = 'asc' | 'desc';

@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {

  transform(value: any[], sortOrder: SortOrder | string = 'asc', sortKey?: string): any[] {
   // console.log('OrderByPipe - Value type:', typeof value); // Check type
   // console.log('OrderByPipe - Value:', value); // Check actual value

    if (!Array.isArray(value)) {
    //  console.error('OrderByPipe: The value provided is not an array.');
      return []; // Return an empty array or handle accordingly
    }

    sortOrder = sortOrder && (sortOrder.toLowerCase() as SortOrder);

    if (sortOrder !== 'asc' && sortOrder !== 'desc') {
     // console.error('OrderByPipe: Invalid sort order. Using default "asc".');
      sortOrder = 'asc';
    }

    let numberArray = [];
    let stringArray = [];

    if (!sortKey) {
      numberArray = value.filter(item => typeof item === 'number').sort((a, b) => a - b);
      stringArray = value.filter(item => typeof item === 'string').sort();
    } else {
      numberArray = value
        .filter(item => typeof item[sortKey] === 'number')
        .sort((a, b) => a[sortKey] - b[sortKey]);

      stringArray = value
        .filter(item => typeof item[sortKey] === 'string')
        .sort((a, b) => {
          if (a[sortKey] < b[sortKey]) return -1;
          else if (a[sortKey] > b[sortKey]) return 1;
          else return 0;
        });
    }

    const sorted = numberArray.concat(stringArray);
    return sortOrder === 'asc' ? sorted : sorted.reverse();
  }
}