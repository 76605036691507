<head>
    <link rel="stylesheet" href="/assets/css/intlTelInput.css" async>
</head>
<div class="login_page">
    <div id="recaptcha-container"></div>
    <section class="register">
        <div class="modal-header close_btn_align">
            <button type="button" class="btn-close  btn-close-black" data-bs-dismiss="modal"
                (click)="closeModal()"></button>
        </div>
        <div class=" position-relative">
            <div class="box">
                <div class=" justify-content-center mb-4 pb-1" *ngIf="SignUpHide">
                    <div class=" user-show-head">Login to askIITians</div>
                </div>

                <div class="justify-content-center mb-4 pb-1" *ngIf="LoginHide">
                    <div class=" user-show-head">Sign Up to askIITians</div>
                </div>
                
                <!-- <form [formGroup]="form" class="px-3">
                    <div id="txtSearchProdAssign" *ngIf="showPhoneInput">
                        <div class="form-group">
                            <label for="name-user" class=" lable-space">Phone Number</label>
                            <app-country-picker name="phone" formControlName="phone"
                                onchange="ischange()"></app-country-picker>
                            <div *ngIf="f['phone'].touched && f['phone'].invalid && !isWritten">
                                <div class="alert alert-danger"
                                    *ngIf="f['phone'].errors && f['phone'].errors['required']">Please Enter Valid Phone
                                    Number.
                                </div>
                            </div>
                            <div class="alert alert-danger" *ngIf="!f['phone'].errors && !isAsperCountry">
                                Please enter valid Mobile Number As per Country code.
                            </div>
                        </div>
                    </div>
                    <div class="mt-3 otp-input" id="show_otp" *ngIf="confirmationResult">
                        <p id="otp_mobile" #hello></p>
                        <label class="mb-1 clr_gray f_600">Enter OTP</label>
                        <div class="input-container d-flex flex-row justify-content-center inputs mb-3"
                            style="display:flex;">
                            <input type="text" class="m-1 text-center form-control  inputs_data" maxlength="1"
                                formControlName="verificationCode1" (keyup)="keyUpEvent($event)" autofocus />
                            <input type="text" class="m-1 text-center form-control inputs_data" maxlength="1"
                                formControlName="verificationCode2" (keyup)="keyUpEvent($event)" />
                            <input type="text" class="m-1 text-center form-control inputs_data" maxlength="1"
                                formControlName="verificationCode3" (keyup)="keyUpEvent($event)" />
                            <input type="text" class="m-1 text-center form-control inputs_data" maxlength="1"
                                formControlName="verificationCode4" (keyup)="keyUpEvent($event)" />
                            <input type="text" class="m-1 text-center form-control inputs_data" maxlength="1"
                                formControlName="verificationCode5" (keyup)="keyUpEvent($event)" />
                            <input type="text" class="m-1 text-center form-control inputs_data" maxlength="1"
                                formControlName="verificationCode6" (keyup)="keyUpEvent($event)" />
                        </div>
                    </div>
                    <div *ngIf="!isOTPVerfied" class="alert alert-danger">
                        <div>Please Enter Valid OTP</div>
                    </div>
                    <div class="resend_otp" *ngIf="confirmationResult" style="line-height: 2">
                        <p>
                            Did Not Received OTP?<a (click)="resendOTP()"> Send OTP Again</a><br>
                            <a (click)="SendToAnotherNumber()"> Send OTP To Another Number</a>
                        </p>
                    </div>
                    <input type="text" name="butAssignProd" placeholder="click here" style="display:none;">
                    <div class="sign_up_hide">
                        <div class="mt-3" *ngIf="LoginHide">
                            <div class="form-group">
                                <label for="name-user" class="lable-space">Name</label>
                                <input type="text" formControlName="name" id="names-user"
                                    placeholder="Type Name Here..." required class="form-control" name="name" />
                                <div *ngIf="f['name'].touched && f['name'].invalid" class="alert alert-danger">
                                    <div *ngIf="f['name'].errors && f['name'].errors['required']">Name is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-3" *ngIf="LoginHide">
                            <div class="form-group">
                                <label for="name-user" class="lable-space">Email</label>
                                <input type="email" id="names-user" placeholder="Type Email Here..." required
                                    class="form-control" name="email" formControlName="email"
                                    pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$" />
                                <div *ngIf="f['email'].touched && f['email'].invalid" class="alert alert-danger">
                                    <div *ngIf="f['email'].errors && f['email'].errors['required']">Email is
                                        required.</div>
                                    <div *ngIf="f['email'].errors &&  f['email'].errors['pattern']" class="text-danger">
                                        Please provide a valid email address</div>
                                </div>
                            </div>

                        </div>

                        <div class="form-group row justify-content-around" *ngIf="LoginHide || isExistGoogleSignIn">
                            <div class="select-grade col-12 px-3">
                                <h2>Select grade of the Student</h2>
                            </div>
                            <div class="col-md-12 col-4 mb-2 gradeSelection mx-3" *ngFor="let items of getGra">
                                <div class="small_device_view">
                                    <input type="radio" name="grade" id="{{items.id}}" value="{{items.id}}"
                                        (click)="getgradeID($event,items.id)" class="form-check-input form-control"
                                        formControlName="grade" required />
                                    <label class="radio-label" for="{{items.name}}">{{items.name}}<sup>th</sup></label>
                                </div>
                                <div *ngIf="f['grade'].touched && f['grade'].invalid" class="alert alert-danger">
                                    <div *ngIf="f['grade'].errors && f['grade'].errors['required']"> Grade is
                                        required.</div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-4 text-center login_submit" *ngIf="LoginHide">
                            <h6 class="m-auto" style="font-weight: 600;"><span id="spnLoginHeading">Already</span>
                                have a account? <a id="closeModal" (click)="LoginBtn()"
                                    class="login_sign_link">Login</a></h6>
                            <button type="submit" class="btn login_btn" *ngIf="SignupbuttonHide" id="closeModal"
                                [disabled]="!f['grade'].valid && !f['email'].valid && !f['name'].valid"
                                (click)="myMethod($event)">SignUp</button>
                            <button type="submit" class="btn login_btn" *ngIf="ValidateSignupbuttonHide" id="closeModal"
                                [disabled]="!f['grade'].valid && !f['email'].valid && !f['name'].valid"
                                (click)="login()">Validate</button>

                        </div>
                    </div>
                    <div class="login_hide" *ngIf="SignUpHide">
                        <div class="mt-4 text-center login_submit">
                            <button type="submit" class="btn login_btn" *ngIf="LoginbuttonHide "
                                [disabled]="!f['phone'].valid" (click)="myMethod($event)">Login</button>
                        </div>
                        <div *ngIf="isGoogleSignIn">
                            <hr />
                            <div class="row">
                                <div class="col-md-12 text-center">
                                    <span class="or-text">OR</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 text-center" style="border-right: 1px solid #f65f12;">
                                    <span id="login-with-email-text"><a (click)="usingEmailBtn()"
                                            class="login_sign_link color-white" id="emailText"> Login
                                            with Email</a></span>
                                </div>
                                <div class="col-md-6 text-center">
                                    <span>
                                        <app-google-sso-login [clientId]="googleClientId"
                                        (onSigninSuccess)="onGoogleSigninSuccess($event)"></app-google-sso-login>
                                    </span>
                                   
                                </div>
                            </div>
                        </div>

                        <div class="mt-4 text-center login_submit">
                            <h6 class="m-auto" style="font-weight: 600;"><span id="spnLoginHeading">Don't</span>
                                have a account? <a (click)="SignupBtn()" class="login_sign_link">Sign up</a></h6>
                            <button type="submit" class="btn login_btn" *ngIf="ValidatebuttonHide"
                                [disabled]="!f['phone'].valid" (click)="login()">Validate</button>
                        </div>


                    </div>

                </form> -->
             

            </div>
        </div>
    </section>
</div>
