export enum SourceIdEnum {
    All = 0,
    Register = 1,
    FreeTrial = 2,
    Forum = 3,
    ContactUs = 4,
    AdminEnquiry = 5,
    FreeDemo = 6,
    CrashCourse = 7,
    TestSeries = 8,
    NRI = 9,
    tbl_askiitians_user = 10,
    UserDetail = 11,
    Login = 12,
    PaidUser = 13,
    BlogUser = 14,
    ExamAlerts = 15,
    RevisionNote = 16,
    CPL = 17,
    Franchisee = 18,
    ReferFriend = 19,
    Exam = 20,
    Marketing = 21,
    RankCalculator = 22,
    AIPMTRankCalculator = 23,
    BITSATRankCalculator = 24,
    JEEAdvanceRankCalculator = 25,
    FBApp = 26,
    JeeMainCollegePredictor = 27,
    BulkEmailRegistration = 28,
    ECommRegistration = 29,
    Resources = 30,
    ExamInfo = 31,
    News = 32,
    Paper = 33,
    Book = 34,
    Coaching = 35,
    colleges = 36,
    Counselling = 37,
    Franchise = 38,
    Expert = 39,
    CoachingAbroad = 40,
    Package = 41,
    Preparationhelp = 42,
    CMSDefault = 43,
    NotifyMe = 44,
    GoogleSignIn = 45,
    AppUser = 46,
    GoogleAdwords = 47,
    MindMap = 48,
    Youtube = 49,
    CrashCoursePopup = 50,
    AppWebPage = 51,
    Banner = 52,
    DPSSchoolQatar = 53,
    MESSchoolQatar = 54,
    TalentDataQatar = 55,
    ICatData = 56,
    OfflineSchoolData = 57,
    CommunityData = 58,
    OneToOne = 59,
    NeetPages = 60,
    PerformanceBatch = 61,
    OrganicPIB = 62,
    JrHackGoogle = 63,
    JrHackSnapchat = 64,
    JuniorHackerOrganic = 65,
    FacebookAdsJrHack = 66,
    PIBLive = 67,
    JrHackTiktok = 68,
    OrganicStickyBottom = 69,
    GoogleNewAdmission = 70,
    GoogleOnlineCounselling = 71,
    JrHackWebinarGoogle = 72,
    TiktokAds = 73,
    WebHome = 74,
    AIOrganic = 75,
    ForumICat = 76,
    CmsMobile = 77,
    Organic_99 = 78
}