import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { UserModel } from 'src/app/Models/UserModel';
import { CacheService } from 'src/app/services/cache.service';
import { ProductService } from 'src/app/services/product.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css'],
})
export class SideNavComponent implements OnInit {
  @Input() childData: boolean | undefined;
  @Input() hideSideNav: boolean | undefined;
  @Input() menu: string | undefined;
  @Output() sendDataEvent = new EventEmitter<boolean>();
  @Output() isSideNavClicked!: boolean;
  @Output() sideNav = new EventEmitter<boolean>();
  // @Output() isSideNavClicked = new EventEmitter<boolean>();
  expandMenu: boolean = false;
  expandWithoutMenu: boolean = false;
  userDetails!: UserModel;
  classLink: string = '';
  testLink: string = '';
  productData: any;
  isPackage: boolean = false;
  Appgradeid: any;
  IsLogin: boolean = false;
  userDetailsSubscribe: any;
  expandSubscribe: any;
  constructor(
    private cacheService: CacheService,
    private userService: UserService,
    private productService: ProductService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.expandSubscribe = this.cacheService._expandUserMenu.subscribe(res => {
      this.expandMenu = res;
      //
      this.userDetailsSubscribe = this.cacheService._userDetails.subscribe((res) => {
        this.IsLogin = res != null;
        if (res != null) {
          this.userDetails = res;
          // this.getUserLinks();
          this.getUserOrders();
        }
      });

    });

    this.cacheService._closeSideNavigation.subscribe(res => {
      this.expandMenu = res;
    });
  }

  getEdmingleToken(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.userService.GetEdmingleClassLink(this.userDetails?.name, this.userDetails?.email, '')
        .subscribe(
          (tokenResponse) => {
            resolve(tokenResponse); // Return the response
          },
          (error) => {
            console.error('Error fetching user links:', error);
            reject(error); // Handle errors
          }
        );
    });
  }
  getUserLinks() {
    this.userService.GetEdmingleClassLink(this.userDetails?.name, this.userDetails?.email, '').subscribe((res) => {
      this.classLink = res;
    });

    this.userService.GetTestSeriesLink(this.userDetails.id.toString()).subscribe((res) => {
      this.testLink = res;
    });
  }

  getUserOrders() {
    this.productService.getOrderFromUserID(this.userDetails.id).subscribe((data) => {
      this.productData = data.items;
      for (const item of this.productData) {
        if (
          item.userId === this.userDetails.id && item.transactionId > 0 &&
          item.packagedetail && item.packagedetail.length > 0 // Check if packagedetail exists and has at least one element
        ) {
          this.isPackage = true;
          this.Appgradeid = item.packagedetail[0].gradeid;
        }
      }
    });
  }


  gotoMyResources() {
    this.router.navigate(['resources-page-component']);
  }

  sendDataToParent() {
    this.expandMenu = false;
    this.sendDataEvent.emit(false);
    this.sideNav.emit(false);
  }
  ngOnDestroy() {
    this.userDetailsSubscribe.unsubscribe();
    this.expandSubscribe.unsubscribe();
  }

  getClassLink() {
    this.getEdmingleToken()
      .then((token) => {
        if (token) {
          const a = document.createElement('a');
          a.href = `https://live.askiitians.com/?jwt=${token}`;
          a.target = '_blank';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a); // Clean up
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }
  getTestSeriesLimk() {
    this.userService.GetTestSeriesLink(this.userDetails.id.toString()).subscribe((res) => {
      this.testLink = res;
      if (this.testLink) {
        const a = document.createElement('a');
        a.href = this.testLink;
        a.target = '_blank';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a); // Clean up
      }
    });
  }
}
