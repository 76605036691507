import { CountryCodeModel } from "../Models/CountryCode.Model";

declare let libphonenumber: any;
export const phoneNumberValidator = (control: any) => {
    if (!control) {
        return false;
    }
    const code = control.split('-')[0];
    const phoneNumber = control.split('-')[1];

    if (!libphonenumber.isValidNumber(phoneNumber, CountryCodeModel.filter(x => x.code == code)[0].country_code)) {
        return false;
    }
    else {
        return true;
    }
}