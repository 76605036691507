<div class="read_answer_question-section">
    <div class="right-1 d-none d-lg-block hover-button" *ngIf="fromMyResourcehide">
            <div class="img">
                <div>
                    <img src="/assets/images/resourse-img/star.png" loading="lazy" alt="star" width="22px" height="22px" class="img-fluid" />
                </div>
                <h5>LIVE ONLINE CLASSES</h5>
            </div>
            <p>
                Prepraring for the competition made easy just by live online
                class.
            </p>
            <div class="img onClassBlock">
                <div class="OCFeature">
                    <img src="/assets/images/resourse-img/tv.png" loading="lazy" alt="tv" width="24px" height="24px" class="img-fluid" />
                </div>
                <p>Full Live Access</p>
            </div>
            <div class="img onClassBlock">
                <div class="OCFeature">
                    <img src="/assets/images/resourse-img/metarial.png" loading="lazy" alt="material" width="24px" height="24px" class="img-fluid" />
                </div>
                <p>Study Material</p>
            </div>
            <div class="img onClassBlock">
                <div class="OCFeature">
                    <img src="/assets/images/resourse-img/doubt removal.png" loading="lazy"  alt="removal" width="24px" height="24px" class="img-fluid" />
                </div>
                <p>Live Doubts Solving</p>
            </div>
            <div class="img onClassBlock">
                <div class="OCFeature">
                    <img src="/assets/images/resourse-img/assignment.png" loading="lazy" alt="assignment" width="24px" height="24px" class="img-fluid" />
                </div>
                <p>Daily Class Assignments</p>
            </div>
            <button class="btn-1 w-100" *ngIf="!canBuyPackage" [disabled]="startFreeTrial" (click)="openFreeTrial();">Start your free trial</button>
            <br />
            <button class="btn-2 w-100" *ngIf="canBuyPackage" [disabled]="buyNow" (click)="buyNewPackage(); buyNow = true;">Buy Now</button>
    </div>

    <div class="right-2" *ngIf="isVisible">
        <ng-container *ngFor="let slide of resourceVideoData">
            <owl-carousel-o [options]="customOptions">
                <ng-template class="slide" carouselSlide *ngFor="let cd of slide.subtopicLinks">
                    <div class="icon_background">
                    <div class="img">
                        <div class="d-flex justify-content-center">
                            <div *ngIf="cd.thumbnailUrl == null ; else elseThumb">
                                <a
                                    (click)="openVideoToParent(cd.thumbnailUrl,cd.link, slide.accessStatus, cd.subscription)">
                                    <!-- <input type="hidden" id="h1" value="{{slide.accessStatus}}" />
                                    <input type="hidden" id="h2" value="{{cd.subscription}}" /> -->
                                    <div
                                        [ngClass]="[ cd.subscription == 'Free' || slide.accessStatus == 'TrialRunning' || slide.accessStatus == 'PackagePurchase' ? iconwithoutBg : iconBg  ]">
                                        <img loading="lazy" [src]="cd.subscription == 'Free' || slide.accessStatus == 'TrialRunning' || slide.accessStatus == 'PackagePurchase' ? myvideoImgUrl : mylockImgUrl"
                                            alt="image" class="img-fluid w-auto set_icon_postion" />
                                    </div>
                                    <img loading="lazy" [ngClass]="[ cd.subscription == 'Free' || slide.accessStatus == 'TrialRunning' || slide.accessStatus == 'PackagePurchase' ? itemCLass : lockClass ]"
                                        src="{{cd.thumbnailUrl}}" alt="image" />
                                </a>
                            </div>
                            <ng-template #elseThumb>
                                <a (click)="openVideoToParent(cd.thumbnailUrl,cd.link, slide.accessStatus)"
                                    class="icon" title="video link">
                                    <div
                                        [ngClass]="[ cd.subscription == 'Free' || slide.accessStatus == 'TrialRunning' || slide.accessStatus == 'PackagePurchase' ? iconwithoutBg : iconBg  ]">
                                        <img loading="lazy" [src]="cd.subscription == 'Free' || slide.accessStatus == 'TrialRunning' || slide.accessStatus == 'PackagePurchase' ? myvideoImgUrl : mylockImgUrl"
                                            alt="image" class="img-fluid w-auto set_icon_postion" />
                                    </div>
                                    <img loading="lazy" src="/assets/images/resource/resourse-doubt/video.png" alt="image"
                                        class="img-fluid video_height" style=" height: 126px;" />
                                </a>
                            </ng-template>


                        </div>
                        <div class="text ">
                            <div>
                                <span class="dot_data_video" style="padding-top:0px;">{{cd.title}}</span>
                                <p class="dot_data_video_desc">{{cd.description}}</p>
                                <span class="dot_data_video" style="padding-bottom:0px;">{{cd.tutorName}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                </ng-template>
            </owl-carousel-o>
        </ng-container>

    </div>

    <div class="right-3" *ngIf="forum_page_right" >
        <h4>Ask a Doubt</h4>
        <p>Get your questions answered by the expert for free</p>
        <div class="textarea">
            <angular-editor [(ngModel)]="htmlContent" [config]="config"></angular-editor>
        </div>
        <button class="w-100 btn-3 F" [class.changestyle]="check" [disabled]="isButtonDisabled" (click)="GetData()">GET ANSWER</button>
    </div>
</div>    
