import { Injectable } from '@angular/core';
import { ApiCallService } from './api-call.service';
import { Observable } from 'rxjs';
import { endPoints } from '../api-end-points';
import { ProductModel } from '../Models/ProductModel';
import { ProductPackage } from '../Models/ProductPackageModel';
import { Transaction } from '../Models/TransactionModel';
import { PackageOrder } from '../Models/PackageOrder';
import { TempPackage } from '../Models/TempPackageModel';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(private apiService: ApiCallService) { }

  getProductDetails(id: string): Observable<ProductModel> {
    return this.apiService.Get(endPoints.GET_PRODUCT_DETAILS + id);
  }

  getProductDetailsByUrl(Name: string): Observable<ProductModel> {
    const params = { url: `${Name}` };
    return this.apiService.Get(endPoints.GET_PRODUCT_DETAILS_BY_URL+'/'+Name);
  }

  getOrderFromUserID(userid: number): Observable<any> {
    const params = { UserId: userid };
    return this.apiService.Get(endPoints.GET_ORDER_BY_USERID, params);
  }

  getTrialDetail(userId: number): Observable<any> {
    return this.apiService.Get(endPoints.GET_TRIAL_DETAILS + userId);
  }

  createOrder(post: ProductPackage): Observable<any> {
    return this.apiService.Post(endPoints.PACKAGE_ORDER, JSON.stringify(post));
  }

  createTemppackageorderdetails(post: TempPackage): Observable<any> {
    return this.apiService.Post(endPoints.CREATE_TEMP_PACKAGE, JSON.stringify(post));
  }

  getProductFromID(Id: number): Observable<any> {
    return this.apiService.Get(endPoints.GET_PRODUCT_FROM_ID + '/' + Id);
  }

  EditOrder(PUT: ProductPackage, id: number): Observable<any> {
    return this.apiService.Put(endPoints.PACKAGE_ORDER + '/' + id, JSON.stringify(PUT));
  }

  createTransactionresponse(post: Transaction): Observable<any> {
    return this.apiService.Post(endPoints.CREATE_TRANSACTION, JSON.stringify(post));
  }

  createTpackageOrderDetail(post: PackageOrder): Observable<any> {
    return this.apiService.Post(endPoints.PACKAGE_ORDER_DETAILS, JSON.stringify(post));
  }
  getCoursesByPageCategory(): Observable<any>
  {
   // return this.apiService.Get(endPoints.GET_COURSES_BY_PAGE_CATEGORY);
    return this.apiService.GetJson('assets/data/homePackages.json');
  }
}
