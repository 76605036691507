import { Injectable } from '@angular/core';
import { Observable} from 'rxjs'; 
import { environment } from 'src/environments/environment';
import { ApiCallService } from './api-call.service';
import { endPoints } from '../api-end-points';


@Injectable({
  providedIn: 'root',
})
export class SendOTPMessageService {
  private smsFactorkey: string = environment.sms_factorkey;
  private baseUrl: string = `https://2factor.in/API/V1`;

  constructor(private apiService: ApiCallService) {}

  sendOTP(phoneNumber: string): Observable<any> {
    const smsRequest = { PhoneNumber: phoneNumber};
    return this.apiService.Post(endPoints.SEND_OTP, smsRequest);
  }
}
