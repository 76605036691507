import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationType } from 'src/app/enum/NotificationType';
import { UserModel } from 'src/app/Models/UserModel';
import { CacheService } from 'src/app/services/cache.service';
import { NotificationService } from 'src/app/services/notification.service';
import { TrialService } from 'src/app/services/trial.service';
import { RightBlockComponent } from 'src/app/Components/right-block/right-block.component';

@Component({
  selector: 'app-trial-user',
  templateUrl: './trial-user.component.html',
  styleUrls: ['./trial-user.component.css'],
})
export class TrialUserComponent implements OnInit {
  @Output() isUserInTrial: EventEmitter<any> = new EventEmitter();
  @Input() GradeId!: string;
  isShow: boolean = false;
  PackageDetails: any;
  Package: any;
  PackageOrder: {} | undefined;
  PackageOrderDetails!: {};
  PackageId!: number;
  OrderId!: any;
  OId!: number;
  @Input() sourceId!: number;
  cacheSubscribe: any;
  userDetails!: UserModel;
  UserId!: number;
  show!: boolean;
  orderId!:number;
  constructor(
    private activeModal: NgbActiveModal,
    private trialService: TrialService,
    private cacheService: CacheService,
    private datePipe: DatePipe,
    private rightBlockComponent: RightBlockComponent,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.cacheSubscribe = this.cacheService._userDetails.subscribe((res) => {
      if (res != null) {
        this.userDetails = res;
        this.UserId = this.userDetails.id;
        this.GradeId = this.userDetails.userdetails.grade;
        this.getUserStatus();
      }
    });


  }
  // ngOnDestroy() {
  //   this.cacheSubscribe.unsubscribe();
  // }
  getPackageDetails() {
    this.trialService.GetPackageDetails().subscribe((data) => {
      if (data)
        {
            this.PackageDetails = data;
            this.Package = this.PackageDetails.filter(
              (item: { gradeId: number; packageName: string }) =>
                item.packageName.includes('Trial') && item.gradeId == parseInt(this.GradeId)
            );
             this.PackageId = this.Package[0]?.id;
             this.orderPackage(this.Package);
        }
    });
  }

  orderPackage(Package: any) {
    this.PackageOrder = {
      isValid: true,
      userId: this.userDetails?.id,
      billingAddressId: 0,
      shippingAddressId: 0,
      transactionId: 0,
      currency: '$',
      amount: Package[0].price,
      status: 'inprogress',
      couponId: 0,
      discountAmount: 0,
      discountUSDAmount: 0,
      createdDateTime: this.transformDate(new Date()),
      lastModified: this.transformDate(new Date()),
      temppackageorderdetail: [{
        isValid: true,
        id: 0,
        userId: this.userDetails.id,
        entityType: 'TrialPackage',
        entityId: this.PackageId,
      }],
    };

    this.trialService.OrderPackage(this.PackageOrder).subscribe((data) => {
      if(data)
      {
        this.orderId = data.id;
     
      this.setPackageOrderDetails();
      }
    });
  }

  setPackageOrderDetails() {
        this.cacheService.SetisTrialUser(true);
        this.isUserInTrial.emit(this.orderId)
        this.notificationService.notifyMessage(NotificationType.Success, `You have started your free trial!`);
    
      this.isShow = true;
   
  }

  transformDate(date: string | number | Date) {
    return this.datePipe.transform(date, "yyyy-MM-ddThh:mm:ss.SSS'Z'");
  }

  GoToStudyPod() {
    this.activeModal.close();
    this.rightBlockComponent.getUserStatus();
  }

  closeModal() {
    this.activeModal.close();
  }
  getUserStatus() {
    if (this.UserId) {
      this.trialService.GetOrder(this.UserId).subscribe((data) => {
        if (data.length > 0) {
          this.isShow = true;
          this.show = true;
          this.cacheService.SetisTrialUser(true);
        }
        else {
          this.isShow = false;
          this.show = true;
          this.cacheService.SetisTrialUser(false);
        }
      });
    }
  }
}
