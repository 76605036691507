import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
declare global {
  interface Window {
    bootstrap: any;
  }
}
@Component({
  selector: 'app-icat',
  templateUrl: './icat.component.html',
  styleUrls: ['./icat.component.css']
})
export class IcatComponent implements OnInit {
  @ViewChild('dwnldPaperFinal') dwnldPaperFinal!: ElementRef<HTMLAnchorElement> | undefined;
  registrationForm!: FormGroup;
  studentForm!: FormGroup;
  schoolForm!: FormGroup;
  addressForm!: FormGroup;
  modalOpen: boolean = false;
  selectedGrade: string | null = null;
  url: string = '';
  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.registrationForm = this.fb.group({
      grade: ['', Validators.required],
      country: ['', Validators.required],
      city: [''],
      timing: ['']
    });

    this.studentForm = this.fb.group({
      fullName: ['', [Validators.required, Validators.minLength(3)]],
      phoneNumber: ['', [Validators.required, Validators.pattern('^[+][0-9]{1,3}[ ]?([0-9]{10})$')]],
      email: ['', [Validators.required, Validators.email]]
    });

    this.schoolForm = this.fb.group({
      country: ['Select', [Validators.required]],
      schoolName: ['', [Validators.required, Validators.minLength(3)]],
      personName: ['', [Validators.required, Validators.minLength(3)]],
      designation: ['', [Validators.required]]
    });

    this.addressForm = this.fb.group({
      schoolAddress: ['', [Validators.required, Validators.minLength(5)]],
      phoneNumber: ['', [Validators.required, Validators.pattern(/^\+?\d{1,4}?\s?-?\(?\d{1,3}?\)?\s?-?\d{1,4}?\s?-?\d{1,4}?\s?-?\d{1,4}$/)]],
      schoolEmail: ['', [Validators.required, Validators.email]]
    });

  }
  grades = ['6', '7', '8', '9', '10', '11', '12', '12th pass'];
  showModal = false;
  selectedCountry!: string;
  openModal() {
     
    this.showModal = true;
    const modalElement = document.getElementById('registernow_flow');
    if (modalElement) {
      const modal = new window.bootstrap.Modal(modalElement);
      modal.show();
    }
  }

  closeModal() {
    this.showModal = false;
    // Optionally, you can still hide the modal manually if needed
    const modalElement = document.getElementById('registernow_flow');
    if (modalElement) {
      modalElement.classList.remove('show');
      modalElement.style.display = 'none';
      document.body.classList.remove('modal-open'); // Removes modal overlay
    }
  }

  openModalNext(event: any) {
     
    const buttonId = event.target.id; // Get the ID of the clicked button
    console.log('Button ID clicked:', buttonId);

    // Logic to show the next modal
    const modalId = this.getModalId(buttonId); // Get the modal ID based on button ID
    const modalElement = document.getElementById(modalId);
    if (modalElement) {
      modalElement.classList.remove('none');
      modalElement.style.display = 'show';
      const modal = new window.bootstrap.Modal(modalElement);
      modal.show();
    }
  }
  getModalId(buttonId: string): string {
    switch (buttonId) {
      case "studentFrmNext1":
        return 'content2';
      case "studentFrmNext2":
        return 'content3';
      case "dwnldIcatPaper":
        return "download_icat_paper";
      default:
        return '';
    }
  }
  onSubmitRegistrationForm() {
     
    const formData = this.registrationForm.value;
    console.log('Form Submitted:', formData);
  }

  onSubmitStudentForm() {
    const formData = this.studentForm.value;
    console.log('Form Submitted:', formData);
  }

  onSubmitSchoolForm() {
    const formData = this.schoolForm.value;
    console.log('Form Submitted:', formData);
  }

  onSubmitAddressForm() {
    const formData = this.addressForm.value;
    console.log('Form Submitted:', formData);
  }

  selectGrade(grade: string) {
     
    this.selectedGrade = grade;
  }

  downloadPaper() {
    if (this.selectedGrade) {
      console.log('Downloading paper for grade: ' + this.selectedGrade);

      switch (this.selectedGrade) {
        case '6':
          this.url = 'https://files.askiitians.com/static_content/Resources/icat/Sample Paper/ICAT (6TH).pdf';
          break;
        case '7':
          this.url = 'https://files.askiitians.com/static_content/Resources/icat/Sample Paper/ICAT (7TH).pdf';
          break;
        case '8':
          this.url = 'https://files.askiitians.com/static_content/Resources/icat/Sample Paper/ICAT (8th).pdf';
          break;
        case '9':
          this.url = 'https://files.askiitians.com/static_content/Resources/icat/Sample Paper/ICAT (9th).pdf';
          break;
        case '10':
          this.url = 'https://files.askiitians.com/static_content/Resources/icat/Sample Paper/ICAT (10th).pdf';
          break;
        case '11':
          this.url = 'https://files.askiitians.com/static_content/Resources/icat/Sample Paper/ICAT (11th M).pdf';
          break;
        default:
          //show incvalide grade
          // this.showModalPopup('Invalid Grade Selected');
          return;
      }
      // Replace with actual download logic, like calling an API or navigating to a URL
    }
    if (this.url) {
      const downloadLink = this.dwnldPaperFinal?.nativeElement;
      if (downloadLink) {
        window.open(this.url);

        // Set the URL for download
        // downloadLink.href = this.url;
        // downloadLink.target = '_blank';
        // downloadLink.download = ''; // Trigger download behavior

        // // Automatically trigger the click to start the download
        // downloadLink.click();
        //  
      }
    }
  }
  selectCity(city: string) {
    this.registrationForm.controls['city'].setValue(city);
  }

  // This method can be used to handle country selection
  onCountryChange(country: string) {
    this.selectedCountry = country;
  }


}
