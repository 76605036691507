import { Injectable } from '@angular/core';
import { ApiCallService } from './api-call.service';
import { endPoints } from '../api-end-points';
import { Observable } from 'rxjs';
import { ProductPackage } from '../Models/ProductPackageModel';
import { TempPackage } from '../Models/TempPackageModel';

@Injectable({
  providedIn: 'root',
})
export class TrialService {
  constructor(private apiService: ApiCallService) { }

  GetPackageDetails(): Observable<any> {
    return this.apiService.Get(endPoints.GET_PACKAGE_DETAILS);
  }

  OrderPackage(packageToOrder: ProductPackage): Observable<any> {
    return this.apiService.Post(endPoints.PACKAGE_ORDER, JSON.stringify(packageToOrder));
  }

  SetPackageOrderDetails(post: TempPackage): Observable<any> {
    return this.apiService.Post(endPoints.PACKAGE_ORDER_DETAILS, JSON.stringify(post));
  }

  GetOrder(userid: number): Observable<any> {
    return this.apiService.Get(endPoints.GET_TRIAL_DETAILS + userid);
  }
}
