import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SelectedObjectModel } from '../Models/SelectedObjectModel';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  private resourcePageChange: BehaviorSubject<SelectedObjectModel> = new BehaviorSubject<SelectedObjectModel>({ selectedGrade: '' } as SelectedObjectModel);
  _resourcePageChange = this.resourcePageChange.asObservable();

  private userDetails: BehaviorSubject<any> = new BehaviorSubject<any>(null as any);
  _userDetails = this.userDetails.asObservable();

  private expandUserMenu: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  _expandUserMenu = this.expandUserMenu.asObservable();

  private topicId: BehaviorSubject<string> = new BehaviorSubject<string>('');
  _topicId = this.topicId.asObservable();

  private isVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  _isVisible = this.isVisible.asObservable();

  private resourceData: BehaviorSubject<any> = new BehaviorSubject<boolean>(false);
  _resourceData = this.resourceData.asObservable();

  private purchasedPackaged: BehaviorSubject<any> = new BehaviorSubject<any>('');
  _purchasedPackaged = this.purchasedPackaged.asObservable();

  private cmsPackageId: BehaviorSubject<any> = new BehaviorSubject<any>('');
  _cmsPackageId = this.cmsPackageId.asObservable();

  private User: BehaviorSubject<any> = new BehaviorSubject<any>('');
  _USer = this.User.asObservable();

  private hashURL: BehaviorSubject<any> = new BehaviorSubject<any>('');
  _hashURL = this.hashURL.asObservable();

  private isCMSPage: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  _isCMSPage = this.isCMSPage.asObservable();

  private isBlogPage: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  _isBlogPage = this.isBlogPage.asObservable();

  private isQuestionPost: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  _isQuestionPost = this.isQuestionPost.asObservable();

  private isUserLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  _isUserLoggedIn = this.isUserLoggedIn.asObservable();

  private ContentToBeSearch: BehaviorSubject<any> = new BehaviorSubject<any>('');
  _setContentToBeSearch = this.ContentToBeSearch.asObservable();

  private cmsPageRedirectionURL: BehaviorSubject<any> = new BehaviorSubject<any>('');
  _cmsPageRedirectionURL = this.cmsPageRedirectionURL.asObservable();

  private isSatPage: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  _isSatpage = this.isSatPage.asObservable();

  private isTrialUser: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  _isTrialUser = this.isTrialUser.asObservable();

  private toCloseSideNavigation: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  _closeSideNavigation = this.toCloseSideNavigation.asObservable();

  constructor() { }

  onResourcePageChange(selectedObj: SelectedObjectModel) {
    this.resourcePageChange.next(selectedObj);
  }

  onUserDetailsChange(user?: any) {
    this.userDetails.next(user);
  }

  onExpandUserMenu(isExpand: boolean) {

    this.expandUserMenu.next(isExpand);
  }
  onSetTopic(topicId: string) {
    this.topicId.next(topicId);
  }
  setVideoDisable(isvisible: boolean) {
    this.isVisible.next(isvisible);
  }

  setResourceData(resourceData: any) {
    this.resourceData.next(resourceData);
  }

  setPurchasedPackage(purchasedPackaged: any) {
    this.purchasedPackaged.next(purchasedPackaged);
  }
  setCMSPackageId(cmsPackageId: any) {
    this.cmsPackageId.next(cmsPackageId);
  }

  setUSer(User: any) {
    this.User.next(User);
  }

  setHashURL(hasURL: any): any {
    this.hashURL.next(hasURL);
  }

  setIsCMSPage(isCMSPage: any) {
    this.isCMSPage.next(isCMSPage);
  }
  setIsBlogPage(isBlogPage: any) {
    this.isBlogPage.next(isBlogPage);
  }
  setIsQuestionPost(isQuestionPost: any) {
    this.isQuestionPost.next(isQuestionPost);
  }
  setContentToBeSearch(constentToBeSearch: any) {
    this.ContentToBeSearch.next(constentToBeSearch);
  }
  setIsUserLoggedIn(isUserLoggedIn: boolean) {
    this.isUserLoggedIn.next(isUserLoggedIn);
  }

  SetisSatPage(isSatPage: boolean) {
    this.isSatPage.next(isSatPage);
  }

  SetisTrialUser(isTrialUser: boolean) {
    this.isTrialUser.next(isTrialUser);
  }

  closeSideNavigation(closeSideNavigation: boolean) {
    this.toCloseSideNavigation.next(closeSideNavigation);
  }

}
