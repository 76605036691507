<section class="contact-Us-block">
   <div class="container">
      <div class="row">
         <div class="col-md-5">
            <h2>Contact Us</h2>
            <p>For quicker assistance, please also provide us with your phone number. If you are a registered Askiitians.com student, please include your login name.<br>
               For any study, service related query , please contact <strong><a href="mailto:info@askiitians.com"> info@askiitians.com</a></strong>.
            </p>
            
            <div class="addesss">
            <h4>Address:</h4>
            <p><strong>AskiiTians.com</strong><br>
            <span>C/O Transweb</span><br>
               B-147, Sector-6, Noida - 201301<br>
               Phone No. <a href="tel:+917353221155">+91-7353-221-155</a>
            </p>
            </div>
         </div>

         <div class="col-md-7">
            <div class="ContactFormfields wite-box" [formGroup]="contactForm">
               <!-- Name Input -->
               <input
                 formControlName="name"
                 type="text"
                 maxlength="50"
                 id="txtName"
                 class="txtbox name"
                 placeholder="Name"
               />
               <div *ngIf="contactForm.get('name')?.invalid && contactForm.get('name')?.touched">
                 <small *ngIf="contactForm.get('name')?.errors?.['required']">Name is required</small>
                 <small *ngIf="contactForm.get('name')?.errors?.['pattern']">Invalid Name</small>
               </div>
             
               <!-- Email Input -->
               <input
                 formControlName="email"
                 type="email"
                 maxlength="50"
                 id="txtEmail"
                 class="txtbox email"
                 placeholder="Email Id"
               />
               <div *ngIf="contactForm.get('email')?.invalid && contactForm.get('email')?.touched">
                 <small *ngIf="contactForm.get('email')?.errors?.['required']">Email is required</small>
                 <small *ngIf="contactForm.get('email')?.errors?.['email']">Invalid Email</small>
               </div>
             
               <!-- Mobile Input -->
               <div class="PhnNumberBlock">
                 <div >
                   <app-country-picker name="PhoneNo" formControlName="PhoneNo"></app-country-picker>
                 </div>
               </div>
             
               <!-- Exam Dropdown -->
               <select formControlName="exam" id="drpExam" class="exam">
                 <option value="" disabled>--Exam--</option>
                 <option *ngFor="let exam of exams" [value]="exam">{{ exam }}</option>
               </select>

               <div class="bottomFields">
                  <!-- Grade Dropdown -->
                  <select formControlName="grade" id="drpStudentGrade" class="grade">
                  <option value="" disabled>Select Grade</option>
                  <option *ngFor="let grade of grades" [value]="grade">{{ grade }}</option>
                  </select>
               
                  <!-- Target Year Dropdown -->
                  <select formControlName="targetYear" id="ddlTargetYear" class="targetyear">
                  <option value="" disabled>--Select--</option>
                  <option *ngFor="let year of targetYears" [value]="year">{{ year }}</option>
                  </select>

               </div> 
             
               <!-- Submit Button -->
               <button type="button" (click)="onSubmit()" class="contactdflt-btn">
                 Submit
               </button>
             
               <!-- Error Message Display -->
               <div class="notify contact">
                 <span id="litErrorMsg">{{ errorMessage }}</span>
               </div>
             </div>
         </div>
      </div>
   </div>
</section>
  