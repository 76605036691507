import { DatePipe, isPlatformBrowser } from '@angular/common';
import { Component, EventEmitter, Inject, Injectable, Input, OnInit, Output, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { UserModel } from 'src/app/Models/UserModel';
import { FreeTrialNotifyComponent } from 'src/app/popupModel/free-trial-notify/free-trial-notify.component';
import { LoginWithEmailComponent } from 'src/app/popupModel/login-with-email/login-with-email.component';
import { LoginComponent } from 'src/app/popupModel/login/login.component';
import { ResourceVideoViewComponent } from 'src/app/popupModel/resource-video-view/resource-video-view.component';
import { TrialUserComponent } from 'src/app/popupModel/trial-user/trial-user.component';
import { CacheService } from 'src/app/services/cache.service';
import { ResourceService } from 'src/app/services/resource.service';
import { TrialService } from 'src/app/services/trial.service';

@Component({
  selector: 'app-right-block',
  templateUrl: './right-block.component.html',
  styleUrls: ['./right-block.component.css'],
  encapsulation: ViewEncapsulation.None,
})

@Injectable({
  providedIn: 'root'
})

export class RightBlockComponent implements OnInit {
  @Input() fromMyResourcehide!: boolean;
  @Input() NewGradeId: string = '';
  @Input() NewSubjectId: string = '';
  @Input() NewResourceId: string = '';
  @Input() forum_page_right: boolean = false;
  @Input() isInTrialUser!: boolean;

  @Output() NewGrade = new EventEmitter<string>();
  @Output() NewSubject = new EventEmitter<string>();
  @Output() NewResource = new EventEmitter<string>();
  @Output() NewTopic = new EventEmitter<string>();
  @Output() data = new EventEmitter<any>();
  isVisible: boolean = false;
  resourceVideoData: any;
  imgSrc: string = '';
  mylockImgUrl: string = 'assets/images/Icon/lock.png';
  myvideoImgUrl: string = 'assets/images/Icon/viddeo_icon.png';
  htmlContent: string = '';
  itemCLass: string = 'img-fluid video_height';
  lockClass: string = 'lock_opacity img-fluid video_height';
  iconBg: string = 'icon_background';
  iconwithoutBg: string = '';
  userDetails!: UserModel;
  UserId!: number;
  OrderDetails: any;
  freeTrialStatus: boolean = false;
  isTrialPending: boolean = false;
  isTrialExpired: boolean = false;
  isPackageExpired: boolean = false;
  isTrialUser: boolean = false;
  canBuyPackage = false;
  NewTopicId: string = '';
  resouresecanBuyPackage = false;
  startFreeTrial = false;
  buyNow = false;
  cacheSubscribe: any;
  resourceSubscribe: any;
  visibleSubscribe: any;
  topicSubscribe: any;
  selectedObject: any = {
    selectedGrade: '',
    selectedResource: '',
    selectedSubject: ''
  }
  isButtonDisabled: boolean = true;
  check: boolean = true;
  startTime!: number;
  endTime!: number;
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '10rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      [
        'undo',
        'redo',
        'subscript',
        'superscript',
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        'indent',
        'outdent',
        'heading',
        'fontName',
        'clearFormatting',
        'link',
        'toggleEditorMode'
      ],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'unlink',
        'insertImage',
        'insertVideo',
        'removeFormat',
      ],
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };

  constructor(
    private cacheService: CacheService,
    private modalService: NgbModal,
    private datePipe: DatePipe,
    private trialService: TrialService,
    private router: Router,
    private resourceService: ResourceService,
    @Inject(PLATFORM_ID) private platformId: any
  ) { }

  ngOnInit() {

    this.cacheService._userDetails.subscribe((res) => {
      if (res != null) {
        this.userDetails = res;
        this.UserId = this.userDetails.id;
      }
    });

    this.cacheService._resourcePageChange.subscribe((res) => {
      this.NewResourceId = res.selectedResource;
    });
    this.cacheService._isTrialUser.subscribe((res) => {
      if (res == true) {
        this.canBuyPackage = true;
      }
    });

    this.getUserStatus();

    this.cacheService._isVisible.subscribe((isVisibleVideo) => {
      this.isVisible = isVisibleVideo;
    });

    this.cacheService._topicId.subscribe((topicId) => {
      this.NewTopicId = topicId;
      if (this.NewResourceId && this.NewResourceId != '1') {
        if (isPlatformBrowser(this.platformId)) {
          this.getVideoData(this.NewGradeId, this.NewSubjectId, this.NewResourceId, this.NewTopicId);
        }
      }
      if (this.NewResourceId == '1') {
        this.isVisible = false;
      }
    });
  }
  // ngOnDestroy() {
  //   this.cacheSubscribe.unsubscribe();
  //   this.resourceSubscribe.unsubscribe();
  //   this.visibleSubscribe.unsubscribe();
  //   this.topicSubscribe.unsubscribe();
  // }

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    // navText: ['<img src="/assets/images/owl-carousel-assets/left-arrow-slider.svg" class="left_indicator_custom"  >', '<img src="/assets/images/owl-carousel-assets/right-arrow-slider.svg" class="right_indicator_custom" >'],
    responsive: {
      0: {
        items: 1,
      },
      300: {
        items: 1,
      },
      400: {
        items: 1,
      },
      500: {
        items: 1,
      },
      740: {
        items: 1,
      },
      940: {
        items: 1,
      },
      1024: {
        items: 1,
      },
    },
    nav: true,
  };

  openFreeTrial() {
    if (this.UserId) {
      this.trialService.GetOrder(this.UserId).subscribe((data) => {
        this.OrderDetails = data;
        if (this.OrderDetails.length > 0) {
          if (this.OrderDetails[0].accessstatus == 'TrialRunning') {
            const modalRef = this.modalService.open(FreeTrialNotifyComponent, { size: 'xl', centered: true, backdrop: 'static' });
            this.canBuyPackage = false;
            modalRef.componentInstance.isUserInTrial.subscribe((isUserInTrial: boolean) => {
              this.canBuyPackage = true;
            });
            modalRef.componentInstance.FreeTrialStatus = this.freeTrialStatus;
          } else if (this.OrderDetails[0].accessstatus == 'TrialExpired') {
            this.freeTrialStatus = true;
            this.canBuyPackage = true;
            const modalRef = this.modalService.open(FreeTrialNotifyComponent, { size: 'xl', centered: true, backdrop: 'static' });
            modalRef.componentInstance.FreeTrialStatus = this.freeTrialStatus;
            modalRef.componentInstance.userid.subscribe((userid: any) => { });
            modalRef.componentInstance.isUserInTrial.subscribe((isUserInTrial: boolean) => {
              this.canBuyPackage = true;
            });
          }
        } else {
          this.startFreeTrial = false;
          this.OpenTrilaModel();
        }
      });
    } else {
      const modal = this.modalService.open(LoginWithEmailComponent, { centered: true, backdrop: 'static' });
      modal.componentInstance.isUserLogged.subscribe((isUserLogged: any) => {
        if (isUserLogged == true) {
          modal.componentInstance.userid.subscribe((userid: any) => {
            if (userid) {
              this.UserId = userid;
              this.openFreeTrial();
            }
          });
        }
      });
    }
  }

  OpenTrilaModel() {
    const modalRef = this.modalService.open(TrialUserComponent, { centered: true, size: 'xl', backdrop: 'static' });
    modalRef.componentInstance.UserId = this.UserId;
    modalRef.componentInstance.GradeId = this.NewGradeId;
    modalRef.componentInstance.isUserInTrial.subscribe((isUserInTrial: any) => {
      if (isUserInTrial != null) {
        this.selectedObject.selectedGrade = this.NewGradeId;
        this.selectedObject.selectedResource = this.NewResourceId;
        this.selectedObject.selectedSubject = this.NewSubjectId;
        this.canBuyPackage = true;
        this.cacheService.SetisTrialUser(true);
        this.data.emit(this.selectedObject);
      }
      else {
        this.canBuyPackage = false;
      }
    });
  }

  buyNewPackage() {
    this.router.navigate(['/package']);
    this.buyNow = false;
  }

  transformDate(date: string | number | Date) {
    return this.datePipe.transform(date, "yyyy-MM-ddThh:mm:ss.SSS'Z'");
  }

  GetData() {
    if (this.NewGradeId == "" || this.NewSubjectId == "") {
      this.NewGradeId = "12";
    }
    else {
      this.NewGradeId = this.NewGradeId;
    }
    const AnswerData = {
      gradeId: this.NewGradeId,
      subjectId: this.NewSubjectId,
      htmlContent: this.htmlContent.replace(/<[^>]*>/g, ''),
      isResourcePage: true
    };
    this.cacheService.setResourceData(AnswerData);
    this.router.navigate(['forums/']);
  }

  openVideoToParent(imgUrl: any, link?: any, accessstatus?: any) {
    if (this.userDetails == null) {
      this.modalService.open(LoginWithEmailComponent, { centered: true, backdrop: 'static' });
    } else {

      if (accessstatus == 'TrialPending') {
        this.isTrialPending = true;
        const modalRef = this.modalService.open(TrialUserComponent, { centered: true, size: 'xl', backdrop: 'static' });
        modalRef.componentInstance.UserId = this.UserId;
      } else if (accessstatus == 'TrialExpired') {
        this.isTrialExpired = true;
        this.freeTrialStatus = true;
        const modalRef = this.modalService.open(FreeTrialNotifyComponent, { size: 'xl', centered: true, backdrop: 'static' });
        modalRef.componentInstance.FreeTrialStatus = this.freeTrialStatus;
      } else if (accessstatus == 'PackageExpired') {
        this.isPackageExpired = true;
        const modalRef = this.modalService.open(FreeTrialNotifyComponent, { size: 'xl', centered: true, backdrop: 'static' });
        modalRef.componentInstance.FreeTrialStatus = this.freeTrialStatus;
      } else {
        const modalRef = this.modalService.open(ResourceVideoViewComponent, { size: 'xl', centered: true, backdrop: 'static' });
        modalRef.componentInstance.ImageURL = imgUrl;
        modalRef.componentInstance.Link = link;
        modalRef.componentInstance.AccessStatus = accessstatus;
        modalRef.componentInstance.UserId = this.UserId;
        if (accessstatus == 'TrialExpired') {
          modalRef.componentInstance.freeTrialStatus = true;
        }
        this.isTrialUser = true;
      }
    }
  }
  getUserStatus(): any {
    if (this.UserId) {
      this.trialService.GetOrder(this.UserId).subscribe((data) => {
        if (data.length > 0) {
          if (
            data[0].accessstatus == 'TrialExpired' ||
            data[0].accessstatus == 'PackageExpired'
          ) {
            this.canBuyPackage = true;
            this.resouresecanBuyPackage = this.canBuyPackage;
          }
          else {
            this.canBuyPackage = true;
            this.resouresecanBuyPackage = this.canBuyPackage;
          }
        }
      });
    }
    return this.canBuyPackage;
  }

  getVideoData(NewGradeId: string, NewSubjectId: string, NewResourceId: string, NewTopicId: string) {
    if (NewResourceId && NewResourceId != '1') {
      this.resourceService
        .getResourcesDataFromIdsWithUserID(
          parseInt(NewGradeId),
          parseInt(NewSubjectId),
          parseInt('1'),
          parseInt(NewTopicId),
          this.userDetails ? this.UserId : 0
        )
        .subscribe((res) => {
          this.resourceVideoData = res;
          if (this.resourceVideoData != null || this.resourceVideoData.length > 0 || this.resourceVideoData != undefined) {
            if (NewGradeId == "" && NewSubjectId == "") {
              this.isVisible = false;
            }
            else {
              this.isVisible = true;
            }
          } else {
            this.isVisible = false;
          }
        });
    } else {
    }
  }
}
