import { DatePipe, DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationType } from 'src/app/enum/NotificationType';
import { Registration } from 'src/app/Models/RegistrationModel';
import { ClientIPAddressService } from 'src/app/services/client-ipaddress.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-tablet-learning-program',
  templateUrl: './tablet-learning-program.component.html',
  styleUrls: ['./tablet-learning-program.component.css']
})
export class TabletLearningProgramComponent {
  tabletForm!: FormGroup;
  registration!: Registration;
  phonRegistrationForm!: FormGroup;
  clientIPAdress!: string;
  phoneNo!: '';
  otp:string | undefined;
  verificationId:number | undefined;
  tabletlearningprogram: string[] = [
    'https://files.askiitians.com/static_content/Resources/scripts/jquery.min1.js',
    'https://files.askiitians.com/static_content/Resources/scripts/owl.carousel.min.js',
    'https://files.askiitians.com/static_content/Resources/scripts/owl.carousel.js',
    'https://files.askiitians.com/static_content/Resources/style/font-awesome.min.css',
    'https://files.askiitians.com/static_content/Resources/style/owl.carousel.css',
    'https://files.askiitians.com/static_content/Resources/style/owl.theme.default.min.css'
  ];

  constructor(@Inject(DOCUMENT) private dom: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private userService: UserService,
    private clientIPAdressService: ClientIPAddressService,
    private notificationService: NotificationService
  ) {
    this.tabletForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(50)]],
      email: ['', [Validators.required, Validators.email, Validators.maxLength(50)]],
      PhoneNo: ['', [Validators.required, Validators.maxLength(50)]]
    });

    this.phonRegistrationForm = this.fb.group({
      PhoneNo: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]], // Assuming the phone number should be a 10-digit number
    });
  }

  ngOnInit() {
    // Load scripts and styles on component initialization
    this.loadCSS(this.tabletlearningprogram);
    this.clientIPAdressService.getClientIp().subscribe((ip) => {
      console.log('Client IP:', ip);
      this.clientIPAdress = ip;
    });
  }

  loadCSS(csslist: any) {
    if (isPlatformBrowser(this.platformId)) {
      if (Array.isArray(csslist)) {
        for (let n = 0; n < csslist.length; n++) {
          const file = csslist[n];
          const isCSS = file.endsWith('.css');
          const isJS = file.endsWith('.js');
          if (isCSS) {
            const link = this.dom.createElement('link');
            link.setAttribute('rel', 'stylesheet');
            link.setAttribute('href', file);
            this.dom.head.appendChild(link);
          } else if (isJS) {
            const script = this.dom.createElement('script');
            script.setAttribute('src', file);
            this.dom.head.appendChild(script);
          }
        }
      }
    }
  }
  get email() {
    return this.tabletForm.get('email');
  }

  get name() {
    return this.tabletForm.get('name');
  }

  get phone() {
    return this.tabletForm.get('phone');
  }

  onPhoneNoSubmit() {
    this.phoneNo = this.phonRegistrationForm.value.PhoneNo;
    this.onSubmit();

  }
  onSubmit() {
     
    this.phoneNo = this.tabletForm.value.PhoneNo;
    this.registration = {
      id: 0,
      name: this.tabletForm.value.name,
      email: this.tabletForm.value.email,
      isActive: true,
      password: this.tabletForm.value.Password,
      userTypeId: 1,
      mobile: this.phoneNo,
      createdDateTime: this.transformDate(new Date())?.toString(),
      modifiedBy: '',
      lastModified: this.transformDate(new Date())?.toString(),
      registrationUrl: String(window.location.pathname),
      sourceNameId: 51,
      ipCountry: '',
      ipAddress: this.clientIPAdress,
      city: '',
      VerificationId:this.verificationId,
      otp: this.otp,
      userDetails: {
        id: 0,
        grade: '',
        userId: 0,
        schoolName: '',
        modifiedDate: this.transformDate(new Date())?.toString(),
      },
      mobileVerification:
      {
        verificationId:0
      }
    };
    this.userService.create(this.registration).subscribe((data: any) => {
      if (data) {
        this.notificationService.notifyMessage(NotificationType.Success, `Thank you!.`);
      }
      else {
        this.notificationService.notifyMessage(NotificationType.Warning, `Something went wrong!.`);
      }
    });
  }
  transformDate(date: string | number | Date) {
    return this.datePipe.transform(date, "yyyy-MM-ddThh:mm:ss.SSS'Z'");
  }
}
